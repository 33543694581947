import React from 'react';
import { Box } from '@material-ui/core';
import {
  ArrayInput,
  required,
  SimpleFormIterator,
  TextInput,
  useTranslate,
} from "react-admin";

export const FillTextMultiple = (props: any) => {
  const translate = useTranslate();

  return (
    <>
      {/* <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
        <ArrayInput source="questionMetas" resource="questions">
          <SimpleFormIterator>
            <TextInput
              source="value"
              label={translate("resources.questions.fields.questionFields")}
              resource="questions"
              required={requiredValidate}
            />
          </SimpleFormIterator>
        </ArrayInput>
      </Box> */}
      <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
        <ArrayInput source="answerMetas" resource="questions">
          <SimpleFormIterator>
            <TextInput
              source="value"
              resource="questions"
              required={requiredValidate}
            />
          </SimpleFormIterator>
        </ArrayInput>
      </Box>
    </>
  );
};

const requiredValidate = [required()];

export default FillTextMultiple;
