import { useEffect, useReducer, useCallback } from "react";

const callApi = async (path: string, body: any) => {
  const token = localStorage.getItem("user_token");
  if (!token) return Promise.reject();

  return fetch(process.env.REACT_APP_API_BASE_URL + path, {
    method: "POST",
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });
};

interface StateT<T> {
  loading: boolean;
  error: Error | null;
  data: T | null;
}

interface Action {
  type: string;
  payload?: any;
}

interface IOptions {
  defaultValue: any;
  callback: (err?: any) => void;
}

const initState = (_default: any): StateT<any> => ({
  loading: false,
  error: null,
  data: _default,
});

const reducer = (state: StateT<any>, action: Action) => {
  switch (action.type) {
    case "startFetch":
      return { ...state, loading: true };
    case "fetchSuccess":
      return { ...state, loading: false, data: action.payload };
    case "fetchError":
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export const useApiMutate = (
  path: string,
  body: any,
  when: any[] = [],
  options: IOptions = { defaultValue: null, callback: () => {} }
) => {
  const [{ data, error, loading }, dispatch] = useReducer(
    reducer,
    initState(options.defaultValue)
  );
  let mount = true;
  useEffect(() => {
    mount = true;
    return () => {
      mount = false;
    };
  }, []);
  const mutate = useCallback(async () => {
    dispatch({ type: "startFetch" });

    try {
      const res = await callApi(path, body);

      const dataJSON = await res.json();

      const { status, data: _data, message } = dataJSON;

      if (status < 400) {
        if (mount) {
          options.callback();
          dispatch({
            type: "fetchSuccess",
            payload: _data,
          });
        }
      } else {
        throw new Error(message);
      }
    } catch (err) {
      options.callback(err);
      if (!mount) {
        return;
      }
      dispatch({ type: "fetchError", payload: error });
    }
  }, when);
  return { mutate, error, data, loading };
};
