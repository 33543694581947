import * as _ from "lodash";
import ListActions from "./ListActions";
import React from "react";
import { Box, Card, CardContent } from "@material-ui/core";
import {
  AutocompleteInput,
  Create as CreateAdmin,
  FormWithRedirect,
  ReferenceInput,
  required,
  TextInput,
  ImageInput,
  Toolbar,
  BooleanInput,
  NumberInput,
} from "react-admin";
import PreviewInputImage from "../components/PreviewInputImage";

export const Form = (props: any) => {
  return (
    <FormWithRedirect
      {...props}
      initialValues={{ displayHome: true }}
      render={(formProps: any) => (
        <Card>
          <form>
            <CardContent>
              <Box display={{ md: "block", lg: "flex" }}>
                <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                  <TextInput
                    type="text"
                    fullWidth={true}
                    source="name"
                    validate={requiredValidate}
                    resource="categories"
                  />
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                  <BooleanInput
                    source="displayHome"
                    fullWidth={true}
                    resource="categories"
                  />
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                  <NumberInput
                    source="sequenceNo"
                    fullWidth={true}
                    resource="courses"
                  />
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                  <ReferenceInput
                    source="parentId"
                    reference="categories"
                    resource="categories"
                    filter={{ "parentId||$isnull": true }}
                    filterToQuery={(searchText: any) => {
                      return _.isEmpty(_.trim(searchText))
                        ? null
                        : { name: searchText };
                    }}
                  >
                    <AutocompleteInput optionText="name" fullWidth />
                  </ReferenceInput>
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                  <ImageInput
                    source="imageUrl"
                    resource="posts"
                    multiple={false}
                    accept="image/*"
                    maxSize={2 * 1024 * 1024}
                    placeholder={<p>Kéo thả ảnh vào đây</p>}
                  >
                    <PreviewInputImage source="imageUrl" title="title" />
                  </ImageInput>
                </Box>
              </Box>
            </CardContent>
            <Toolbar
              record={formProps.record}
              basePath={formProps.basePath}
              invalid={formProps.invalid}
              handleSubmit={formProps.handleSubmit}
              saving={formProps.saving}
              undoable={false}
              resource="categories"
            />
          </form>
        </Card>
      )}
    />
  );
};
const Create = (props: any) => {
  return (
    <CreateAdmin {...props} undoable={false} actions={<ListActions />}>
      <Form />
    </CreateAdmin>
  );
};

const requiredValidate = [required()];

export default Create;
