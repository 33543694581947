import ChipField from "../components/ChipField";
import DateTimeField from "../components/DateTimeField";
import ListActions from "./ListActions";
import ListFilter from "./ListFilters";
import React, { FC } from "react";
import {
  Datagrid,
  List,
  TextField,
  useTranslate,
  ReferenceField,
} from "react-admin";
import { useLocation } from "react-router";

export enum ContestMode {
  Online = "online",
  Offline = "offline",
}

export enum GradeStatus {
  Publish = "publish",
  Draft = "draft",
}

const MajorList: FC<any> = (props) => {
  const translate = useTranslate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const mode = params.get("mode") || ContestMode.Online;
  return (
    <List
      {...props}
      empty={<ListActions mode={mode} />}
      title={"Đề thi"}
      actions={<ListActions mode={mode} />}
      filters={<ListFilter />}
      filter={{ mode }}
    >
      <Datagrid optimized rowClick="edit">
        <TextField source="id" />
        <TextField source="name" />
        <ReferenceField source="gradeId" reference="grades" label={"Lớp"}>
          <ChipField source="name" />
        </ReferenceField>

        <ReferenceField source="periodId" reference="periods" label={"Kỳ"}>
          <ChipField source="name" />
        </ReferenceField>

        <DateTimeField source="createdAt" />
      </Datagrid>
    </List>
  );
};

export default MajorList;
