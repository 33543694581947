import {
  Datagrid,
  List,
  NumberField,
  TextField,
  useTranslate,
} from "react-admin";
import React, { FC } from "react";

import ListActions from "./ListActions";
import ListFilter from "./ListFilters";

const MajorList: FC<any> = (props) => {
  const translate = useTranslate();
  return (
    <List
      {...props}
      title={translate("resources.versions.page.list")}
      actions={<ListActions />}
      filters={<ListFilter />}
    >
      <Datagrid optimized rowClick="edit">
        <NumberField source="ios" />
        <NumberField source="android" />

        <TextField source="updatedAt" />
      </Datagrid>
    </List>
  );
};

export default MajorList;
