import { Box, Button } from "@material-ui/core";
import React from "react";
import {
  Datagrid,
  Edit as EditAdmin,
  EditButton,
  ReferenceManyField,
  Show,
  TextField,
  TopToolbar,
} from "react-admin";
import { Link } from "react-router-dom";
import { Form } from "./Create";
import ListActions from "./ListActions";
const SectionShowActions = ({ id }: any) => (
  <TopToolbar>
    <Button
      className="MuiButton-textPrimary MuiButton-textSizeSmall"
      component={Link}
      to={{
        pathname: "/sections/create",
        state: { record: { courseId: parseInt(id) } },
      }}>
      + Create
    </Button>
  </TopToolbar>
);

const SectionShow = (props: any) => {
  return (
    <Show {...props} actions={<SectionShowActions {...props} />} title={" "}>
      <ReferenceManyField
        label="sections"
        reference="sections"
        target="courseId"
        perPage={1000}
        sort={{ field: "sequenceNo", order: "ASC" }}>
        <Datagrid>
          <TextField source="name" />
          <TextField source="sequenceNo" />
          <EditButton />
        </Datagrid>
      </ReferenceManyField>
    </Show>
  );
};

const Edit = (props: any) => {
  return (
    <div style={{ flex: 1, display: "flex" }}>
      <Box flex={3} ml={{ xs: 0, sm: "0.5em" }}>
        <EditAdmin
          component="div"
          {...props}
          undoable={false}
          actions={<ListActions />}>
          <Form isEdit id />
        </EditAdmin>
      </Box>
      <Box flex={2} ml={{ xs: 0, sm: "0.5em" }}>
        <SectionShow {...props} />
      </Box>
    </div>
  );
};

export default Edit;
