import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import { useTranslate, useLocale, useSetLocale, Title } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import { AppState } from "../types";
import { Box } from "@material-ui/core";
import { TextInput, Create as CreateAdmin } from "react-admin";
import { Toolbar } from "react-admin";
import { SaveButton } from "react-admin";
import { FormWithRedirect } from "react-admin";
import { required } from "react-admin";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useRedirect, useNotify } from "react-admin";
import { customHttpClientAdmin } from "../dataProvider";

type DataSubmit = {
  current_pass: string;
  new_pass: string;
  confirm_pass: string;
};

export const ChangePasswordAdmin = (props: any) => {
  const translate = useTranslate();
  const redirect = useRedirect();
  const notify = useNotify();
  const [isShowCurrentPass, setIsShowCurrentPass] = useState<boolean>(false);
  const [isShowNewPass, setIsShowCNewPass] = useState<boolean>(false);
  const permissions = localStorage.getItem("permissions");

  const passwordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]).{8,32}$/;

  const iconEyeOn = () => {
    return (
      <VisibilityIcon
        style={{
          fontSize: 30,
          position: "absolute",
          right: 40,
          // top: 20,
          marginTop: 5,
          padding: 10,
          zIndex: 9999,
        }}
      />
    );
  };

  const iconEyeOff = () => {
    return (
      <VisibilityOffIcon
        style={{
          fontSize: 30,
          position: "absolute",
          right: 40,
          // top: 20,
          marginTop: 5,
          padding: 10,
          zIndex: 9999,
        }}
      />
    );
  };
  return (
    <Card>
      <Title title={translate("Đổi mật khẩu")} />
      <FormWithRedirect
        render={(formProps: any) => {
          const handleOnsubmitChangePass = async (data: DataSubmit) => {
            const auth = permissions && JSON.parse(permissions);
            if (
              data.current_pass == null ||
              data.new_pass == null ||
              data.confirm_pass == null
            ) {
              notify("Vui lòng nhập đầy đủ thông tin vào ô này", "error");
              return;
            } else if (data.new_pass.length < 8 || data.new_pass.length > 32) {
              notify(
                "Mật khẩu cần tối thiểu 8 kí tự và tối đa 32 kí tự",
                "error"
              );
              return;
            } else if (data.new_pass != data.confirm_pass) {
              notify(
                "Mật khẩu mới và mật khẩu nhập lại chưa trùng khớp với nhau",
                "error"
              );
              return;
            } else if (!passwordRegex.test(data.new_pass)) {
              notify(
                "Mật khẩu cần có cả chữ viết thường, chữ viết hoa, số và kí tự đặc biệt",
                "error"
              );
              return;
            } else {
              const body = {
                email: auth?.email.trim(),
                password: data.new_pass.trim(),
                password_old: data.current_pass.trim(),
                "confirm-password": data.confirm_pass.trim(),
              };
              console.log(body, "body");

              await fetch(
                process.env.REACT_APP_API_NEW_BASE + "/admin/change-password",
                {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify(body),
                }
              )
                .then((res: any) => res.json())
                .then((success: any) => {
                  console.log(success, "successsuccess");

                  if (success.code == 1) {
                    localStorage.removeItem("user_token");
                    localStorage.removeItem("permissions");
                    redirect("/login");
                  } else if (success.statusCode == 400) {
                    notify(success.message, "error");
                    return;
                  }
                })
                .catch((error: any) => {
                  notify(`Vui lòng thử lại sau.`, {
                    type: "error",
                  });
                });
            }
          };

          return (
            <Card>
              <form>
                <CardContent>
                  <Box
                    display={{ md: "block", lg: "flex" }}
                    style={{ alignItems: "center" }}
                  >
                    <Box
                      flex={1}
                      ml={{ xs: 0, sm: "0.5em" }}
                      style={{ display: "flex", flexDirection: "row" }}
                    >
                      <TextInput
                        type={isShowCurrentPass ? "text" : "password"}
                        source="current_pass"
                        label="Mật khẩu cũ"
                        fullWidth
                        validate={requiredValidate}
                        resource="courses"
                      />
                      {isShowCurrentPass ? (
                        <div
                          onClick={() => {
                            setIsShowCurrentPass(false);
                          }}
                        >
                          {iconEyeOn()}
                        </div>
                      ) : (
                        <div
                          onClick={() => {
                            setIsShowCurrentPass(true);
                          }}
                        >
                          {iconEyeOff()}
                        </div>
                      )}
                    </Box>
                  </Box>
                  <Box
                    display={{ md: "block", lg: "flex" }}
                    style={{ alignItems: "center" }}
                  >
                    <Box
                      flex={1}
                      ml={{ xs: 0, sm: "0.5em" }}
                      style={{ display: "flex", flexDirection: "row" }}
                    >
                      <TextInput
                        type={isShowNewPass ? "text" : "password"}
                        source="new_pass"
                        label="Mật khẩu mới"
                        fullWidth
                        validate={requiredValidate}
                        resource="courses"
                      />
                      {isShowNewPass ? (
                        <div
                          onClick={() => {
                            setIsShowCNewPass(false);
                          }}
                        >
                          {iconEyeOn()}
                        </div>
                      ) : (
                        <div
                          onClick={() => {
                            setIsShowCNewPass(true);
                          }}
                        >
                          {iconEyeOff()}
                        </div>
                      )}
                    </Box>
                  </Box>
                  <Box
                    display={{ md: "block", lg: "flex" }}
                    style={{ alignItems: "center" }}
                  >
                    <Box
                      flex={1}
                      ml={{ xs: 0, sm: "0.5em" }}
                      style={{ display: "flex", flexDirection: "row" }}
                    >
                      <TextInput
                        type={isShowNewPass ? "text" : "password"}
                        source="confirm_pass"
                        label="Nhập lại mật khẩu mới"
                        fullWidth
                        validate={requiredValidate}
                        resource="courses"
                      />
                      {isShowNewPass ? (
                        <div
                          onClick={() => {
                            setIsShowCNewPass(false);
                          }}
                        >
                          {iconEyeOn()}
                        </div>
                      ) : (
                        <div
                          onClick={() => {
                            setIsShowCNewPass(true);
                          }}
                        >
                          {iconEyeOff()}
                        </div>
                      )}
                    </Box>
                  </Box>
                </CardContent>

                <Toolbar
                  record={formProps.record}
                  basePath={formProps.basePath}
                  invalid={formProps.invalid}
                  saving={formProps.saving}
                  undoable={false}
                  resource="courses"
                  // redirect={false}
                >
                  <SaveButton
                    saving={formProps.saving}
                    undoable={false}
                    handleSubmitWithRedirect={() =>
                      handleOnsubmitChangePass(formProps.form.getState().values)
                    }
                  />
                </Toolbar>
              </form>
            </Card>
          );
        }}
      />
    </Card>
  );
};

const requiredValidate = [required()];

export default ChangePasswordAdmin;
