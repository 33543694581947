import React, { useState, useEffect } from "react";
import { Card, Checkbox, Grid } from "@material-ui/core";

export default function GroupInput(props: any) {
  const isDisable = props.disabled;
  const setCheckInput = props.setCheckInput;

  //   const setListDayWeekly = props.setListDayWeekly;

  const CheckBoxGroup = (props: any) => {
    const [selectedOptions, setSelectedOptions] = useState(props.options);
    return (
      <>
        {props.options.map((option: any) => (
          <Grid key={option.optionValue}>
            <label>
              {option.label}:
              <Checkbox
                checked={option.value}
                disabled={isDisable}
                name={option.optionValue}
                value={option.value}
                onChange={(foo) => {
                  setCheckInput(false);
                  const updatedOptions = selectedOptions.map((opt: any) => {
                    const clickedOption = foo.target.name;
                    if (clickedOption == opt.optionValue) {
                      return { ...opt, value: !opt.value };
                    }
                    return opt;
                  });

                  setSelectedOptions(updatedOptions);
                  props.callback(() => updatedOptions);
                }}
              />
            </label>
          </Grid>
        ))}
      </>
    );
  };

  return (
    <CheckBoxGroup
      callback={props.setAcademicLevel}
      options={props.academicLevel}
    />
  );
}
