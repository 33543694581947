import { CircularProgress } from "@material-ui/core";
import { PlusOneOutlined } from "@material-ui/icons";
import _ from "lodash";
import React, { FC, useCallback, useState } from "react";
import { useNotify } from "react-admin";
import { useInput } from "react-admin";
import "./upload.css";

interface IProps {}

const UploadAudio: FC<any> = (props) => {
  const inputProps = useInput(props);
  const {
    input,
    meta: { touched, error },
  } = inputProps;

  const [loading, setLoading] = useState<boolean>();
  const noti = useNotify();
  const uploadButton = (
    <div className="block-upload-audio">
      {loading ? <CircularProgress /> : <PlusOneOutlined />}
      <div style={{ marginTop: 8 }}>Tải âm thanh</div>
    </div>
  );
  const hostname = `${
    process.env.REACT_APP_API_BASE_URL || "http://localhost:4004"
  }`;

  const uploadAudio = useCallback((file: any) => {
    setLoading(true);
    let formdata = new FormData();
    formdata.append("file", file);

    const token = localStorage.getItem("user_token");
    const requestOptions = {
      method: "POST",
      body: formdata,
      headers: { Authorization: `Bearer ${token}` },
    };

    fetch(`${hostname}/api/upload/audio`, requestOptions)
      .then((response) => {
        setLoading(false);
        return response.json();
      })
      .then((success) => {
        const audio = _.get(success, "data.url");
        input.onChange(audio);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, []);

  return (
    <div className="block-upload-audio-wrapper">
      {input.value && (
        <label className="block-upload-audio">
          <audio controls>
            <source
              src={
                input.value.includes("http")
                  ? `${input.value}`
                  : `${hostname}${input.value}`
              }
              type="audio/mp3"
            />
            Your browser does not support the audio element.
          </audio>
        </label>
      )}

      <label className="block-upload-audio">
        <input
          hidden
          onChange={(e) => {
            if (e.target.files) {
              console.log("e.target.files", e.target.files[0]);
              const file = e.target.files[0];
              if (file.size >= 20 * 1024 * 1024) {
                noti("Ảnh vượt quá 20Mb", "warning");
                return;
              }
              uploadAudio(file);
            }
          }}
          type="file"
          accept=".mp3,audio/*"
        />
        {uploadButton}
      </label>
    </div>
  );
};

export default UploadAudio;
