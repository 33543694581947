import React, { FC, useEffect, useState } from "react";
import { FieldProps } from "../types";
import { required, SelectInput, useTranslate } from "react-admin";
import { customHttpClient, customHttpClientAdmin } from "../dataProvider";
import { Box } from "@material-ui/core";

interface Props extends FieldProps<any> {
  className?: string;
  fullWidth?: any;
  resource?: string;
  setVarCheckTimepublic?: any;
  setNews: any;
  disabled: any;
}
const requiredValidate = [required()];

type News = {
  id: string;
  title: string;
};

const NewsList: FC<Props> = ({
  record,
  className,
  setNews,
  source,
  disabled,
  ...rest
}) => {
  const [newsList, setNewsList] = useState();

  useEffect(() => {
    let res = customHttpClient(`/crud/posts`, {
      method: "GET",
    })
      .then((res: any) => res.json)
      .then((data: any) => {
        const dataCourse = data?.map((item: News) => {
          return {
            id: item.id,
            name: item.title,
          };
        });
        setNewsList(dataCourse);
      })

      .catch((error: any) => {
        console.log(error, "error");
      });
  }, []);

  return (
    <Box flex={2} ml={{ xs: 0, sm: "0.5em" }}>
      {/* {newsList && ( */}
      <SelectInput
        validate={requiredValidate}
        {...rest}
        source={source}
        label="Chọn tin tức"
        choices={newsList}
        disabled={disabled}
        onChange={(e: any) => setNews(e.target.value)}
      />
      {/* )} */}
    </Box>
  );
};

export default NewsList;
