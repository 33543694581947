import React, { useState } from "react";
import _ from "lodash";
import {
  Create,
  FormWithRedirect,
  ReferenceInput,
  AutocompleteInput,
  SelectInput,
  required,
  Toolbar,
  DateInput,
} from "react-admin";
import ListActions from "./ListActions";
import { Card, CardContent, Box } from "@material-ui/core";
import moment from "moment";

const requiredValidate = [required()];
const MajorCreate = (props: any) => {
  return (
    <Create {...props} undoable={false} actions={<ListActions />}>
      <Form />
    </Create>
  );
};

export const Form = (props: any) => {
  return (
    <FormWithRedirect
      {...props}
      render={(formProps: any) => (
        <Card>
          <form>
            <CardContent>
              <Box display={{ md: "block", lg: "flex" }}>
                <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                  <ReferenceInput
                    source="studentId"
                    reference="users"
                    resource="studentcardcodes"
                    validate={requiredValidate}
                    filterToQuery={(searchText: any) => {
                      return _.isEmpty(_.trim(searchText))
                        ? null
                        : { email: searchText };
                    }}
                  >
                    <AutocompleteInput
                      optionText="email"
                      fullWidth
                      validate={requiredValidate}
                    />
                  </ReferenceInput>
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                  <ReferenceInput
                    source="studentId"
                    reference="users"
                    resource="studentcardcodes"
                    validate={requiredValidate}
                    filterToQuery={(searchText: any) => {
                      return _.isEmpty(_.trim(searchText))
                        ? null
                        : { telephone: searchText };
                    }}
                  >
                    <AutocompleteInput
                      optionText="telephone"
                      fullWidth
                      validate={requiredValidate}
                    />
                  </ReferenceInput>
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                  <ReferenceInput
                    source="cardCodeId"
                    reference="cardcodes"
                    filter={{ used: 0 }}
                    resource="studentcardcodes"
                    filterToQuery={(searchText: any) => {
                      return _.isEmpty(_.trim(searchText))
                        ? null
                        : { code: searchText };
                    }}
                  >
                    <AutocompleteInput
                      optionText={(record: any) => {
                        return `${record.code} - ${record.card.displayName}`;
                      }}
                      fullWidth
                      validate={requiredValidate}
                    />
                  </ReferenceInput>
                </Box>

                <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                  <DateInput
                    source="expiredAt"
                    resource="studentcardcodes"
                    fullWidth
                  />
                </Box>
              </Box>
            </CardContent>
            <Toolbar
              record={formProps.record}
              basePath={formProps.basePath}
              invalid={formProps.invalid}
              handleSubmit={formProps.handleSubmit}
              saving={formProps.saving}
              resource="students"
              undoable={false}
            />
          </form>
        </Card>
      )}
    />
  );
};

// const requiredValidate = [required()];

export default MajorCreate;
