import ChipField from '../components/ChipField';
import DateTimeField from '../components/DateTimeField';
import ListActions from './ListActions';
import ListFilter from './ListFilters';
import React, { FC } from 'react';
import { Chip } from '@material-ui/core';
import {
  Datagrid,
  List,
  TextField,
  useTranslate,
  ReferenceField,
} from "react-admin";
const QuestionType = (props: any) => {
  const translate = useTranslate();

  return (
    <Chip
      label={translate(`resources.questions.fields.${props.record.type}`)}
    />
  );
};

const MajorList: FC<any> = (props) => {
  const translate = useTranslate();
  return (
    <List
      {...props}
      title={translate("resources.questions.page.list")}
      actions={<ListActions />}
      filters={<ListFilter />}>
      <Datagrid optimized rowClick="edit">
        <TextField source="id" />
        <TextField source="questionText" />
        <QuestionType source="type" />

        <ReferenceField source="unitId" reference="units">
          <ChipField source="name" />
        </ReferenceField>

        <DateTimeField source="createdAt" />
      </Datagrid>
    </List>
  );
};

export default MajorList;
