import * as _ from "lodash";
import ListActions from "./ListActions";
import React, { useState } from "react";
import { Box, Card, CardContent, makeStyles } from "@material-ui/core";
import {
  Create as CreateAdmin,
  FormWithRedirect,
  NumberInput,
  required,
  TextInput,
  Toolbar,
  ReferenceInput,
  AutocompleteInput,
  SaveButton,
  DeleteButton,
} from "react-admin";
import { useNotify } from "react-admin";

export const Form = (props: any) => {
  const { record } = props;

  const [valueSequen, setValueSequen] = useState<number>(record.sequenceNo);
  const notify = useNotify();

  const submitForm = () => {
    notify(`Thứ tự nhập vào phải lớn hơn hoặc bằng 0`, {
      type: "warning",
    });
  };
  return (
    <FormWithRedirect
      {...props}
      render={(formProps: any) => (
        <Card>
          <form>
            <CardContent>
              <Box display={{ md: "block", lg: "flex" }}>
                <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                  <TextInput
                    type="text"
                    source="name"
                    validate={requiredValidate}
                    resource="sections"
                    fullWidth
                  />
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                  <NumberInput
                    source="sequenceNo"
                    validate={requiredValidate}
                    resource="sections"
                    fullWidth
                    min={0}
                    onChange={(e: any) => {
                      setValueSequen(e.target.value);
                    }}
                  />
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                  <ReferenceInput
                    source="courseId"
                    reference="courses"
                    resource="sections"
                    filterToQuery={(searchText: any) => {
                      return _.isEmpty(_.trim(searchText))
                        ? null
                        : { name: searchText };
                    }}>
                    <AutocompleteInput
                      optionText="name"
                      fullWidth
                      validate={requiredValidate}
                    />
                  </ReferenceInput>
                </Box>
              </Box>
            </CardContent>
            {/* <Toolbar
              record={formProps.record}
              basePath={formProps.basePath}
              invalid={formProps.invalid}
              handleSubmit={formProps.handleSubmit}
              saving={formProps.saving}
              undoable={false}
              redirect={`/courses/${formProps.record.courseId}`}
              resource="sections"
            /> */}
            <Toolbar
              record={formProps.record}
              basePath={formProps.basePath}
              invalid={formProps.invalid}
              handleSubmit={formProps.handleSubmit}
              saving={formProps.saving}
              undoable={false}
              resource="sections">
              <SaveButton
                saving={formProps.saving}
                redirect={`/courses/${formProps.record.courseId}`}
                handleSubmitWithRedirect={
                  valueSequen >= 0
                    ? formProps.handleSubmitWithRedirect
                    : submitForm
                }
              />
              {formProps.isEdit && (
                <DeleteButton
                  undoable={false}
                  redirect={`/courses/${formProps.record.courseId}`}
                />
              )}
            </Toolbar>
          </form>
        </Card>
      )}
    />
  );
};

const Create = (props: any) => {
  return (
    <CreateAdmin undoable={false} {...props} actions={<ListActions />}>
      <Form />
    </CreateAdmin>
  );
};

const requiredValidate = [required()];

export default Create;
