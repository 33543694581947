import { Box, Button, Chip } from "@material-ui/core";
import { CloudUploadOutlined } from "@material-ui/icons";
import React from "react";
import {
  Button as RAButton,
  Datagrid,
  Edit as EditAdmin,
  EditButton,
  ReferenceManyField,
  Show,
  TextField,
  TopToolbar,
  useTranslate,
} from "react-admin";
import { Link, useHistory } from "react-router-dom";
import { Form } from "./Create";
import ListActions from "./ListActions";

const QuestionShowActions = ({ id }: any) => {
  const translate = useTranslate();
  const history = useHistory();
  return (
    <TopToolbar>
      <Button
        className="MuiButton-textPrimary MuiButton-textSizeSmall"
        component={Link}
        to={{
          pathname: "/questions/create",
          search: "?game=game",
          state: { record: { unitId: parseInt(id) } },
        }}
      >
        + Create Game
      </Button>
      <Button
        className="MuiButton-textPrimary MuiButton-textSizeSmall"
        component={Link}
        to={{
          pathname: "/questions/create",
          state: { record: { unitId: parseInt(id) } },
        }}
      >
        + Create Question
      </Button>

      <RAButton
        className="MuiButton-textPrimary MuiButton-textSizeSmall"
        component={Link}
        onClick={() => {
          history.push(`/units/${id}/questions`);
        }}
        label="Import câu hỏi"
      >
        <CloudUploadOutlined />
      </RAButton>
    </TopToolbar>
  );
};

const TypeTranslate = (props: any) => {
  const translate = useTranslate();
  const { record } = props;
  if (!record) return <></>;
  return (
    <Chip label={translate(`resources.questions.fields.${record.type}`)} />
  );
};
const QuestionShow = (props: any) => {
  return (
    <Show {...props} actions={<QuestionShowActions {...props} />} title={" "}>
      <ReferenceManyField
        label="questions"
        reference="questions"
        target="unitId"
        perPage={1000}
        sort={{ field: "sequenceNo", order: "ASC" }}
      >
        <Datagrid>
          <TextField source="questionText" />
          <TextField source="sequenceNo" label="Thứ tự" translate="no" />
          <TypeTranslate source="type" />
          <EditButton />
        </Datagrid>
      </ReferenceManyField>
    </Show>
  );
};

const ChildUnitShow = (props: any) => {
  return (
    <Show {...props} title={" "} actions={false}>
      <ReferenceManyField
        label="units"
        reference="units"
        target="parentId"
        perPage={1000}
        sort={{ field: "sequenceNo", order: "ASC" }}
      >
        <Datagrid>
          <TextField source="name" />
          <TextField source="sequenceNo" translate="no" />
          <EditButton />
        </Datagrid>
      </ReferenceManyField>
    </Show>
  );
};

const Edit = (props: any) => {
  return (
    <div style={{ flex: 1, display: "flex" }}>
      <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
        <EditAdmin
          component="div"
          {...props}
          undoable={false}
          actions={<ListActions />}
          label=""
        >
          <Form isEdit />
        </EditAdmin>
      </Box>
      <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
        <QuestionShow {...props} />
        <ChildUnitShow {...props} />
      </Box>
    </div>
  );
};

export default Edit;
