import React, { FC, useState } from "react";
import { Datagrid, List, TextField, useTranslate } from "react-admin";
import { useLocation } from "react-router";
import DateTimeField from "../components/DateTimeField";
import { ContestMode } from "../contests/List";
import ListActions from "./ListActions";
import ListFilter from "./ListFilters";
import { useDispatch, useSelector } from "react-redux";
import { changeTypeGrade } from "../configuration/actions";
import { AppState } from "../types";

const MajorList: FC<any> = (props) => {
  const translate = useTranslate();
  const location = useLocation();
  const dispatch = useDispatch();
  const grades = useSelector((state: AppState) => state.grades);
  if (location.search.includes("mode")) {
    const searchParams = new URLSearchParams(location.search);
    const searchMode: any = searchParams.get("mode");
    dispatch(changeTypeGrade(searchMode));
  }
  const params = new URLSearchParams(location.search);
  const mode = grades || ContestMode.Online;
  // console.log("grades", grades);
  return (
    <List
      {...props}
      title={"Danh sách lớp"}
      actions={<ListActions mode={mode} />}
      filters={<ListFilter />}
      filter={{ mode }}
      empty={<ListActions mode={mode} />}
      onPageChange={() => console.log("sang trang ở đây")}
    >
      <Datagrid optimized rowClick="edit">
        <TextField source="id" />
        <TextField source="name" label="Tên lớp" />
        <TextField source="status" label="Trạng thái" />
        <DateTimeField source="createdAt" />
      </Datagrid>
    </List>
  );
};

export default MajorList;
