import * as _ from "lodash";
import React, { FC } from "react";
import { Datagrid, List, TextField, useTranslate } from "react-admin";
import DateTimeField from "../components/DateTimeField";
import ListActions from "./ListActions";
import ListFilter from "./ListFilters";
import { ReferenceField } from "react-admin";
import { Button } from "@material-ui/core";
import { useUpdate } from "react-admin";
import ChipField from "../components/ChipField";

export enum QuestionReportStatus {
  Open = "open",
  Done = "done",
}

const ActionButton = (props: any) => {
  const { record } = props;

  const status = _.get(props, "record.status");
  const [updateDone, { loading: loadingDone }] = useUpdate(
    "questionreports",
    record.id,
    { status: QuestionReportStatus.Done },
    record
  );

  const [updateReopen, { loading: loadingReopen }] = useUpdate(
    "questionreports",
    record.id,
    { status: QuestionReportStatus.Open },
    record
  );

  if (status === QuestionReportStatus.Done) {
    return (
      <Button
        color="primary"
        variant="contained"
        onClick={() => {
          updateReopen();
        }}
      >
        Reopen
      </Button>
    );
  } else if (status === QuestionReportStatus.Open) {
    return (
      <Button
        color="primary"
        variant="contained"
        onClick={() => {
          updateDone();
        }}
      >
        Ok
      </Button>
    );
  } else {
    return <></>;
  }
};
const QuestionInfo = (props: any) => {
  return (
    <>
      {props.record.unitId && (
        <ReferenceField
          {...props}
          source="unitId"
          reference="units"
          link={(record: any) => `/units/${record.unitId}`}
        >
          <TextField source="name" />
        </ReferenceField>
      )}
      {props.record.contestId && (
        <ReferenceField
          {...props}
          source="contestId"
          reference="contests"
          link={(record: any) => `/contests/${record.contestId}`}
        >
          <TextField source="name" />
        </ReferenceField>
      )}
    </>
  );
};

const SectionOrPeriodInfo = (props: any) => {
  return (
    <>
      {props.record.unitId && (
        <ReferenceField {...props} source="unitId" reference="units">
          <ReferenceField
            source="sectionId"
            reference="sections"
            link={(record: any) => `/sections/${record.sectionId}`}
          >
            <TextField source="name" />
          </ReferenceField>
        </ReferenceField>
      )}
      {props.record.contestId && (
        <ReferenceField {...props} source="contestId" reference="contests">
          <ReferenceField
            source="periodId"
            reference="periods"
            link={(record: any) => `/periods/${record.periodId}`}
          >
            <TextField source="name" />
          </ReferenceField>
        </ReferenceField>
      )}
    </>
  );
};

const CourseOrGradeInfo = (props: any) => {
  return (
    <>
      {props.record.unitId && (
        <ReferenceField {...props} source="unitId" reference="units">
          <ReferenceField source="sectionId" reference="sections">
            <ReferenceField
              source="courseId"
              reference="courses"
              link={(record: any) => `/courses/${record.courseId}`}
            >
              <TextField source="name" />
            </ReferenceField>
          </ReferenceField>
        </ReferenceField>
      )}
      {props.record.contestId && (
        <ReferenceField {...props} source="contestId" reference="contests">
          <ReferenceField source="periodId" reference="periods">
            <ReferenceField
              source="gradeId"
              reference="grades"
              link={(record: any) => `/grades/${record.gradeId}`}
            >
              <TextField source="name" />
            </ReferenceField>
          </ReferenceField>
        </ReferenceField>
      )}
    </>
  );
};

const MajorList: FC<any> = (props) => {
  const translate = useTranslate();

  return (
    <List
      {...props}
      title={"Quản lý report"}
      actions={<ListActions />}
      filters={<ListFilter />}
      sort={{ field: "id", order: "DESC" }}
    >
      <Datagrid optimized>
        <TextField source="id" />
        <TextField source="content" label="Mô tả" />
        <ReferenceField
          source="questionId"
          reference="questions"
          label="Câu hỏi số"
        >
          <TextField source="id" />
        </ReferenceField>

        <ReferenceField
          source="questionId"
          reference="questions"
          label="Bài học con"
        >
          <QuestionInfo />
        </ReferenceField>

        <ReferenceField
          source="questionId"
          reference="questions"
          label="Bài học cha"
        >
          <ReferenceField source="unitId" reference="units">
            <ReferenceField
              source="parentId"
              reference="units"
              link={(record: any) => `/units/${record.parentId}`}
            >
              <TextField source="name" />
            </ReferenceField>
          </ReferenceField>
        </ReferenceField>

        <ReferenceField
          source="questionId"
          reference="questions"
          label="Chương"
        >
          <SectionOrPeriodInfo />
        </ReferenceField>

        <ReferenceField source="questionId" reference="questions" label="Khóa">
          <CourseOrGradeInfo />
        </ReferenceField>

        <DateTimeField source="createdAt" label="Ngày tạo" />
        <ChipField source="status" label="Trạng thái" />
        <ActionButton label="Action" />
      </Datagrid>
    </List>
  );
};

export default MajorList;
