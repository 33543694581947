import { CircularProgress } from "@material-ui/core";
import { PlusOneOutlined } from "@material-ui/icons";
import _ from "lodash";
import React, { FC, useCallback, useState } from "react";
import { useNotify } from "react-admin";
import { useInput } from "react-admin";
import "./upload.css";

interface IProps {}

const UploadImage: FC<any> = (props) => {
  const inputProps = useInput(props);
  const { label } = props;
  const {
    input,
    meta: { touched, error },
  } = inputProps;

  const noti = useNotify();
  const [loading, setLoading] = useState<boolean>();

  const uploadButton = (
    <div className="block-upload-image">
      {loading ? <CircularProgress /> : <PlusOneOutlined />}
      <div style={{ marginTop: 8 }}>{label || "Tải ảnh lên"}</div>
    </div>
  );
  const hostname = `${
    process.env.REACT_APP_API_BASE_URL || "http://localhost:4004"
  }`;
  const [show, setShow] = useState(false);
  const uploadImage = useCallback((file: any) => {
    setLoading(true);
    let formdata = new FormData();
    formdata.append("file", file);

    const token = localStorage.getItem("user_token");
    const requestOptions = {
      method: "POST",
      body: formdata,
      headers: { Authorization: `Bearer ${token}` },
    };

    fetch(`${hostname}/api/upload/image`, requestOptions)
      .then((response) => {
        setLoading(false);
        return response.json();
      })
      .then((success) => {
        const image = _.get(success, "data.url");
        input.onChange(image);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, []);

  return (
    <div
      className="block-upload-image-wrapper"
      style={{
        filter: props.checkDisableForm ? `brightness(80%)` : "brightness(1)",
      }}
    >
      {input.value && (
        <label className="block-upload-image">
          <img
            src={
              input.value.includes("http")
                ? `${input.value}`
                : `${hostname}${input.value}`
            }
            alt="avatar"
            className="block-upload-image-preview"
          />
        </label>
      )}

      <label className="block-upload-image">
        <input
          disabled={props.checkDisableForm}
          hidden
          onChange={(e) => {
            if (e.target.files) {
              const file = e.target.files[0];
              if (file.size >= 2 * 1024 * 1024) {
                noti("Ảnh vượt quá 2Mb", "warning");
                return;
              }
              uploadImage(file);
            }
          }}
          type="file"
          accept="image/png, image/jpeg"
        />
        {uploadButton}
      </label>
    </div>
  );
};

export default UploadImage;
