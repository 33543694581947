import * as _ from "lodash";
import React, { cloneElement, FC } from "react";
import ListIcon from "@material-ui/icons/List";
import {
  CreateButton,
  ListButton,
  sanitizeListRestProps,
  TopToolbar,
  Button,
} from "react-admin";
import { useHistory, useRouteMatch } from "react-router";
import { Link } from "react-router-dom";
import { ButtonBase } from "@material-ui/core";

const ListActions: FC<any> = ({
  currentSort,
  className,
  resource,
  filters,
  displayedFilters,
  exporter, // you can hide ExportButton if exporter = (null || false)
  filterValues,
  permanentFilter,
  hasCreate, // you can hide CreateButton if hasCreate = false
  basePath,
  selectedIds,
  onUnselectItems,
  showFilter,
  maxResults,
  total,
  hasShow,
  hasList,
  mode,
  ...rest
}) => {
  const history = useHistory();
  const periodId = _.get(rest, "data.periodId", undefined);

  return (
    <TopToolbar
      className={className}
      {...sanitizeListRestProps(rest)}
      hasshow={`${hasShow}`}
      haslist={`${hasList}`}
    >
      {/* {!filters && <ListButton />} */}
      {!filters && (
        <Button
          component="button"
          variant="text"
          label="pos.action.listing"
          onClick={() => {
            if (periodId) {
              history.push(`/periods/${periodId}`);
            } else {
              history.goBack();
            }
          }}
        >
          <ListIcon style={{ fontSize: "20" }} />
        </Button>
      )}
      {filters &&
        cloneElement(filters, {
          undoable: "false",
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button",
        })}
      {/* {!!periodId && <CreateButton basePath={basePath} />} */}
    </TopToolbar>
  );
};

export default ListActions;
