import React, { FC } from "react";
import { FieldProps } from "../types";
import { required, SelectInput, useTranslate } from "react-admin";

interface Props extends FieldProps<any> {
  className?: string;
  fullWidth?: any;
  resource?: string;
  disabled?: boolean;
}
const requiredValidate = [required()];
const DisplayInput: FC<Props> = ({
  record,
  className,
  disabled,
  source,
  ...rest
}) => {
  const translate = useTranslate();

  return (
    <SelectInput
      disabled={disabled}
      validate={requiredValidate}
      {...rest}
      source={source}
      choices={[
        { id: 1, name: "Thông báo đẩy" },
        { id: 2, name: "Trong mục thông báo" },
        { id: 3, name: "Cả 2" },
      ]}
    />
  );
};

export default DisplayInput;
