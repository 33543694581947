import { unparse as convertToCSV } from "papaparse";
import React, { FC } from "react";
import {
  BooleanField,
  BulkDeleteButton,
  Datagrid,
  downloadCSV,
  List,
  ReferenceField,
  TextField,
  useTranslate,
} from "react-admin";
import ChipField from "../components/ChipField";
import DateTimeField from "../components/DateTimeField";
import ListActions from "./ListActions";
import ListFilter from "./ListFilters";
import LocksButton from "./LocksButton";

const exporter = (codes: any) => {
  const csv = convertToCSV({
    data: codes,
    // select and order fields in the export
    fields: ["code"],
  });
  downloadCSV(csv, "codes"); // download as 'posts.csv` file
};

const PostBulkActionButtons = (props: any) => (
  <>
    <LocksButton label="Lock" {...props} isLock />
    <LocksButton label="UnLock" {...props} isLock={false} />
    <BulkDeleteButton {...props} />
  </>
);

const MajorList: FC<any> = (props) => {
  const translate = useTranslate();

  return (
    <List
      {...props}
      title={translate("resources.cardcodes.page.list")}
      actions={<ListActions />}
      bulkActionButtons={<PostBulkActionButtons />}
      filters={<ListFilter />}
      exporter={exporter}
    >
      <Datagrid optimized>
        <ChipField source="code" />
        <BooleanField source="isLocked" />
        <TextField source="series" />
        <ReferenceField source="cardId" reference="cards">
          <TextField source="displayName" />
        </ReferenceField>
        {/* <ReferenceManyField
          label={translate("resources.cardcodes.fields.used")}
          reference="studentcardcodes"
          target="cardCodeId">
          <SingleFieldList>
            <ReferenceField source="studentId" reference="students">
              <TextField source="fullname" />
            </ReferenceField>
          </SingleFieldList>
        </ReferenceManyField> */}
        <BooleanField source="used" />
        <DateTimeField source="createdAt" />
      </Datagrid>
    </List>
  );
};

export default MajorList;
