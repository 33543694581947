import React, { FC } from "react";
import { FieldProps } from "../types";
import { required, SelectInput, useTranslate } from "react-admin";

interface Props extends FieldProps<any> {
  className?: string;
  fullWidth?: any;
  resource?: string;
  setVarCheckTimepublic?: any;
  setChangeform?: any;
  disabled?: boolean;
}
const requiredValidate = [required()];
const SendTime: FC<Props> = ({
  record,
  className,
  source,
  setVarCheckTimepublic,
  setChangeform,
  disabled,
  ...rest
}) => {
  const translate = useTranslate();

  return (
    <SelectInput
      validate={requiredValidate}
      onChange={(e: any) => {
        setVarCheckTimepublic(e.target.value);
        setChangeform(true);
      }}
      disabled={disabled}
      {...rest}
      source={source}
      label="Thời gian gửi"
      choices={[
        { id: "send_now", name: "Gửi ngay" },
        { id: "timer", name: "Hẹn giờ" },
        { id: "birthday", name: "Ngày sinh nhật" },
      ]}
    />
  );
};

export default SendTime;
