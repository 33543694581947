import * as _ from "lodash";
import PreviewInputImage from "../components/PreviewInputImage";
import React from "react";
import { Box } from "@material-ui/core";
import CloudDownloadIcon from "@material-ui/icons/CloudDownloadOutlined";
import {
  ArrayInput,
  ImageInput,
  SimpleFormIterator,
  TextInput,
  useTranslate,
  useInput,
  Button,
} from "react-admin";
import { ImportButton } from "ra-import-csv";
import { slugify } from "../helpers";
import * as FileSaver from "file-saver";
import UploadAudio from "../components/UploadAudio";
import UploadImage from "../components/UploadImage";

const HEADER_MAPPER: any = {
  tu_vung: "word",
  danh_van: "spell",
  nghia: "mean",
  vi_du: "example",
  loai_tu: "partOfSpeech",
};

const configImportQuestion = {
  parseConfig: {
    header: true,
    transformHeader: (h: string) => {
      const slug = slugify(h);
      return HEADER_MAPPER[slug] || slug;
    },
  },
};

export const ImportVocabulary = (props: any) => {
  const translate = useTranslate();
  const inputProps = useInput(props);
  const {
    input,
    meta: { touched, error },
  } = inputProps;

  console.log(props, inputProps);

  return (
    <>
      <Button
        color="primary"
        component="button"
        variant="text"
        label="pos.action.downloadTemplateVocab"
        onClick={() => {
          FileSaver.saveAs(
            `${process.env.REACT_APP_API_BASE_URL}/public/templates/example-import-vocabulary.xlsx`,
            "phiếu đề xuất.docx"
          );
        }}
      >
        <CloudDownloadIcon style={{ fontSize: "20" }} />
      </Button>
      <ImportButton
        resource="questions"
        basePath={`/questions`}
        {...configImportQuestion}
        label={translate("pos.action.importVocabulary")}
        showOverWrite={false}
        handleValues={(values: any[], callback: Function) => {
          input.onChange(values);
          callback();
        }}
      />
    </>
  );
};

export const Vocabulary = (props: any) => {
  return (
    <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
      <ArrayInput source="vocabMetas" resource="questions">
        <SimpleFormIterator>
          <TextInput source="word" resource="questions" />
          <TextInput
            source="partOfSpeech"
            resource="questions"
            label="Loại từ"
          />
          <UploadAudio source="audio" resource="questions" />
          <TextInput source="spell" resource="questions" />
          <TextInput source="mean" resource="questions" />
          <UploadImage source="thumb" resource="questions" />
          <TextInput source="example" resource="questions" />
          <TextInput source="other" resource="questions" />
        </SimpleFormIterator>
      </ArrayInput>
    </Box>
  );
};

export default Vocabulary;
