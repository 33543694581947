import * as _ from "lodash";
import React, { FC } from "react";
import {
  Datagrid,
  List,
  ReferenceField,
  TextField,
  useTranslate,
} from "react-admin";
import DateTimeField from "../components/DateTimeField";
import AvatarField from "../components/AvatarField";
import { HomeBlockModelName } from "./Create";
import ListActions from "./ListActions";
import ListFilter from "./ListFilters";

const MajorList: FC<any> = (props) => {
  const translate = useTranslate();
  return (
    <List
      {...props}
      title={translate("resources.posts.page.list")}
      actions={<ListActions />}
      filters={<ListFilter />}
    >
      <Datagrid optimized rowClick="edit">
        <TextField source="title" label="Tiêu đề" />
        <TextField source="sequenceNo" label="Thứ tự" />

        <DateTimeField source="createdAt" label="Ngày tạo" />
      </Datagrid>
    </List>
  );
};

export default MajorList;
