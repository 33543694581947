import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@material-ui/core";
import React, { useCallback, useState } from "react";
import { useNotify, useRefresh, useTranslate } from "react-admin";

export const PopupUpload = (props: any) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const [file, setFile] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>();
  const hostname = `${
    process.env.REACT_APP_API_BASE_URL || "http://localhost:4004"
  }`;

  const { isShowApproved, showApproved } = props;
  const uploadHomophones = useCallback(() => {
    if (!file) return;
    setLoading(true);
    let formdata = new FormData();
    formdata.append("file", file);

    const token = localStorage.getItem("user_token");
    const requestOptions = {
      method: "POST",
      body: formdata,
      headers: { Authorization: `Bearer ${token}` },
    };

    fetch(`${hostname}/api/homophones/upload`, requestOptions)
      .then((response) => {
        setLoading(false);
        return response.json();
      })
      .then((success) => {
        notify("Tải lên thành công", "info");
        showApproved(false);
        refresh();
      })
      .catch((error) => {
        notify("Tải lên thành công", "error");
        setLoading(false);
      });
  }, [file]);
  return (
    <Dialog
      open={isShowApproved}
      maxWidth="lg"
      onClose={() => showApproved(false)}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Tải file excel lên</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <input
            type="file"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            onChange={(e) => {
              if (e.target.files) {
                const file = e.target.files[0];
                setFile(file);
              }
            }}
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => showApproved(false)} color="primary">
          Hủy bỏ
        </Button>
        <Button
          onClick={() => {
            uploadHomophones();
          }}
          color="primary"
        >
          Tải lên
        </Button>
      </DialogActions>
    </Dialog>
  );
};
