import ListActions from "./ListActions";
import React, { useEffect, useState } from "react";
import { Edit as EditAdmin } from "react-admin";
import { Form } from "./Create";
import { customHttpClientAdmin } from "../dataProvider";

const Edit = (props: any) => {
  const [dataSlider, setDataSlider] = useState<any>(null);
  const initData = async () => {
    try {
      await customHttpClientAdmin(
        `/admin/advertisements/${props?.match.params.id}`,
        {
          method: "GET",
        }
      )
        .then((res: any) => res.json)
        .then((response: any) => {
          setDataSlider(response);
        })
        .catch((error: any) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    initData();
  }, []);

  if (!dataSlider) {
    return <div>Loading...</div>;
  }

  return (
    // <EditAdmin
    //   undoable={false}
    //   component="div"
    //   {...props}
    //   actions={<ListActions />}>
    <Form
      dataSlider={dataSlider?.dataSlider}
      idSlider={props?.match?.params.id}
      isEditSlider
      createdBy={props?.permissions?.email}
    />
    // </EditAdmin>
  );
};

export default Edit;
