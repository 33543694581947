import * as _ from "lodash";
import React, { FC } from "react";
import {
  Filter,
  NumberInput,
  TextInput,
  ReferenceInput,
  AutocompleteInput,
} from "react-admin";

const ListFilter: FC<any> = (props) => (
  <Filter {...props}>
    <NumberInput source="id" />
    <TextInput source="name" />
    <ReferenceInput
      source="gradeId"
      reference="grades"
      resource="grades"
      filterToQuery={(searchText: any) => {
        return _.isEmpty(_.trim(searchText)) ? null : { name: searchText };
      }}
    >
      <AutocompleteInput fullWidth optionText={"name"} />
    </ReferenceInput>
    <ReferenceInput
      source="periodId"
      reference="periods"
      resource="periods"
      filterToQuery={(searchText: any) => {
        return _.isEmpty(_.trim(searchText)) ? null : { name: searchText };
      }}
    >
      <AutocompleteInput fullWidth optionText={"name"} />
    </ReferenceInput>
  </Filter>
);

export default ListFilter;
