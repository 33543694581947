import React, { FC } from "react";
import { FieldProps } from "../types";
import { required, SelectInput, useTranslate } from "react-admin";

interface Props extends FieldProps<any> {
  className?: string;
  fullWidth?: any;
  resource?: string;
  setnotiType?: any;
  setChangeform?: any;
  disable?: boolean;
}
const requiredValidate = [required()];
const TypeNotiInput: FC<Props> = ({
  record,
  className,
  setnotiType,
  source,
  setChangeform,
  disable,
  ...rest
}) => {
  const translate = useTranslate();

  return (
    <SelectInput
      validate={requiredValidate}
      label="Loại thông báo"
      onChange={(e: any) => {
        setnotiType(`${e.target.value}`);
        setChangeform(true);
      }}
      {...rest}
      disabled={disable}
      source={source}
      choices={[
        { id: "watch_new", name: "Xem tin tức" },
        { id: "watch_course", name: "Xem khóa học" },
        { id: "watch_last_lesson", name: "Xem bài học gần nhất" },
        { id: "watch_login", name: "Xem trang đăng nhập" },
        { id: "watch_card_active", name: "Xem trang kích hoạt" },
        { id: "watch_home_page", name: "Xem trang chủ" },
        { id: "watch_landing_page", name: "Xem landing page" },
        { id: "watch_post", name: "Xem bài viết" },
        { id: "watch_nothing", name: "Thông báo không có điều hướng" },
      ]}
    />
  );
};

export default TypeNotiInput;
