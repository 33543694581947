import React, { cloneElement, FC } from "react";
import {
  CreateButton,
  ListButton,
  sanitizeListRestProps,
  TopToolbar,
  ExportButton,
} from "react-admin";

const ListActions: FC<any> = ({
  currentSort,
  className,
  resource,
  filters,
  displayedFilters,
  exporter, // you can hide ExportButton if exporter = (null || false)
  filterValues,
  permanentFilter,
  hasCreate, // you can hide CreateButton if hasCreate = false
  basePath,
  selectedIds,
  onUnselectItems,
  showFilter,
  maxResults,
  total,
  hasShow,
  hasList,
  ...rest
}) => {
  return (
    <TopToolbar
      className={className}
      {...sanitizeListRestProps(rest)}
      hasshow={`${hasShow}`}
      haslist={`${hasList}`}>
      {!filters && <ListButton basePath={basePath} />}
      {filters &&
        cloneElement(filters, {
          undoable: "false",
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button",
        })}
      <CreateButton basePath={basePath} />
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filterValues={filterValues}
        maxResults={10000000000}
      />
    </TopToolbar>
  );
};

export default ListActions;
