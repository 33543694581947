import {
  Box,
  Button,
  Card,
  CardContent,
  Modal,
  TextField,
  Typography,
} from "@material-ui/core";
import { Add, Delete } from "@material-ui/icons";
import * as _ from "lodash";
import React, { useEffect, useState } from "react";
import { CheckboxGroupInput } from "react-admin";
import { DateInput } from "react-admin";
import {
  Create as CreateAdmin,
  DeleteButton,
  FormWithRedirect,
  required,
  SaveButton,
  SelectInput,
  TextInput,
  Toolbar,
  useNotify,
} from "react-admin";
import DisplayInput from "../components/DisplayInput";
import FormFillterUser from "../components/FormFillterUser";
import ObjectSendInput from "../components/ObjectSendInput";
import { RichTextInput } from "../components/RichTextInput";
import SendTime from "../components/SendTime";
import StatusInput from "../components/StatusInput";
import TimeInput from "../components/TimeInput";
import UploadImage from "../components/UploadImage";
import ListActions from "./ListActions";
import { minValue } from "react-admin";
import CourseList from "../components/CourseList";
import NewsList from "../components/NewsList";
import { customHttpClientAdmin } from "../dataProvider";
import { useRedirect } from "react-admin";
import GroupInput from "../components/GroupInput";

export enum NotificationFilter {
  AutoNotDoingCourse = "auto_not_doing_course",
  AutoBirthday = "auto_birthday",
  AutoUnderExpire = "auto_under_expire",
  AutoExpired = "auto_expired",
  AutoDaily = "auto_daily",
}

export const NotificationFilterName: { [a: string]: string } = {
  [NotificationFilter.AutoDaily]: "Hằng ngày",
  [NotificationFilter.AutoExpired]: "Hết hạn",
  [NotificationFilter.AutoUnderExpire]: "Sắp hết hạn",
  [NotificationFilter.AutoBirthday]: "Sinh nhật",
  [NotificationFilter.AutoNotDoingCourse]: "Chưa làm khóa học",
};

interface daysList {
  id: string;
  name: string;
}

export const Form = (props: any) => {
  const [filterType, setFilterType] = useState(
    _.get(props, "record.filterType", NotificationFilter)
  );
  const [notiType, setnotiType] = useState<string>("");
  const [varCheckTimepublic, setVarCheckTimepublic] = useState<string>("");
  const [datepublic, setDatepublic] = useState<Date>(new Date());
  const [typeSetTime, setTypeSetTime] = useState<string>("");
  const [changeForm, setChangeform] = useState<boolean>(false);
  const [News, setNews] = useState<string>("");
  const [course, setCourse] = useState<string>("");
  const [statusCard, setStatusCard] = useState("");
  const [objectSend, setObjectSend] = useState<any>();
  const [timeSend, setTimeSend] = useState<any>(props.record.send_time);
  const [dateSend, setDateSend] = useState<any>(props.record.send_once);
  const [lable, setLable] = useState<string>("Chọn ngày");
  const [ischeck, setIscheck] = useState<boolean>(false);

  const validateUserCreation = async (values: any) => {
    const errors: any = {};
    if (values.status === "complete") {
      errors.status = "Required *";
    }
    return errors;
  };

  const [checkinput, setCheckInput] = useState<boolean>(false);
  const academicLevelChoices = [
    { optionValue: "2", label: "T2" },
    { optionValue: "3", label: "T3" },
    { optionValue: "4", label: "T4" },
    { optionValue: "5", label: "T5" },
    { optionValue: "6", label: "T6" },
    { optionValue: "7", label: "T7" },
    { optionValue: "8", label: "CN" },
  ];
  const receivedItems: any = props.record.value_timers
    ? props.record.value_timers
    : [];

  const [academicLevel, setAcademicLevel] = useState(
    academicLevelChoices.map((option) => {
      if (receivedItems.indexOf(option.optionValue) != -1) {
        return {
          ...option,
          value: true,
        };
      }
      return {
        ...option,
        value: false,
      };
    })
  );
  const newList = academicLevel
    .filter((item: any) => item.value)
    .map((item: any) => `${item.optionValue}`);

  const style = {
    position: "absolute" as "absolute",
    top: "25%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  const requiredValidate = [required()];
  const requiredValidateTime = [
    required(),
    minValue(
      `${datepublic.getFullYear()}-${
        datepublic.getMonth() + 1 >= 10
          ? datepublic.getMonth() + 1
          : `0${datepublic.getMonth() + 1}`
      }-${
        datepublic.getDate() >= 10
          ? datepublic.getDate()
          : `0${datepublic.getDate()}`
      }`
    ),
  ];
  const redirect = useRedirect();

  const daysList: daysList[] = [
    { id: "2", name: "T2" },
    { id: "3", name: "T3" },
    { id: "4", name: "T4" },
    { id: "5", name: "T5" },
    { id: "6", name: "T6" },
    { id: "7", name: "T7" },
    { id: "8", name: "CN" },
  ];

  function CheckTimepublic(props: any) {
    const disable = props.disabled;

    if (varCheckTimepublic === "timer") {
      return (
        <>
          <Box flex={2} ml={{ xs: 0, sm: "0.5em" }}>
            <SelectInput
              label="Loại hẹn giờ"
              source="type_timers"
              validate={requiredValidate}
              resource="units"
              disabled={disable}
              onChange={(e: any) => {
                setChangeform(true);
                setTypeSetTime(e.target.value);
              }}
              choices={[
                { id: "once", name: "Một lần" },
                { id: "daily", name: "Lặp lại hằng ngày" },
                { id: "weekly", name: "Lặp lại hằng tuần" },
                { id: "monthly", name: "Lặp lại hằng tháng" },
              ]}
            />
          </Box>
          {typeSetTime !== "" && (
            <Box flex={3} ml={{ xs: 0, sm: "0.5em" }}>
              <TimeInput
                validate={requiredValidate}
                source="send_time"
                label="Hẹn giờ"
                disabled={disable}
                setTimeSend={setTimeSend}
              />
            </Box>
          )}
          <>
            {typeSetTime === "weekly" ? (
              <Box flex={4} ml={{ xs: 0, sm: "0.5em" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <p
                    style={{
                      paddingRight: "1vw",
                      color: checkinput ? "red" : "black",
                    }}
                  >
                    {checkinput ? "Required" : "Chọn ngày:"}
                  </p>
                  <GroupInput
                    disabled={disable}
                    academicLevelChoices={academicLevelChoices}
                    setAcademicLevel={setAcademicLevel}
                    academicLevel={academicLevel}
                    setCheckInput={setCheckInput}
                  />
                </div>
              </Box>
            ) : typeSetTime === "once" ? (
              <Box flex={4} ml={{ xs: 0, sm: "0.5em" }}>
                <DateInput
                  source="send_once"
                  label="Ngày gửi"
                  disabled={disable}
                  onChange={(e: any) => setDateSend(e.target.value)}
                  validate={requiredValidate}
                />
              </Box>
            ) : typeSetTime === "monthly" ? (
              <Box flex={4} ml={{ xs: 0, sm: "0.5em" }}>
                <TextInput
                  fullWidth={true}
                  placeholder="Nhập ngày ngăn cách bởi dấu , VD: 1,7,12"
                  label={lable}
                  onChange={() =>
                    setLable("Nhập ngày ngăn cách bởi dấu , VD: 1,7,12")
                  }
                  disabled={disable}
                  source="value_timers"
                  validate={requiredValidate}
                  resource="notifications"
                />
              </Box>
            ) : (
              <></>
            )}
          </>
        </>
      );
    } else if (varCheckTimepublic === "birthday")
      return (
        <Box flex={2} ml={{ xs: 0, sm: "0.5em" }}>
          <TimeInput
            validate={requiredValidate}
            source="send_time"
            label="Hẹn giờ"
          />
        </Box>
      );
    else return <></>;
  }

  const notify = useNotify();
  interface listNotifycation {
    id: number;
    title: string;
    content: string;
    errorTitle: boolean;
    errorContent: boolean;
  }
  const [listNoti, setListNoti] = useState<listNotifycation[]>([
    {
      id: 1,
      title: "",
      content: "",
      errorTitle: true,
      errorContent: true,
    },
  ]);

  const [checkEmpty, setCheckEmpty] = useState<boolean>(false);

  useEffect(() => {
    const newList = listNoti.filter((element) => {
      return element.errorContent || element.errorTitle;
    });
    if (newList.length === 0) setCheckEmpty(true);
    else setCheckEmpty(false);
  }, [listNoti]);

  function CheckTypeNoti(props: any) {
    const disabled = props.disabled;
    if (notiType === "watch_landing_page") {
      return (
        <Box flex={2} ml={{ xs: 0, sm: "0.5em" }}>
          <TextInput
            source="link_page"
            validate={requiredValidate}
            resource="notifications"
            disabled={disabled}
          />
        </Box>
      );
    } else if (notiType === "watch_post") {
      return (
        <>
          <Box flex={2} ml={{ xs: 0, sm: "0.5em" }}>
            <TextInput
              label="Tiêu đề bài viết"
              type="text"
              fullWidth
              source="title_post"
              validate={requiredValidate}
              resource="notifications"
              disabled={disabled}
            />
          </Box>
          <Box flex={3} ml={{ xs: 0, sm: "0.5em" }}>
            <TextInput
              fullWidth
              source="slug_post"
              label="Mô tả ngắn"
              resource="notifications"
              disabled={disabled}
            />
          </Box>
        </>
      );
    } else if (notiType === "watch_course") {
      return (
        <CourseList
          source="course_id"
          resource="notifications"
          setCourse={setCourse}
          disabled={disabled}
        />
      );
    } else if (notiType === "watch_new") {
      return (
        <NewsList
          source="post_id"
          resource="notifications"
          setNews={setNews}
          disabled={disabled}
        />
      );
    } else return <></>;
  }

  useEffect(() => {
    const mix = props.record?.notifications_mix?.map((item: any) => {
      return {
        id: item.id,
        title: item.title_mix,
        content: item.content_mix,
      };
    });
    if (mix) setListNoti(mix);
  }, [props]);

  const btn = document.getElementsByClassName(
    "MuiButton-sizeSmall"
  ) as HTMLCollectionOf<HTMLElement>;

  useEffect(() => {
    btn[2].style["display"] = "none";
  }, []);
  const [open, setOpen] = React.useState(false);

  return (
    <FormWithRedirect
      {...props}
      validate={validateUserCreation}
      initialValues={{
        filterType: NotificationFilter,
        mode: 2,
        type: "watch_home_page",
      }}
      render={(formProps: any) => {
        const handleOpen = () => setOpen(true);
        const handleClose = () => setOpen(false);
        let obj = formProps.form.getState().errors;
        console.log(obj);
        function isEmpty(object: any) {
          return Object.keys(object).length === 0;
        }
        let emptyObj = isEmpty(obj);

        const checkDisableForm =
          (formProps.record.send_type === "send_now" ||
            (new Date(
              `${formProps.record.send_once}:${formProps.record.send_time}`
            ).getTime() -
              new Date().getTime() <
              0 &&
              formProps.record.send_type === "timer" &&
              formProps.record.type_timers === "once")) &&
          formProps.record.id &&
          formProps.record.status !== "draft";

        if (!changeForm) {
          setnotiType(`${formProps.record.type ?? formProps.record.type}`);
          setObjectSend(
            formProps.record.is_send_all === 1
              ? 1
              : formProps.record.is_send_all === 0
              ? 0
              : null
          );
          setStatusCard(
            `${
              formProps.record.card_expiry_type
                ? formProps.record.card_expiry_type
                : ""
            }`
          );
          setVarCheckTimepublic(
            `${formProps.record.send_type ? formProps.record.send_type : null}`
          );
          setTypeSetTime(
            `${
              formProps.record.type_timers ? formProps.record.type_timers : null
            }`
          );
        }

        const handleOnsubmitNoti = async (data: any) => {
          console.log(111);

          if (
            new Date(`${dateSend}:${timeSend}`).getTime() -
              new Date().getTime() <
              0 &&
            typeSetTime === "once" &&
            varCheckTimepublic === "timer"
          ) {
            notify(
              "Thời gian hẹn giờ không được nhỏ hơn thời gian hiện tại, vui lòng kiểm tra lại."
            );
          } else if (
            typeSetTime === "weekly" &&
            newList.length < 1 &&
            varCheckTimepublic === "timer"
          ) {
            setCheckInput(true);
          } else {
            const dataObject = {
              ...data,
              mode: 2,
              mix: listNoti,
              is_send_all: objectSend === 1 ? 1 : 0,
              status: data.status === "complete" ? null : data.status,
              value_timers:
                typeSetTime === "weekly" && varCheckTimepublic === "timer"
                  ? newList + ""
                  : typeSetTime === "monthly" && varCheckTimepublic === "timer"
                  ? data.value_timers + ""
                  : null,
            };

            customHttpClientAdmin(
              `/admin/notifications/${
                formProps.record.id ? formProps.record.id + "/update" : "create"
              }`,
              {
                method: "POST",
                body: JSON.stringify(dataObject),
              }
            )
              .then((res: any) => res.json)
              .then((success: any) => {
                if (success.status === 200) {
                  redirect("/pushers");
                  notify(
                    `${
                      formProps.record.id
                        ? "Chỉnh sửa thông báo thành công"
                        : "Thêm thông báo thành công"
                    }`
                  );
                } else console.log(success);
              })
              .catch((error: any) => {
                handleClose();
                if (error.message === "value_timers_invalid")
                  notify("Số ngày bạn nhập sai định dạng");
                else if (error.message === "The status field is required.") {
                  notify("Vui lòng chọn trạng thái của thông báo");
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  });
                } else if (error.message === "")
                  notify("Vui lòng nhập nội dung thông báo!");
                console.log(error, "error");
              });
          }
        };
        return (
          <Card>
            <form>
              <CardContent>
                <Box display={{ md: "block", lg: "flex" }}>
                  <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                    <TextInput
                      type="text"
                      fullWidth={true}
                      source="title"
                      validate={requiredValidate}
                      disabled={checkDisableForm}
                      resource="notifications"
                    />
                  </Box>
                  <Box display={{ md: "block", lg: "flex" }}>
                    <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                      <StatusInput
                        source="status"
                        label="Trạng thái"
                        disabled={checkDisableForm}
                        resource="notifications"
                        status={formProps.record.status}
                        isCheckCreate={formProps.record.id}
                      />
                    </Box>
                  </Box>
                  <Box display={{ md: "block", lg: "flex" }}>
                    <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                      <DisplayInput
                        label="Hiển thị"
                        source="display"
                        resource="notifications"
                        disabled={checkDisableForm}
                      />
                    </Box>
                  </Box>
                  <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                    <UploadImage
                      source="imageUrl"
                      checkDisableForm={checkDisableForm}
                    />
                  </Box>
                </Box>
                {listNoti?.map((item, index) => (
                  <Box
                    display={{ md: "block", lg: "flex" }}
                    style={{ marginTop: "2vh" }}
                  >
                    <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                      <TextField
                        error={item.errorTitle && ischeck}
                        label={
                          item.errorTitle && ischeck
                            ? "Required"
                            : "Tiêu đề thông báo"
                        }
                        variant="outlined"
                        required
                        disabled={checkDisableForm}
                        value={item.title}
                        onChange={(e: any) => {
                          setListNoti(
                            listNoti?.map((noti, i) => {
                              if (i === index) {
                                return {
                                  ...item,
                                  title: e.target.value,
                                  errorTitle:
                                    e.target.value === "" ? true : false,
                                };
                              } else return noti;
                            })
                          );
                        }}
                      />
                    </Box>
                    {item.errorContent && ischeck && (
                      <p
                        style={{
                          fontSize: "12px",
                          color: "red",
                          lineHeight: "0px",
                        }}
                      >
                        Required*
                      </p>
                    )}
                    <Box
                      flex={2}
                      ml={{ xs: 0, sm: "0.5em" }}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <textarea
                        aria-label="Nội dung thông báo"
                        placeholder="Nội dung thông báo"
                        disabled={checkDisableForm}
                        value={item.content}
                        style={{
                          width: "100%",
                          height: 150,
                          resize: "none",
                          overflowY: "scroll",
                        }}
                        onChange={(e: any) => {
                          setListNoti(
                            listNoti?.map((noti, i) => {
                              if (i === index) {
                                return {
                                  ...item,
                                  content: e.target.value,
                                  errorContent:
                                    e.target.value === "" ? true : false,
                                };
                              } else return noti;
                            })
                          );
                        }}
                      />
                      <div>
                        {listNoti.length > 1 && (
                          <Button
                            disabled={checkDisableForm}
                            startIcon={<Delete />}
                            onClick={() => {
                              const filterResult = listNoti.filter(function (
                                element
                              ) {
                                return element.id !== item.id;
                              });
                              setListNoti(filterResult);
                            }}
                          >
                            Delete
                          </Button>
                        )}
                      </div>
                    </Box>
                  </Box>
                ))}

                <Box display={{ md: "block", lg: "flex" }}>
                  <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                    <Button
                      style={{ marginTop: "10px" }}
                      disabled={checkDisableForm}
                      onClick={() => {
                        setListNoti([
                          ...listNoti,
                          {
                            id: listNoti[listNoti.length - 1].id + 1,
                            title: "",
                            content: "",
                            errorContent: true,
                            errorTitle: true,
                          },
                        ]);
                        setCheckEmpty(false);
                      }}
                      variant="outlined"
                    >
                      Add <Add />
                    </Button>
                  </Box>
                </Box>

                <Box display={{ md: "block", lg: "flex" }}>
                  <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                    <TextField
                      label="Loại thông báo"
                      variant="outlined"
                      aria-readonly
                      value="Xem trang chủ"
                      style={{ marginTop: "2vh" }}
                      disabled={checkDisableForm}
                    />
                  </Box>
                  <CheckTypeNoti disabled={checkDisableForm} />
                </Box>
                {notiType === "watch_post" && (
                  <Box display={{ md: "block", lg: "flex" }}>
                    <Box display={{ md: "block", lg: "flex" }}>
                      <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                        <RichTextInput
                          source="content_post"
                          height={200}
                          resource="notifications"
                        />
                      </Box>
                    </Box>
                  </Box>
                )}
                <Box display={{ md: "block", lg: "flex" }}>
                  <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                    <ObjectSendInput
                      source="is_send_all"
                      resource="notifications"
                      setObjectSend={setObjectSend}
                      setChangeform={setChangeform}
                      disabled={checkDisableForm}
                    />
                  </Box>
                </Box>
                {objectSend === 0 && (
                  <FormFillterUser
                    statusCard={statusCard}
                    setStatusCard={setStatusCard}
                    setChangeform={setChangeform}
                  />
                )}
                <Box display={{ md: "block", lg: "flex" }}>
                  <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                    <SendTime
                      source="send_type"
                      resource="notifications"
                      setVarCheckTimepublic={setVarCheckTimepublic}
                      setChangeform={setChangeform}
                      disabled={checkDisableForm}
                    />
                  </Box>
                  <CheckTimepublic disabled={checkDisableForm} />
                </Box>
                {checkDisableForm && (
                  <p>
                    Thông báo đã được gửi đi lúc:{" "}
                    {varCheckTimepublic === "send_now"
                      ? new Date(formProps.record.updated_at).toLocaleString(
                          "en-GB",
                          {
                            hour12: false,
                          }
                        )
                      : new Date(
                          `${formProps.record.send_once}:${formProps.record.send_time}`
                        ).toLocaleString("en-GB", {
                          hour12: false,
                        })}
                  </p>
                )}
              </CardContent>
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    {formProps.form.getState().values.status === "draft"
                      ? "Không gửi được thông báo ở trạng thái nháp"
                      : "Gửi ngay"}
                  </Typography>
                  <Typography id="modal-modal-description">
                    {formProps.form.getState().values.status === "draft"
                      ? "Thông báo đang ở trạng thái nháp, nếu bạn muốn gửi ngay bây giờ vui lòng chỉnh sửa trạng thái thành hoạt động"
                      : "Thông báo sẽ được gửi đi ngay bây giờ, bạn có chắc chắn muốn gửi"}
                  </Typography>
                  <div style={{ display: "flex", justifyContent: "end" }}>
                    <Button
                      onClick={() => {
                        if (emptyObj) {
                          handleOnsubmitNoti(formProps.form.getState().values);
                        } else {
                          formProps.handleSubmitWithRedirect();
                        }
                      }}
                    >
                      {formProps.form.getState().values.status === "draft"
                        ? "Tiếp tục lưu nháp"
                        : "Gửi ngay"}
                    </Button>
                    <Button onClick={handleClose}>
                      {formProps.form.getState().values.status === "draft"
                        ? "Quay lại"
                        : "Chỉnh sửa"}
                    </Button>
                  </div>
                </Box>
              </Modal>

              <Toolbar
                record={formProps.record}
                basePath={formProps.basePath}
                invalid={formProps.invalid}
                handleSubmit={formProps.handleSubmit}
                saving={formProps.saving}
                undoable={false}
                resource="notifications"
              >
                <Box display="flex" justifyContent="space-between" width="100%">
                  <span>
                    <SaveButton
                      saving={formProps.saving}
                      disabled={checkDisableForm}
                      handleSubmitWithRedirect={() => {
                        setIscheck(true);
                        setListNoti(
                          listNoti.map((item) => {
                            if (item.content === "" && item.title === "") {
                              return {
                                ...item,
                                errorContent: true,
                                errorTitle: true,
                              };
                            } else if (item.title === "") {
                              return { ...item, errorTitle: true };
                            } else if (item.content === "") {
                              return { ...item, errorContent: true };
                            } else return { ...item };
                          })
                        );
                        if (emptyObj) {
                          if (
                            formProps.form.getState().values.send_type ===
                              "send_now" &&
                            checkEmpty
                          ) {
                            handleOpen();
                          } else if (checkEmpty) {
                            handleOnsubmitNoti(
                              formProps.form.getState().values
                            );
                          }
                        } else {
                          formProps.handleSubmitWithRedirect();
                        }
                      }}
                    />
                  </span>

                  <DeleteButton record={formProps.record} />
                </Box>
              </Toolbar>
            </form>
          </Card>
        );
      }}
    />
  );
};
const Create = (props: any) => {
  return (
    <CreateAdmin undoable={false} {...props} actions={<ListActions />}>
      <Form />
    </CreateAdmin>
  );
};

export default Create;
