import { CircularProgress } from "@material-ui/core";
import { PlusOneOutlined } from "@material-ui/icons";
import _ from "lodash";
import React, { FC, useCallback, useState } from "react";
import { useNotify } from "react-admin";
import { useInput } from "react-admin";
import "./upload.css";

interface IProps {}

const UploadVideo: FC<any> = (props) => {
  const inputProps = useInput(props);
  const {
    input,
    meta: { touched, error },
  } = inputProps;

  const [loading, setLoading] = useState<boolean>();
  const noti = useNotify();
  const uploadButton = (
    <div className="block-upload-video">
      {loading ? <CircularProgress /> : <PlusOneOutlined />}
      <div style={{ marginTop: 8 }}>Tải video</div>
    </div>
  );
  const hostname = `${
    process.env.REACT_APP_API_BASE_URL || "http://localhost:4004"
  }`;

  const uploadVideo = useCallback((file: string | Blob) => {
    setLoading(true);
    let formdata = new FormData();
    formdata.append("file", file);

    const token = localStorage.getItem("user_token");
    const requestOptions = {
      method: "POST",
      body: formdata,
      headers: { Authorization: `Bearer ${token}` },
    };

    fetch(`${hostname}/api/upload/video`, {
      ...requestOptions,
    })
      .then((response) => {
        setLoading(false);
        return response.json();
      })
      .then((success) => {
        const video = _.get(success, "data.url");
        input.onChange(video);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, []);

  return (
    <div className="block-upload-video-wrapper">
      {input.value && (
        <label className="block-upload-video">
          <video controls>
            <source
              src={
                input.value.includes("http")
                  ? `${input.value}`
                  : `${hostname}${input.value}`
              }
              type="video/mp4"
            />
            Your browser does not support the video element.
          </video>
        </label>
      )}

      <label className="block-upload-video">
        <input
          hidden
          onChange={(e) => {
            if (e.target.files) {
              const file = e.target.files[0];
              if (file.size >= 200 * 1024 * 1024) {
                noti("Ảnh vượt quá 200Mb", "warning");
                return;
              }
              uploadVideo(file);
            }
          }}
          type="file"
          accept=".mp4"
        />
        {uploadButton}
      </label>
    </div>
  );
};

export default UploadVideo;
