import { Button } from "@material-ui/core";
import { stringify } from "query-string";
import React, { FC } from "react";
import {
  Datagrid,
  EditButton,
  List,
  NumberField,
  ReferenceField,
  TextField,
  useTranslate,
} from "react-admin";
import { Link } from "react-router-dom";
import ChipField from "../components/ChipField";
import DateTimeField from "../components/DateTimeField";
import ListActions from "./ListActions";
import ListFilter from "./ListFilters";
import AvatarField from "../components/AvatarField";

const ShowCourseButton = (props: any) => {
  const { record } = props;
  const translate = useTranslate();
  return (
    <Button
      className="MuiButton-textPrimary MuiButton-textSizeSmall"
      component={Link}
      to={{
        pathname: "/courses",
        search: stringify({
          page: 1,
          perPage: 25,
          sort: "id",
          order: "ASC",
          filter: JSON.stringify({ categories: { id: record.id } }),
        }),
      }}>
      {translate("common.fields.showCourse")}
    </Button>
  );
};

const MyList = (props: any) => {
  return (
    <Datagrid optimized>
      <AvatarField source="imageUrl" />
      <TextField source="name" />
      <NumberField source="courseCount" />
      <NumberField source="sequenceNo" />
      <ReferenceField source="parentId" reference="categories">
        <ChipField source="name" />
      </ReferenceField>
      <DateTimeField source="createdAt" />
      <ShowCourseButton />
      <EditButton />
    </Datagrid>
  );
};
const MajorList: FC<any> = (props) => {
  const translate = useTranslate();

  return (
    <List
      {...props}
      title={translate("resources.categories.page.list")}
      actions={<ListActions />}
      filters={<ListFilter />}>
      <MyList {...props} />
    </List>
  );
};

export default MajorList;
