import { Box, Button } from "@material-ui/core";
import React from "react";
import {
  Datagrid,
  Edit as EditAdmin,
  EditButton,
  ReferenceManyField,
  Show,
  TextField,
  TopToolbar,
} from "react-admin";
import { Link } from "react-router-dom";
import { Form } from "./Create";
import ListActions from "./ListActions";

const UnitShowActions = ({ id }: any) => (
  <TopToolbar>
    <Button
      className="MuiButton-textPrimary MuiButton-textSizeSmall"
      component={Link}
      to={{
        pathname: `/units/create`,
        state: { record: { sectionId: parseInt(id) } },
      }}
    >
      + Create Lesson
    </Button>
  </TopToolbar>
);

const UnitShow = (props: any) => {
  return (
    <Show {...props} actions={<UnitShowActions {...props} />} title={" "}>
      <ReferenceManyField
        label="units"
        reference="units"
        target="sectionId"
        perPage={1000}
        filter={{
          "parentId||$isnull": true,
        }}
        sort={{ field: "sequenceNo", order: "ASC" }}
      >
        <Datagrid>
          <TextField source="name" />
          <TextField source="sequenceNo" />
          <EditButton />
        </Datagrid>
      </ReferenceManyField>
    </Show>
  );
};

const Edit = (props: any) => {
  return (
    <div style={{ flex: 1, display: "flex" }}>
      <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
        <EditAdmin
          undoable={false}
          component="div"
          {...props}
          actions={<ListActions />}
        >
          <Form isEdit />
        </EditAdmin>
      </Box>
      <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
        <UnitShow {...props} />
      </Box>
    </div>
  );
};

export default Edit;
