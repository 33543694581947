import { Filter, NumberInput, TextInput } from "react-admin";
import React, { FC } from "react";
import { SelectInput } from "react-admin";
import { QuestionReportStatus } from "./List";

const ListFilter: FC<any> = (props) => (
  <Filter {...props}>
    <NumberInput source="id" />
    <SelectInput
      source="status"
      alwaysOn
      choices={[
        { id: QuestionReportStatus.Open, name: QuestionReportStatus.Open },
        { id: QuestionReportStatus.Done, name: QuestionReportStatus.Done },
      ]}
    />
  </Filter>
);

export default ListFilter;
