import { Button, Icon } from "@material-ui/core";
import React, { cloneElement, FC } from "react";
import { BarChartOutlined } from "@material-ui/icons";
import {
  CreateButton,
  ListButton,
  sanitizeListRestProps,
  TopToolbar,
} from "react-admin";
import { useRedirect } from "react-admin";

const ListActions: FC<any> = ({
  currentSort,
  className,
  resource,
  filters,
  displayedFilters,
  exporter, // you can hide ExportButton if exporter = (null || false)
  filterValues,
  permanentFilter,
  hasCreate, // you can hide CreateButton if hasCreate = false
  basePath,
  selectedIds,
  onUnselectItems,
  showFilter,
  maxResults,
  total,
  hasShow,
  hasList,
  ...rest
}) => {
  const redirect = useRedirect();
  const handleClickViewStatistical = () => {
    redirect("/statisticalslider");
  };
  return (
    <TopToolbar
      className={className}
      {...sanitizeListRestProps(rest)}
      hasshow={`${hasShow}`}
      haslist={`${hasList}`}>
      {!filters && <ListButton basePath={basePath} />}
      {filters &&
        cloneElement(filters, {
          undoable: "false",
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button",
        })}
      <CreateButton basePath={basePath} />
      <Button
        variant="text"
        color="primary"
        startIcon={<BarChartOutlined />}
        onClick={handleClickViewStatistical}>
        Xem thống kê
      </Button>
    </TopToolbar>
  );
};
export default ListActions;
