import ChipField from '../components/ChipField';
import DateTimeField from '../components/DateTimeField';
import ListActions from './ListActions';
import ListFilter from './ListFilters';
import React, { FC } from 'react';
import {
  Datagrid,
  List,
  ReferenceField,
  TextField,
  useTranslate,
} from "react-admin";

const MajorList: FC<any> = (props) => {
  const translate = useTranslate();
  return (
    <List
      {...props}
      title={translate("resources.sections.page.list")}
      actions={<ListActions />}
      filters={<ListFilter />}>
      <Datagrid optimized rowClick="edit">
        <TextField source="id" />
        <TextField source="name" />
        <TextField source="sequenceNo" />
        <ReferenceField
          source="courseId"
          reference="courses"
          resource="sections">
          <ChipField source="name" />
        </ReferenceField>

        <DateTimeField source="createdAt" />
      </Datagrid>
    </List>
  );
};

export default MajorList;
