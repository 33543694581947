import { Box, Card, CardContent } from "@material-ui/core";
import * as _ from "lodash";
import React, { useState } from "react";
import { FC } from "react";
import {
  ArrayInput,
  AutocompleteArrayInput,
  AutocompleteInput,
  BooleanInput,
  Create as CreateAdmin,
  DeleteButton,
  FileInput,
  FormWithRedirect,
  ImageInput,
  NumberInput,
  ReferenceArrayInput,
  ReferenceInput,
  required,
  SaveButton,
  SelectInput,
  SimpleFormIterator,
  TextInput,
  Toolbar,
} from "react-admin";
import { useHistory } from "react-router";
import PreviewInputAudio from "../components/PreviewInputAudio";
import PreviewInputImage from "../components/PreviewInputImage";
import { RichTextInput } from "../components/RichTextInput";
import ListActions from "./ListActions";
import { QuestionMetaKey } from "../questions/Create";
import { useTranslate } from "react-admin";
import { useNotify } from "react-admin";
import { useUpdate } from "react-admin";

enum QuizType {
  Default = "default",
  Reading = "reading",
  Listening = "listening",
}

export const optionRenderer = (choice: any) => {
  if (!choice || !choice.name) return "";
  return `Chương: ${choice.name} -- Khóa: ${_.get(
    choice,
    "course.name",
    "Không rõ"
  )}`;
};

enum UnitType {
  Learning = "learning",
  Theory = "theory",
  Video = "video",
  Vocab = "vocab",
  Speaking = "speaking",
  Quiz = "quiz",
  Listening = "listening",
  Game = "game",
}

export const Form = (props: any) => {
  const { record } = props;

  const translate = useTranslate();
  const [valueSequen, setValueSequen] = useState<number>(record.sequenceNo);
  const notify = useNotify();

  return (
    <FormWithRedirect
      {...props}
      defaultValue={{
        canComment: true,
        isPrivate: true,
        duration: 0,
        quizType: QuizType.Default,
        type: UnitType.Learning,
      }}
      render={(formProps: any) => {
        const submitForm = () => {
          notify(`Thứ tự nhập vào phải lớn hơn hoặc bằng 0`, {
            type: "warning",
          });
        };

        return (
          <Card>
            <form>
              <CardContent>
                <Box
                  display={{ md: "block", lg: "flex" }}
                  style={{ alignItems: "center" }}>
                  <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                    <TextInput
                      type="text"
                      source="name"
                      validate={requiredValidate}
                      resource="units"
                      fullWidth
                    />
                  </Box>
                  <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                    <NumberInput
                      fullWidth
                      source="duration"
                      validate={requiredValidate}
                      resource="units"
                    />
                  </Box>

                  <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                    <NumberInput
                      fullWidth
                      source="sequenceNo"
                      validate={requiredValidate}
                      resource="units"
                      min={0}
                      onChange={(e: any) => {
                        setValueSequen(e.target.value);
                      }}
                    />
                  </Box>

                  <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                    <SelectInput
                      fullWidth
                      source="status"
                      validate={requiredValidate}
                      resource="units"
                      choices={[
                        { id: "publish", name: "publish" },
                        { id: "private", name: "draft" },
                      ]}
                    />
                  </Box>
                </Box>
                <Box
                  display={{ md: "block", lg: "flex" }}
                  style={{ alignItems: "center" }}>
                  <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                    <BooleanInput fullWidth source="isPrivate" />
                  </Box>
                  <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                    <BooleanInput
                      fullWidth
                      source="canComment"
                      resource="units"
                    />
                  </Box>
                  <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                    <BooleanInput fullWidth source="isQuiz" resource="units" />
                  </Box>
                  <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                    <SelectInput
                      source="type"
                      validate={[required()]}
                      fullWidth
                      choices={Object.values(UnitType).map((name, index) => ({
                        id: name,
                        name: `${index + 1}. ${translate(
                          `resources.units.fields.${name}`
                        )}`,
                      }))}
                    />
                  </Box>
                </Box>
                <Box
                  display={{ md: "block", lg: "flex" }}
                  style={{ alignItems: "center" }}>
                  <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                    <ReferenceInput
                      source="sectionId"
                      disabled
                      reference="sections"
                      resource="sections"
                      filterToQuery={(searchText: any) => {
                        return _.isEmpty(_.trim(searchText))
                          ? null
                          : { name: searchText };
                      }}>
                      <AutocompleteInput
                        fullWidth
                        disabled
                        validate={requiredValidate}
                        optionText={optionRenderer}
                      />
                    </ReferenceInput>
                  </Box>
                </Box>

                {!_.isNumber(record.parentId) && (
                  <Box display={{ md: "block", lg: "flex" }}>
                    <Box flex={2} ml={{ xs: 0, sm: "0.5em" }}>
                      <ReferenceArrayInput
                        source="childIds"
                        reference="units"
                        resource="units"
                        fullWidth
                        filter={{
                          sectionId: _.get(record, "sectionId", undefined),
                          "parentId||$isnull": true,
                          ...(_.get(record, "id", undefined)
                            ? { "id||$ne": _.get(record, "id", undefined) }
                            : {}),
                        }}
                        perPage={100}
                        filterToQuery={(searchText: any) => {
                          return _.isEmpty(_.trim(searchText))
                            ? null
                            : { name: searchText };
                        }}>
                        <AutocompleteArrayInput source="name" fullWidth />
                      </ReferenceArrayInput>
                    </Box>
                  </Box>
                )}
                <Box>
                  <RichTextInput source="content" />
                </Box>
                <Box
                  display={{ md: "block", lg: "flex" }}
                  style={{ alignItems: "center" }}>
                  <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                    <ArrayInput source="videos" resource="courses">
                      <SimpleFormIterator>
                        <TextInput source="value" />
                      </SimpleFormIterator>
                    </ArrayInput>
                  </Box>
                  <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                    <FileInput
                      className="audio-preview"
                      source="audios"
                      resource="courses"
                      multiple
                      accept="audio/*"
                      maxSize={20 * 1024 * 1024}>
                      <PreviewInputAudio source="value" />
                    </FileInput>
                  </Box>
                  <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                    <ImageInput
                      source="images"
                      resource="courses"
                      multiple
                      maxSize={2 * 1024 * 1024}
                      accept="image/*"
                      placeholder={<p>Kéo thả ảnh vào đây</p>}>
                      <PreviewInputImage source="value" title="title" />
                    </ImageInput>
                  </Box>
                </Box>
              </CardContent>
              <Toolbar
                record={formProps.record}
                basePath={formProps.basePath}
                invalid={formProps.invalid}
                handleSubmit={formProps.handleSubmit}
                saving={formProps.saving}
                undoable={false}
                resource="units">
                <SaveButton
                  saving={formProps.saving}
                  redirect={`/sections/${formProps.record.sectionId}`}
                  // handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                  handleSubmitWithRedirect={
                    valueSequen >= 0
                      ? formProps.handleSubmitWithRedirect
                      : submitForm
                  }
                />
                {formProps.isEdit && (
                  <DeleteButton
                    redirect={`/sections/${formProps.record.sectionId}`}
                    undoable={false}
                  />
                )}
              </Toolbar>
            </form>
          </Card>
        );
      }}
    />
  );
};
const Create: FC = (props: any) => {
  const history = useHistory();
  if (!_.get(props, "location.state.record.sectionId", undefined)) {
    history.goBack();
    return <></>;
  }
  return (
    <CreateAdmin {...props} undoable={false} actions={<ListActions />}>
      <Form />
    </CreateAdmin>
  );
};

const requiredValidate = [required()];

export default Create;
