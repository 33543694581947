import * as FileSaver from "file-saver";
import * as _ from "lodash";
import CloudDownloadIcon from "@material-ui/icons/Save";
import ListActions from "./ListActions";
import React from "react";
import { Box, Card, CardContent } from "@material-ui/core";
import {
  AutocompleteInput,
  Create as CreateAdmin,
  FormWithRedirect,
  NumberInput,
  ReferenceInput,
  required,
  Toolbar,
  Button,
  TextInput,
  useNotify,
} from "react-admin";
import { customHttpClient } from "../dataProvider";

const Form = (props: any) => {
  const notify = useNotify();
  return (
    <FormWithRedirect
      {...props}
      render={(formProps: any) => (
        <Card>
          <form>
            <CardContent>
              <Box display={{ md: "block", lg: "flex" }}>
                <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                  <NumberInput
                    source="value"
                    fullWidth={true}
                    validate={requiredValidate}
                    resource="cardcodes"
                  />
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                  <TextInput
                    source="regionCode"
                    fullWidth={true}
                    validate={requiredValidate}
                    resource="cardcodes"
                  />
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                  <ReferenceInput
                    source="cardId"
                    reference="cards"
                    resource="cards"
                    validate={requiredValidate}
                    filter={{ onSale: 1, }}
                    perPage={1000}
                    filterToQuery={(searchText: any) => {
                      return _.isEmpty(_.trim(searchText))
                        ? null
                        : {
                            name: searchText,
                          };
                    }}
                  >
                    
                    <AutocompleteInput
                      optionText="displayName"
                      validate={requiredValidate}
                      fullWidth
                    />
                  </ReferenceInput>
                </Box>
              </Box>
            </CardContent>
            <Toolbar
              record={formProps.record}
              basePath={formProps.basePath}
              invalid={formProps.invalid}
              handleSubmit={formProps.handleSubmit}
              saving={formProps.saving}
              undoable={false}
              resource="cardcodes"
              redirect={false}
            >
              <Button
                color="primary"
                component="button"
                variant="text"
                label="pos.action.save"
                onClick={() => {
                  console.log(formProps.form.getState().values);
                  customHttpClient(`/crud/cardcodes`, {
                    method: "POST",
                    body: JSON.stringify(formProps.form.getState().values),
                  })
                    .then((res: any) => {
                      FileSaver.saveAs(
                        `${process.env.REACT_APP_API_BASE_URL}/public/gens/card.xlsx`,
                        "phiếu đề xuất.docx"
                      );
                    })
                    .catch((error: any) => {
                      notify(
                        "Error: Tạo không thành công: " + error.message,
                        "warning"
                      );
                    });
                }}
              >
                <CloudDownloadIcon style={{ fontSize: "20" }} />
              </Button>
            </Toolbar>
          </form>
        </Card>
      )}
    />
  );
};

const Create = (props: any) => {
  return (
    <CreateAdmin {...props} undoable={false} actions={<ListActions />}>
      <Form />
    </CreateAdmin>
  );
};

const requiredValidate = [required()];

export default Create;
