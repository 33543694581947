import _ from "lodash";
import React, { FC } from "react";
import {
  AutocompleteInput,
  Filter,
  ReferenceInput,
  TextInput,
  useTranslate,
  SelectInput,
} from "react-admin";

const ListFilter: FC<any> = (props) => {
  const translate = useTranslate();
  return (
    <Filter {...props}>
      <TextInput alwaysOn source="cardCode.code" />
      <ReferenceInput
        alwaysOn
        label="Thẻ"
        source="cardCode.cardId"
        reference="cards"
        perPage={1000}
        translate={false}
      >
        <SelectInput optionText="displayName" />
      </ReferenceInput>
      <TextInput alwaysOn source="student.email" />
      <TextInput alwaysOn source="student.telephone" />
      <TextInput source="expiredAt" />
      <TextInput source="createdAt" />
    </Filter>
  );
};

export default ListFilter;
