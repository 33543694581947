import React from "react";
import { Box } from "@material-ui/core";
import {
  ArrayInput,
  required,
  SimpleFormIterator,
  TextInput,
  useTranslate,
} from "react-admin";
import UploadImage from "../components/UploadImage";

export const PairSentence = (props: any) => {
  const translate = useTranslate();
  return (
    <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
      <ArrayInput source="answerMetas" resource="questions">
        <SimpleFormIterator>
          <TextInput
            source="key"
            resource="questions"
            required={requiredValidate}
          />
          <TextInput
            source="value"
            label={translate("resources.questions.fields.keyValue")}
            resource="questions"
            required={requiredValidate}
          />
          <UploadImage
            source="imageUrl"
            label={"ảnh minh họa"}
            resource="questions"
          />
        </SimpleFormIterator>
      </ArrayInput>
    </Box>
  );
};

const requiredValidate = [required()];

export default PairSentence;
