import * as React from "react";
import { Theme, useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import { ElementAddress } from "../Create";
import "./styles.css";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name: string, personName: string[], theme: Theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

interface Iprops {
  data: any;
  setDataAddress: any;
  title: string;
  elementList?: any;
  indexItem?: number;
  defaultValueDist?: string[] | any;
  isEdit?: boolean;
}

type ItemDist = {
  id: string;
  name: string;
  province_id: number;
};

export const PopupReducer = (state: any, action: any) => {
  switch (action.type) {
    case "ADD_DISTRIST":
      const array = [...state, ...action.payload];
      const uniqueData = array.filter((item, index, self) => {
        // console.log(self, "self");
        return index === self.findIndex((t) => t.id === item.id);
      });

      const updatedData = uniqueData.filter(
        (item) => item.id != action.payload?.map((i: any) => i.id)
      );

      return updatedData;
    default:
      return state;
  }
};

const MultipleSelect = ({
  data,
  setDataAddress,
  title,
  elementList,
  indexItem,
  defaultValueDist,
  isEdit,
}: Iprops) => {
  const theme = useTheme();
  const [personName, setPersonName] = React.useState<string[]>([]);
  const [distEdit, setDistEdit] = React.useState<string[]>([]);
  const [render, setRender] = React.useState<number>(0);
  let arrayDistrist: any = defaultValueDist;

  const [distristArrayReducer, dispatch] = React.useReducer(
    PopupReducer,
    defaultValueDist
  );

  React.useEffect(() => {
    if (isEdit) {
      dispatch({ type: "ADD_DISTRIST", payload: defaultValueDist });
    }
  }, [defaultValueDist]);

  React.useEffect(() => {
    if (isEdit) {
      setDataAddress(
        elementList?.map((item: ElementAddress, i: number) => {
          if (i === indexItem) {
            return {
              ...item,
              distrist: distristArrayReducer?.map((i: any) => i?.id),
            };
          } else return item;
        })
      );
    } else {
      setDataAddress(
        elementList?.map((item: ElementAddress, i: number) => {
          if (i === indexItem) {
            return {
              ...item,
              distrist: personName.map((i: any) => i?.id),
            };
          } else return item;
        })
      );
    }
  }, [personName, render]);

  React.useEffect(() => {
    setPersonName([]);
  }, [data]);

  const handleChangeCreate = (event: SelectChangeEvent<typeof personName>) => {
    const {
      target: { value },
    } = event;

    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(" ") : value
    );
  };

  const handleChangeEdit = (event: SelectChangeEvent<typeof distEdit>) => {
    const {
      target: { value },
    } = event;
    // TODO: Xử lý lại
    const newValues = typeof value === "string" ? value.split(" ") : value;
    dispatch({ type: "ADD_DISTRIST", payload: value });
    setRender(render + 1);
    console.log(value, "value===>>>>");

    // // Lọc ra các giá trị không trùng lặp và chưa tồn tại trong mảng
    // const uniqueValues = newValues.filter(
    //   (newValue) => !arrayDistrist.includes(newValue)
    // );

    // // Thêm các giá trị mới vào mảng
    // arrayDistrist = [...arrayDistrist, ...uniqueValues];
    // // Loại bỏ các phần tử trùng lặp dựa trên id
    // arrayDistrist = arrayDistrist.reduce((acc: any[], current: any) => {
    //   const found = acc.find((item) => item.id === current.id);
    //   if (!found) {
    //     acc.push(current);
    //   }
    //   return acc;
    // }, []);
  };

  if (isEdit) {
    return (
      <div>
        <FormControl
          sx={{
            m: 1,
            width: 500,
            border: 0,
            borderWidth: 0,
            marginTop: -1.2,
            backgroundColor: "#e5e5e5",
          }}
          variant="standard">
          <InputLabel
            id="demo-simple-select-standard-label"
            style={{ paddingLeft: 5 }}>
            {title}
          </InputLabel>
          <Select
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard-provices"
            multiple
            MenuProps={MenuProps}
            sx={{
              ".MuiSelect-selectMenu": {
                overflow: "auto",
                wordWrap: "break-word",
              },
            }}
            style={{
              paddingLeft: 10,
            }}
            value={distristArrayReducer}
            onChange={(e) => handleChangeEdit(e)}
            renderValue={(selected: string[]) =>
              distristArrayReducer?.map((i: any, idx: number) => (
                <span
                  key={idx}
                  style={{
                    padding: 7,
                    marginRight: 10,
                    width: "100%",
                    borderRadius: 5,
                    backgroundColor: "#fff",
                    fontSize: 12,
                    fontWeight: "bold",
                    marginBottom: 5,
                  }}>
                  {i.name}
                </span>
              ))
            }
            // input={<OutlinedInput label="Name" />}
            // MenuProps={MenuProps}
          >
            {data?.map((item: any, index: number) => (
              <MenuItem
                key={item.id}
                value={item}
                style={getStyles(item.name, defaultValueDist, theme)}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    );
  }
  return (
    <div>
      <FormControl
        sx={{
          m: 1,
          minWidth: elementList.length > 1 ? 550 : 665,
          border: 0,
          borderWidth: 0,
          marginTop: -1.2,
          backgroundColor: "#e5e5e5",
        }}
        variant="standard">
        <InputLabel
          id="demo-simple-select-standard-label"
          style={{ paddingLeft: 5 }}>
          {title}
        </InputLabel>
        <Select
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard-provices"
          multiple
          MenuProps={MenuProps}
          sx={{
            ".MuiSelect-selectMenu": {
              overflow: "auto",
              wordWrap: "break-word",
            },
          }}
          style={{
            paddingLeft: 10,
          }}
          value={personName}
          onChange={(e) => handleChangeCreate(e)}
          renderValue={(selected: string[]) =>
            personName?.map((i: any, idx: number) => (
              <span
                key={idx}
                style={{
                  padding: 7,
                  marginRight: 10,
                  width: "100%",
                  borderRadius: 5,
                  backgroundColor: "#fff",
                  fontSize: 12,
                  fontWeight: "bold",
                  marginBottom: 5,
                }}>
                {i.name}
              </span>
            ))
          }
          // input={<OutlinedInput label="Name" />}
          // MenuProps={MenuProps}
        >
          {data?.map((item: any, index: number) => (
            <MenuItem
              key={item.id}
              value={item}
              style={getStyles(item.name, personName, theme)}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default React.memo(MultipleSelect);
