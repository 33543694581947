import * as currencyFormatter from 'currency-formatter';
import React, { FC } from 'react';
import { Chip } from '@material-ui/core';
import { FieldProps } from '../types';

interface Props extends FieldProps<any> {
  className?: string;
}

const getMoneyText = (count: number) => {
  return currencyFormatter.format(count, { code: "VND" });
};

const MoneyField: FC<Props> = ({ record, className, source }) => {
  return record ? (
    <Chip
      className={className}
      label={source && record[source] && getMoneyText(record[source])}
    />
  ) : null;
};

export default MoneyField;
