import { CircularProgress } from "@material-ui/core";
import React, { useMemo } from "react";
import { FileField } from "react-admin";

const LocalUploadAudio = (props: any) => {
  console.log("PreviewInputAudio", props.loading);
  const fileURL = useMemo(
    () => window.URL.createObjectURL(props.record.rawFile),
    [props.record.rawFile]
  );

  return (
    <>
      <FileField {...props} />
      <audio src={fileURL} controls></audio>
      {props?.loading && (
        <div
          style={{
            display: "flex",
            width: 50,
            height: 50,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress size={30} color="primary" />
        </div>
      )}
    </>
  );
};

const InternalUploadAudio = (props: any) => {
  const url = props.record[props.source].includes("http")
    ? props.record[props.source]
    : process.env.REACT_APP_API_BASE_URL + props.record[props.source];
  return (
    <>
      <FileField
        {...props}
        record={{
          ...props.record,
          [props.source]: props.record[props.source].includes("http")
            ? props.record[props.source]
            : process.env.REACT_APP_API_BASE_URL + props.record[props.source],
        }}
      />
      <audio src={url} controls></audio>
    </>
  );
};

const PreviewInputAudio = (props: any) => {
  return !!props.record.rawFile ? (
    <LocalUploadAudio {...props} />
  ) : (
    <InternalUploadAudio {...props} />
  );
};
export default PreviewInputAudio;
