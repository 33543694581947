import { Box } from "@material-ui/core";
import React from "react";
import {
  ArrayInput,
  required,
  SimpleFormIterator,
  TextInput,
  useTranslate,
} from "react-admin";
import UploadAudio from "../components/UploadAudio";
import UploadImage from "../components/UploadImage";
import UploadVideo from "../components/UploadVideo";

export const BacktalkVideo = (props: any) => {
  const translate = useTranslate();

  return (
    <>
      <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
        <ArrayInput
          source="answerMetas"
          resource="questions"
          label="Câu hỏi theo video"
        >
          <SimpleFormIterator>
            <UploadVideo
              source="videoUrl"
              label={"Video"}
              resource="questions"
            />
            <TextInput
              source="question"
              label={"Câu nói chính"}
              resource="questions"
              required={requiredValidate}
            />

            <UploadAudio
              source="audioUrl"
              label={"Âm thanh"}
              resource="questions"
            />
            {/* <UploadImage source="imageUrl" label={"Ảnh"} resource="questions" /> */}
            <TextInput
              source="answer"
              label={"Đáp án"}
              resource="questions"
              required={requiredValidate}
            />

            <TextInput
              source="suggestion"
              label={"Gợi ý"}
              resource="questions"
            />
          </SimpleFormIterator>
        </ArrayInput>
      </Box>
    </>
  );
};

const requiredValidate = [required()];

export default BacktalkVideo;
