import ListActions from "./ListActions";
import React from "react";
import { Edit } from "react-admin";
import { Form } from "./Create";
import * as _ from "lodash";

const MajorEdit = (props: any) => {
  return (
    <Edit component="div" undoable={false} {...props} actions={<ListActions />}>
      <Form />
    </Edit>
  );
};

export default MajorEdit;
