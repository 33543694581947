import React, { cloneElement, FC } from "react";
import {
  CreateButton,
  ListButton,
  sanitizeListRestProps,
  TopToolbar,
  Button,
} from "react-admin";
import ListIcon from "@material-ui/icons/List";
import { useHistory } from "react-router";
import * as _ from "lodash";

const ListActions: FC<any> = ({
  currentSort,
  className,
  resource,
  filters,
  displayedFilters,
  exporter, // you can hide ExportButton if exporter = (null || false)
  filterValues,
  permanentFilter,
  hasCreate, // you can hide CreateButton if hasCreate = false
  basePath,
  selectedIds,
  onUnselectItems,
  showFilter,
  maxResults,
  total,
  hasShow,
  hasList,
  ...rest
}) => {
  const history = useHistory();
  const gradeId = _.get(rest, "data.gradeId", undefined);
  return (
    <TopToolbar
      className={className}
      {...sanitizeListRestProps(rest)}
      hasshow={`${hasShow}`}
      haslist={`${hasList}`}
    >
      {!filters && (
        <Button
          component="button"
          variant="text"
          label="pos.action.listing"
          onClick={() => {
            if (gradeId) {
              history.push(`/grades/${gradeId}`);
            } else {
              history.goBack();
            }
          }}
        >
          <ListIcon style={{ fontSize: "20" }} />
        </Button>
      )}
      {filters &&
        cloneElement(filters, {
          undoable: "false",
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button",
        })}

      {/* <CreateButton basePath={basePath} /> */}
    </TopToolbar>
  );
};

export default ListActions;
