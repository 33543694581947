import englishMessages from "ra-language-english";

const customEnglishMessages: any = {
  ...englishMessages,
  pos: {
    search: "Search",
    configuration: "Configuration",
    language: "Language",
    theme: {
      name: "Theme",
      light: "Light",
      dark: "Dark",
    },
    action: {
      importQuestions: "Nhập câu hỏi",
      importVocabulary: "Nhập từ vựng",
      downloadTemplateVocab: "Tải mẫu nhập từ vựng",
      downloadImportQuestions: "Tải mẫu nhập câu hỏi",
      listing: "List",
      save: "Save",
      reset: "Reset",
      grant: "Phân quyền",
    },
    dashboard: {
      student_total: "Học viên",
      course_total: "Khóa học",
      unit_total: "Bài học",
    },
    menu: {
      param: "Đối số",
      pagebuilder: "Thiết lập trang",
      examConfig: "Thiết lâp lớp và kỳ thi",
      allcontest: "Quản lý bài thi",
    },
  },
  common: {
    fields: {
      f: "Nữ",
      m: "Nam",
      fm: "Không yêu cầu",
      createdAt: "Ngày tạo",
      showCourse: "Xem các khóa học",
      draft: "Nháp",
      active: "Hoạt động",
      send_now: "Gửi ngay",
      time: "Hẹn giờ",
      birthday: "Sinh nhật",
    },
  },
  resources: {
    permissions: {
      page: {
        list: "Thêm quyền hạn",
      },
      zones: {
        admin: "Quản lý admin",
        student: "Quản lý học viên",
        studentcard: "Quản lý gia hạn thẻ",
        cardcode: "Quản lý mã thẻ",
        card: "Quản lý thẻ học viên",
        course: "Quản lý khóa học",
        category: "Quản lý phân loại khóa",
        post: "Quản lý bài đăng",
        version: "Quản lý phiên bản",
        noti: "Quản lý thông báo",
        pagebuilder: "Quản lý thiết lập trang",
        report: "Quản lý report",
        contest: "Quản lý đề thi",
      },
      fields: {
        all: "Tất cả",
        "read-admin": "",
        "read-student": "",
        "read-studentcardcode": "",
        "read-cardcode": "",
        "read-card": "",
        "read-course": "",
        "read-category": "",
        "read-post": "",
      },
    },
    studentcardcodes: {
      name: "Học viên gia hạn thẻ",
      actions: {
        btnActive: "Gia hạn thẻ",
      },
      fields: {
        expiredAt: "Ngày hết hạn",
        createdAt: "Ngày kích hoạt",
        studentId: "Học viên",
        cardCodeId: "Mã thẻ",
        "cardCode.code": "Mã thẻ",
        "student.telephone": "Số điện thoại",
        "student.email": "Email",
      },
      page: {
        list: "Danh sách học viên gia hạn",
      },
    },
    notifications: {
      name: "Thông báo",
      fields: {
        expiredAt: "Ngày hết hạn",
        createdAt: "Ngày kích hoạt",
        studentId: "Học viên",
        cardCodeId: "Mã thẻ",
        "cardCode.code": "Mã thẻ",
        "student.telephone": "Số điện thoại",
        "student.email": "Email",
      },
      page: {
        list: "Danh sách thông báo",
      },
    },
    students: {
      name: "Học viên",
      fields: {
        avatar: "Ảnh",
        email: "Email",
        password: "Mật khẩu",
        fullname: "Tên đầy đủ",
        dob: "Ngày sinh",
        sex: "Giới tính",
        telephone: "Số điện thoại",
        address: "Địa chỉ",
        createdAt: "Ngày tạo",
      },
      fieldGroups: {},
      page: {
        list: "Danh sách học viên",
      },
    },

    cards: {
      name: "Thẻ học viên",
      fields: {
        value: "Trị giá",
        expiredDate: "Số ngày hết hạn",
        displayName: "Tên hiển thị",
        createdAt: "Ngày tạo",
        courses: "Khóa học",
      },
      page: {
        list: "Danh sách",
      },
    },

    versions: {
      name: "Quản lý phiên bản",
      fields: {
        android: "Android",
        iOS: "iOS",

        createdAt: "Ngày tạo",
        updatedAt: "Ngày chỉnh sửa",
      },
      page: {
        list: "Danh sách",
      },
    },

    courses: {
      name: "Khoá học",
      fields: {
        sequenceNo: "Thứ tự",
        courseIds: "Chọn khóa học",
        name: "Tên khóa học",
        commentCount: "Số bình luận",
        unitCount: "Số bài học",
        duration: "Thời lượng (s)",
        studentCount: "Số học viên tham gia",
        canComment: "Có thể bình luận",
        categories: "Phân loại khóa học",
        createdAt: "Ngày tạo",
        description: "Mô tả khóa học",
        goalDescriptions: "Mục tiêu",
        benefitDescriptions: "Lợi ích",
        requirementDescriptions: "Yêu cầu",
        categoryIds: "Phân loại",
        imageUrl: "Ảnh thumb",
        type: "Loại thẻ học",
      },
      page: {
        list: "Danh sách",
      },
    },
    sections: {
      name: "Chương trong khóa học",
      fields: {
        name: "Tên chương",
        commentCount: "Số bình luận",
        duration: "Thời lượng (s)",
        sequenceNo: "Thứ tự",
        courseId: "Khóa học",
        createdAt: "Ngày tạo",
      },
      page: {
        list: "Danh sách",
      },
    },
    users: {
      name: "Tài khoản",
      fields: {
        email: "Email",
        createdBy: "Người tạo",
        createdAt: "Ngày tạo",
      },
      page: {
        list: "Danh sách",
      },
    },
    admins: {
      name: "Quản lý tài khoản admin",
      fields: {
        email: "Email",
        createdBy: "Người tạo",
        createdAt: "Ngày tạo",
      },
      page: {
        list: "Danh sách",
      },
    },
    quizzes: {
      name: "Quiz",
      fields: {
        name: "Tên",
        sequenceNo: "Thứ tự",
        unitId: "Bài học",
        sectionId: "Chương",
        createdAt: "Ngày tạo",
      },
      page: {
        list: "Danh sách",
      },
      fieldGroups: {
        quiz: "Quiz",
      },
    },
    cardcodes: {
      name: "Mã thẻ",
      fields: {
        createdAt: "Ngày tạo",
        code: "Mã",
        regionCode: "Mã vùng (4 số)",
        value: "Số lượng mã",
        cardId: "Tên thẻ",
        used: "Thẻ đã sử dụng",
      },
      page: {
        list: "Danh sách",
      },
    },
    questions: {
      name: "Câu hỏi và ngữ pháp",
      fields: {
        createdAt: "Ngày tạo",
        questionFields: "Câu hỏi",
        questionText: "Câu hỏi?",
        questionExplain: "Giải thích",
        questionTitle: "Title",
        key: "Cột 1",
        keyValue: "Cột 2",
        value: "Trả lời",
        type: "Loại câu hỏi",
        isCorrect: "?Đúng",
        sequenceNo: "Thứ tự",
        quizId: "Quiz",
        unitId: "Bài học",
        unit: "Bài học",
        hasManyCorrectAnswers: "Có nhiều câu trả lời đúng?",
        videos: "Thêm videos",
        audios: "Thêm âm thanh",
        images: "Thêm ảnh",
        answers: "Thêm câu trả lời",
        questionMetas: "Thêm câu hỏi con",
        vocabMetas: "Thêm từ vựng",
        answerMetas: "Thêm câu trả lời",
        vocabulary: "Từ vựng",
        multiple_choice: "Lựa chọn đáp án đúng",
        arrangement: "Sắp xếp chữ cái",
        fill_word: "Điền 1 từ",
        fill_word_multiple: "Điền nhiều từ",
        catching_up: "Nói đuổi",
        back_talk: "Nói đối đáp",
        back_talk_video: "Nói đối đáp theo video",
        back_talk_conversation: "Nói đối đáp hội thoại",
        fill_word_multiple_answer: "Điền nhiều từ (max 3 đồng nghĩa)",
        pair: "Tìm cặp câu tương xứng",
        game_fish: "Game cá lớn cá bé",
        game_spin: "Game xoay ô",
        game_mine: "Game đào vàng",
        game_pokemon: "Game pokemon",
        game_train: "Game tàu",
        BricksDownGame: "BricksDownGame",
        BridgeGame: "BridgeGame",
        BasketballGame: "BasketballGame",
        MixAndMatchGame: "MixAndMatchGame",
        reading: "Dạng đọc",
        listening: "Dạng nghe",
        word: "Từ vựng",
        audio: "Audio",
        spell: "Đánh vần",
        mean: "Nghĩa",
        example: "Ví dụ",
        other: "Khác",
        new_arrange: "Sắp xếp mới",
        flash_card: "Flashcard",
        speak_not_text:'Nói không text',
      },
      page: {
        list: "Danh sách",
      },
      fieldGroups: {
        question: "Câu hỏi",
        answer: "Câu trả lời",
      },
    },
    units: {
      name: "Bài học",
      fields: {
        childIds: "Bài học con",
        name: "Tên bài học",
        sequenceNo: "Thứ tự",
        duration: "Thời lượng (s)",
        sectionId: "Chương",
        createdAt: "Ngày tạo",
        isPrivate: "Bài học free",
        isQuiz: "Dạng Quiz",
        canComment: "Bình luận",
        learning: "learning",
        theory: "theory",
        video: "video",
        vocab: "vocab",
        speaking: "speaking",
        quiz: "quiz",
        reading: "reading",
        listening: "listening",
        game: "game",
      },
      page: {
        list: "Danh sách",
      },
    },

    posts: {
      name: "Bài đăng",
      fields: {
        title: "Tên bài đăng",
        sequenceNo: "Thứ tự",
        status: "Trạng thái",
        updateTime: "Cập nhật lúc",
        imageUrl: "Ảnh minh họa",
        description: "Mô tả ngắn",
        createdBy: "Người tạo",
        createdAt: "Ngày tạo",
        updatedAt: "Ngày tạo",
      },
      page: {
        list: "Danh sách",
      },
    },
    categories: {
      name: "Phân loại khóa học",
      fields: {
        imageUrl: "Ảnh",
        name: "Tên",
        parentId: "Phân loại lớn hơn",
        createdAt: "Ngày tạo",
        courseCount: "Số khóa học",
        sequenceNo: "Thứ tự",
      },
      page: {
        list: "Danh sách",
      },
    },
  },
};

export default customEnglishMessages;
