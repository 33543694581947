import moment from 'moment';
import React, { FC } from 'react';
import { Chip } from '@material-ui/core';
import { FieldProps } from '../types';

interface Props extends FieldProps<any> {
  className?: string;
}

const DateTimeField: FC<Props> = ({ record, className, source }) => {
  return record ? (
    <Chip
      className={className}
      label={
        source && record[source]
          ? moment(record[source]).format("YYYY-MM-DD HH:mm:ss")
          : "Unknown"
      }
    />
  ) : null;
};

export default DateTimeField;
