import React, { FC } from "react";
import { SelectInput } from "react-admin";
import { Filter, NumberInput, TextInput } from "react-admin";
import { NotificationFilterName } from "./Create";

const ListFilter: FC<any> = (props) => (
  <Filter {...props}>
    <NumberInput source="id" />
    <TextInput source="name" label="Tiêu đề" />
    {/* <SelectInput
      source="type"
      label="Loại thông báo"
      choices={[
        { id: "watch_new", name: "Xem tin tức" },
        { id: "watch_course", name: "Xem khóa học" },
        { id: "watch_last_lesson", name: "Xem bài học gần nhất" },
        { id: "watch_login", name: "Xem trang đăng nhập" },
        { id: "watch_card_active", name: "Xem trang kích hoạt thẻ" },
        { id: "watch_home_page", name: "Xem trang chủ" },
        { id: "watch_landing_page", name: "Xem landing page" },
        { id: "watch_post", name: "Xem bài viết" },
        { id: "watch_nothing", name: "Chỉ có thông báo, không điều hư" },
      ]}
      resource="jobs"
    /> */}
  </Filter>
);

export default ListFilter;
