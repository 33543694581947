import { Box, Card, CardContent } from "@material-ui/core";
import * as _ from "lodash";
import React, { useEffect, useState } from "react";
import {
  FormWithRedirect,
  required,
  SelectInput,
  DateInput,
} from "react-admin";
import PercentIcon from "@mui/icons-material/Percent";
import AdsClickIcon from "@mui/icons-material/AdsClick";
import VisibilityIcon from "@mui/icons-material/Visibility";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useNotify } from "react-admin";
import moment from "moment";
import { customHttpClientAdmin } from "../dataProvider";
import MuiltipleCheckbox from "./components/MuiltipleCheckbox";

export enum Filter {
  Slider = "slider",
  Popup = "popup",
}

type Detail = {
  totalNoActiveCardView: number;
  totalNoActiveCardClick: number;
  totalExpriedView: number;
  totalExpriedClick: number;
  totalReturnFeeView: number;
  totalReturnFeeClick: number;
  totalTrialView: number;
  totalTrialClick: number;
};

type DataStatical = {
  totalView: number;
  totalClick: number;
  detail: Detail;
};

const StatisticalPage = () => {
  const [sliderPopup, setSliderPopup] = useState(Filter.Popup);
  const [startTime, setStartTime] = useState<string>(
    moment().subtract(1, "months").format("YYYY-MM-DD")
  );

  const [endTime, setEndTime] = useState<string>(moment().format("YYYY-MM-DD"));
  const [dataStatical, setDataStatical] = useState<DataStatical>({
    totalClick: 0,
    totalView: 0,
    detail: {
      totalNoActiveCardView: 0,
      totalNoActiveCardClick: 0,
      totalExpriedView: 0,
      totalExpriedClick: 0,
      totalReturnFeeView: 0,
      totalReturnFeeClick: 0,
      totalTrialView: 0,
      totalTrialClick: 0,
    },
  });

  const [dataSeleted, setDataSelected] = useState<string[]>([]);
  const [selected, setSelected] = useState<string[]>([]);

  function createData(
    name: string,
    calories: number,
    fat: number,
    carbs: number,
    protein: number
  ) {
    return { name, calories, fat, carbs, protein };
  }

  const totalPercent = (totalClick: number, totalView: number) => {
    const result = (totalClick / totalView) * 100;
    let roundedResult;
    if (isNaN(result)) {
      roundedResult = 0;
    } else if (result >= 10) {
      roundedResult = result.toFixed(1);
    } else {
      roundedResult = Math.round(result * 10) / 10;
    }
    const percentage: any = roundedResult + " %";
    return percentage;
  };

  const formatNumber = (number: number) => {
    const formatter = new Intl.NumberFormat("en-US");
    const formattedNumber = formatter.format(number);
    return formattedNumber;
  };

  const rows = [
    createData(
      "Chưa kích hoạt",
      dataStatical?.detail.totalNoActiveCardView,
      totalPercent(
        dataStatical?.detail.totalNoActiveCardView,
        dataStatical?.totalView
      ),
      dataStatical?.detail.totalNoActiveCardClick,
      totalPercent(
        dataStatical?.detail.totalNoActiveCardClick,
        dataStatical?.totalClick
      )
    ),
    createData(
      "Dùng thử",
      dataStatical?.detail.totalTrialView,
      totalPercent(
        dataStatical?.detail.totalTrialView,
        dataStatical?.totalView
      ),
      dataStatical?.detail.totalTrialClick,
      totalPercent(
        dataStatical?.detail.totalTrialClick,
        dataStatical?.totalClick
      )
    ),
    createData(
      "Trả phí",
      dataStatical?.detail.totalReturnFeeView,
      totalPercent(
        dataStatical?.detail.totalReturnFeeView,
        dataStatical?.totalView
      ),
      dataStatical?.detail.totalReturnFeeClick,
      totalPercent(
        dataStatical?.detail.totalReturnFeeClick,
        dataStatical?.totalClick
      )
    ),
    createData(
      "Đã hết hạn",
      dataStatical?.detail.totalExpriedView,
      totalPercent(
        dataStatical?.detail.totalExpriedView,
        dataStatical?.totalView
      ),
      dataStatical?.detail.totalExpriedClick,
      totalPercent(
        dataStatical?.detail.totalExpriedClick,
        dataStatical?.totalClick
      )
    ),
  ];

  useEffect(() => {
    customHttpClientAdmin("/admin/advertisements/category", {
      method: "POST",
      // body: JSON.stringify({ type: sliderPopup }),
    })
      .then((res: any) => res.json)
      .then((response: any) => {
        if (response) {
          setDataSelected(response.data);
        } else console.log(response);
      })
      .catch((error: any) => {
        console.log(error);
      });
  }, [sliderPopup]);

  const initData = async () => {
    try {
      const body = {
        start_date: startTime,
        end_date: endTime,
        type: selected,
      };
      await customHttpClientAdmin("/admin/advertisements/report", {
        method: "POST",
        body: JSON.stringify(body),
      })
        .then((res: any) => res.json)
        .then((response: any) => {
          if (response) {
            setDataStatical(response);
          } else console.log(response);
        })
        .catch((error: any) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    initData();
  }, [startTime, endTime, selected]);

  const onChangeStartTime = (e: any) => {
    setStartTime(e.target.value);
  };
  const onChangeEndTime = (e: any) => {
    setEndTime(e.target.value);
  };

  if (!dataStatical) {
    return <div>Loading....</div>;
  }

  return (
    <FormWithRedirect
      initialValues={{
        slide: Filter.Popup,
      }}
      render={(formProps: any) => {
        return (
          <Card>
            <form>
              <CardContent>
                <Box display={{ md: "block", lg: "flex" }}>
                  {/* <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                    <SelectInput
                      fullWidth
                      source="slide"
                      validate={requiredValidate}
                      label="Chọn Loại"
                      choices={[
                        {
                          id: Filter.Slider,
                          name: "Slider",
                        },
                        {
                          id: Filter.Popup,
                          name: "Popup",
                        },
                      ]}
                      onChange={(e: any) => {
                        setSliderPopup(e.target.value);
                      }}
                      resource="slideshow"
                    />
                  </Box> */}
                  <Box flex={1} ml={{ xs: 0, sm: "0.5em" }} />

                  <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                    <MuiltipleCheckbox
                      data={dataSeleted}
                      title={
                        sliderPopup == "slider" ? "Chọn Slider" : "Chọn Popup"
                      }
                      setSelected={setSelected}
                    />
                  </Box>

                  <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                    <DateInput
                      source="startTime"
                      // fullwidth
                      defaultValue={startTime}
                      label="Ngày bắt đầu"
                      requiredValidate
                      onChange={(e: any) => onChangeStartTime(e)}
                    />{" "}
                    <DateInput
                      source="endTime"
                      defaultValue={endTime}
                      // fullwidth
                      requiredValidate
                      label="Ngày kết thúc"
                      onChange={(e: any) => onChangeEndTime(e)}
                    />
                  </Box>
                </Box>
                <Box
                  display={{ md: "block", lg: "flex" }}
                  style={{ marginTop: 40, marginBottom: 50 }}>
                  <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                    <Card
                      style={{
                        padding: 20,
                        boxShadow:
                          "rgba(50, 50, 93, 0.25) 0px 10px 20px -10px, rgba(0, 0, 0, 0.3) 0px 6px 12px -6px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset",
                      }}>
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "row",
                        }}>
                        <p style={{ fontSize: 25 }}>Số tài khoản hiển thị</p>

                        <VisibilityIcon
                          style={{ width: 50, fontSize: 40, marginLeft: 10 }}
                        />
                      </Box>
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}>
                        <p style={{ fontSize: 30 }}>
                          {formatNumber(dataStatical?.totalView)}
                        </p>
                      </Box>
                    </Card>
                  </Box>
                  <Box
                    flex={1}
                    ml={{ xs: 0, sm: "0.5em" }}
                    style={{
                      marginRight: 40,
                      marginLeft: 40,
                    }}>
                    <Card
                      style={{
                        padding: 20,

                        boxShadow:
                          "rgba(50, 50, 93, 0.25) 0px 10px 20px -10px, rgba(0, 0, 0, 0.3) 0px 6px 12px -6px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset",
                      }}>
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "row",
                        }}>
                        <p style={{ fontSize: 25 }}>Số tài khoản Click</p>

                        <AdsClickIcon
                          style={{ width: 50, fontSize: 40, marginLeft: 10 }}
                        />
                      </Box>
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}>
                        <p style={{ fontSize: 30 }}>
                          {formatNumber(dataStatical?.totalClick)}
                        </p>
                      </Box>
                    </Card>
                  </Box>
                  <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                    <Card
                      style={{
                        padding: 20,
                        boxShadow:
                          "rgba(50, 50, 93, 0.25) 0px 10px 20px -10px, rgba(0, 0, 0, 0.3) 0px 6px 12px -6px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset",
                      }}>
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "row",
                        }}>
                        <PercentIcon
                          style={{ width: 50, fontSize: 40, marginLeft: 10 }}
                        />
                        <p style={{ fontSize: 25 }}>Click</p>
                      </Box>
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}>
                        <p style={{ fontSize: 30 }}>
                          {totalPercent(
                            dataStatical?.totalClick,
                            dataStatical?.totalView
                          )}
                        </p>
                      </Box>
                    </Card>
                  </Box>
                </Box>
                <p style={{ fontSize: 30, fontWeight: "500" }}>
                  THỐNG KÊ THEO ĐỐI TƯỢNG
                </p>
                <TableContainer
                  component={Paper}
                  style={{
                    boxShadow:
                      "rgba(50, 50, 93, 0.25) 0px 5px 10px -5px, rgba(0, 0, 0, 0.3) 0px 3px 6px -3px, rgba(10, 37, 64, 0.35) 0px -1px 3px 0px inset",
                    marginBottom: 20,
                  }}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          align="center"
                          style={{ fontWeight: "bold" }}>
                          Tài khoản
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            border: "0.01px solid #e5e5e5",
                            fontSize: 17,
                            fontWeight: "bold",
                          }}>
                          Tài khoản View
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            border: "0.01px solid #e5e5e5",
                            fontWeight: "bold",
                            fontSize: 17,
                          }}>
                          % View
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            border: "0.01px solid #e5e5e5",
                            fontSize: 17,
                            fontWeight: "bold",
                          }}>
                          Tài khoản click
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            border: "0.01px solid #e5e5e5",
                            fontWeight: "bold",
                            fontSize: 17,
                          }}>
                          % Click
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map((row) => (
                        <TableRow
                          key={row.name}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}>
                          <TableCell align="center" scope="row">
                            {row.name}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ border: "0.01px solid #e5e5e5" }}>
                            {row.calories}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ border: "0.01px solid #e5e5e5" }}>
                            {row.fat}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ border: "0.01px solid #e5e5e5" }}>
                            {row.carbs}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ border: "0.01px solid #e5e5e5" }}>
                            {row.protein}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </CardContent>
            </form>
          </Card>
        );
      }}
    />
  );
};

const requiredValidate = [required()];

export default StatisticalPage;
