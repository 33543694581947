import { Box, Button, Chip } from "@material-ui/core";
import { CloudUploadOutlined } from "@material-ui/icons";
import React from "react";
import {
  Button as RAButton,
  Datagrid,
  Edit as EditAdmin,
  EditButton,
  ReferenceManyField,
  Show,
  TextField,
  TopToolbar,
  useTranslate,
} from "react-admin";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Form } from "./Create";
import { ContestMode } from "./List";
import ListActions from "./ListActions";

const QuestionShowActions = ({ id }: any) => {
  const translate = useTranslate();
  const history = useHistory();
  return (
    <TopToolbar>
      <Button
        className="MuiButton-textPrimary MuiButton-textSizeSmall"
        component={Link}
        to={{
          pathname: "/questions/create",
          search: "?game=game&mode=contest",
          state: { record: { contestId: parseInt(id) } },
        }}
      >
        + Create Game
      </Button>
      <Button
        className="MuiButton-textPrimary MuiButton-textSizeSmall"
        component={Link}
        to={{
          pathname: "/questions/create",
          search: "?mode=contest",
          state: { record: { contestId: parseInt(id) } },
        }}
      >
        + Create Question
      </Button>

      <RAButton
        className="MuiButton-textPrimary MuiButton-textSizeSmall"
        component={Link}
        onClick={() => {
          history.push(`/contests/${id}/questions`);
        }}
        label="Import câu hỏi"
      >
        <CloudUploadOutlined />
      </RAButton>
    </TopToolbar>
  );
};
const Test = (props: any) => {
  const history = useHistory();
  return (
    <Button
      onClick={() => {
        history.push(`/questions/${props.record.id}?mode=contest`);
      }}
      variant="contained"
      color="primary"
    >
      EDIT
    </Button>
  );
};

const TypeTranslate = (props: any) => {
  const translate = useTranslate();
  const { record } = props;
  if (!record) return <></>;
  return (
    <Chip label={translate(`resources.questions.fields.${record.type}`)} />
  );
};
const QuestionShow = (props: any) => {
  return (
    <Show {...props} actions={<QuestionShowActions {...props} />} title={" "}>
      <ReferenceManyField
        label="questions"
        reference="questions"
        target="contestId"
        perPage={1000}
        sort={{ field: "sequenceNo", order: "ASC" }}
      >
        <Datagrid>
          <TextField source="questionText" />
          <TextField source="sequenceNo" label="Thứ tự" translate="no" />
          <TypeTranslate source="type" />
          {/* <EditButton /> */}
          <Test />
        </Datagrid>
      </ReferenceManyField>
    </Show>
  );
};

const Edit = (props: any) => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const mode = params.get("mode") || ContestMode.Online;
  return (
    <div style={{ flex: 1, display: "flex" }}>
      <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
        <EditAdmin
          component="div"
          {...props}
          undoable={false}
          actions={<ListActions mode={mode} />}
          label=""
        >
          <Form isEdit />
        </EditAdmin>
      </Box>
      <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
        <QuestionShow {...props} />
      </Box>
    </div>
  );
};

export default Edit;
