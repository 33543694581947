import React, { FC } from "react";
import {
  Datagrid,
  List,
  ReferenceField,
  TextField,
  useTranslate,
} from "react-admin";
import AvatarField from "../components/AvatarField";
import DateTimeField from "../components/DateTimeField";
import ListActions from "./ListActions";
import ListFilter from "./ListFilters";
import { ReferenceArrayField } from "react-admin";
import { SingleFieldList } from "react-admin";
import ChipField from "../components/ChipField";

const MajorList: FC<any> = (props) => {
  const translate = useTranslate();
  return (
    <List
      {...props}
      title={translate("resources.posts.page.list")}
      actions={<ListActions />}
      filters={<ListFilter />}
    >
      <Datagrid optimized rowClick="edit">
        <TextField source="id" />
        <TextField source="minAge" label="tuổi >=" />
        <TextField source="maxAge" label="tuổi <=" />
        <ReferenceArrayField
          source="courseIds"
          reference="courses"
          label="Khóa học"
        >
          <SingleFieldList>
            <ChipField source="name" />
          </SingleFieldList>
        </ReferenceArrayField>
        <DateTimeField source="createdAt" />
      </Datagrid>
    </List>
  );
};

export default MajorList;
