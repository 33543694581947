import React, { FC, useState } from "react";
import { Datagrid, List, TextField, useTranslate } from "react-admin";
import ListActions from "./ListActions";
import ListFilter from "./ListFilters";
import { PopupUpload } from "./Upload";

const MajorList: FC<any> = (props) => {
  const translate = useTranslate();
  const [isShowApproved, showApproved] = useState(false);
  return (
    <>
      <List
        {...props}
        title={translate("resources.posts.page.list")}
        actions={
          <ListActions
            isShowApproved={isShowApproved}
            showApproved={showApproved}
          />
        }
        filters={<ListFilter />}
      >
        <Datagrid optimized>
          <TextField source="id" />
          <TextField source="word1" />
          <TextField source="word2" />
          <TextField source="word3" />
          <TextField source="word4" />
          <TextField source="word5" />
          <TextField source="word6" />
          <TextField source="word7" />
          <TextField source="word8" />
          <TextField source="word9" />
          <TextField source="word10" />
        </Datagrid>
      </List>
      <PopupUpload
        isShowApproved={isShowApproved}
        showApproved={showApproved}
      />
    </>
  );
};

export default MajorList;
