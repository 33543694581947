import { Box, Card, CardContent } from "@material-ui/core";

import React from "react";
import { NumberInput } from "react-admin";
import {
  SelectInput,
  Create as CreateAdmin,
  FormWithRedirect,
  required,
  TextInput,
  Toolbar,
} from "react-admin";
import { useLocation } from "react-router";
import { ContestMode, GradeStatus } from "../contests/List";
import ListActions from "./ListActions";

export const Form = (props: any) => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const mode = params.get("mode");
  return (
    <FormWithRedirect
      {...props}
      initialValues={{
        duration: 0,
        mode,
        status: GradeStatus.Draft,
      }}
      render={(formProps: any) => (
        <Card>
          <form>
            <CardContent>
              <Box display={{ md: "block", lg: "flex" }}>
                <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                  <TextInput
                    type="text"
                    label="Tên lớp"
                    fullWidth={true}
                    source="name"
                    validate={requiredValidate}
                    resource="grades"
                  />
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                  <NumberInput
                    label="Thứ thự"
                    fullWidth={true}
                    source="sequenceNo"
                    validate={requiredValidate}
                    resource="grades"
                  />
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                  <SelectInput
                    fullWidth
                    source="status"
                    validate={requiredValidate}
                    resource="contests"
                    choices={[
                      { name: "Publish", id: GradeStatus.Publish },
                      { name: "Draft", id: GradeStatus.Draft },
                    ]}
                  />
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                  <SelectInput
                    fullWidth
                    source="mode"
                    disabled
                    validate={requiredValidate}
                    resource="contests"
                    choices={[
                      { name: "Online", id: ContestMode.Online },
                      { name: "Offline", id: ContestMode.Offline },
                    ]}
                  />
                </Box>
              </Box>
            </CardContent>
            <Toolbar
              record={formProps.record}
              basePath={formProps.basePath}
              invalid={formProps.invalid}
              handleSubmit={formProps.handleSubmit}
              saving={formProps.saving}
              undoable={false}
              resource="grades"
            />
          </form>
        </Card>
      )}
    />
  );
};
const Create = (props: any) => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const mode = params.get("mode");
  return (
    <CreateAdmin
      undoable={false}
      {...props}
      actions={<ListActions mode={mode} />}
    >
      <Form />
    </CreateAdmin>
  );
};

const requiredValidate = [required()];

export default Create;
