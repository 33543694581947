import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Modal,
  Typography,
} from "@material-ui/core";
import { SendOutlined } from "@material-ui/icons";
import * as _ from "lodash";
import { title } from "process";
import React, { memo, useCallback, useEffect, useState } from "react";
import { CheckboxGroupInput } from "react-admin";
import { DateInput } from "react-admin";
import { minValue } from "react-admin";
import { number } from "react-admin";
import { useRedirect } from "react-admin";
import { FormDataConsumer } from "react-admin";

import { DateTimeInput } from "react-admin";
import {
  AutocompleteInput,
  Create as CreateAdmin,
  DeleteButton,
  FormWithRedirect,
  NumberInput,
  ReferenceInput,
  required,
  SaveButton,
  SelectInput,
  TextInput,
  Toolbar,
  useNotify,
} from "react-admin";
import { shallowEqual } from "react-redux";
import CourseList from "../components/CourseList";
import DisplayInput from "../components/DisplayInput";
import FormFillterUser from "../components/FormFillterUser";
import GroupInput from "../components/GroupInput";
import NewsList from "../components/NewsList";
import ObjectSendInput from "../components/ObjectSendInput";
import { RichTextInput } from "../components/RichTextInput";
import SendTime from "../components/SendTime";
import StatusInput from "../components/StatusInput";
import TimeInput from "../components/TimeInput";
import TypeNotiInput from "../components/TypeNotiInput";
import UploadImage from "../components/UploadImage";
import { customHttpClient, customHttpClientAdmin } from "../dataProvider";
import { useApiMutate } from "../helpers/useApiMutate";
import ListActions from "./ListActions";
import SelectSearch from "react-select-search";
import SelectCourses from "./SelectCourse";

export enum NotificationFilter {
  All = "all",
  ByAge = "by_age",
  ByExpired = "by_expired",
  ByUnderExpire = "by_under_expire",
  ByNotExpire = "by_not_expire",
  ByCourse = "by_course",
  Mode = "mode",
}

// const MyInput = memo(
//   (props: any) => {
//     console.log(props.input.value);

//     const mergeProps = _.merge(props, {
//       input: { value: props.input.value ? _.parseInt(props.input.value) : "" },
//     });

//     return (
//       <AutocompleteInput
//         // {...mergeProps}
//         {...props}
//         optionText={(row: any) => {
//           return `Bài: ${_.get(row, "name")} Chương: ${_.get(
//             row,
//             "section.name"
//           )}`;
//         }}
//         fullWidth
//       />
//     );
//   },
//   (prev, next) => {
//     console.log(prev.input.value, next.input.value);
//     console.log(shallowEqual(prev.choices, next.choices));

//     return (
//       prev.input.value == next.input.value &&
//       shallowEqual(prev.choices, next.choices)
//     );
//   }
// );

export const NotificationFilterName: { [a: string]: number } = {
  // [NotificationFilter.AutoDaily]: "Hằng ngày",
  // [NotificationFilter.AutoExpired]: "Hết hạn",
  // [NotificationFilter.AutoUnderExpire]: "Sắp hết hạn",
  // [NotificationFilter.AutoBirthday]: "Sinh nhật",
  // [NotificationFilter.AutoNotDoingCourse]: "Chưa làm khóa học",
  [NotificationFilter.Mode]: 1,
};

type Course = {
  id: string;
  name: string;
};

export const Form = (props: any) => {
  const courseID = props.record?.course_id;
  const [filterType, setFilterType] = useState(
    _.get(props, "record.filterType", NotificationFilter.All)
  );
  const notify = useNotify();

  const { loading, mutate: sendFunc } = useApiMutate(
    `/api/notifications/send`,
    { notiId: props.record?.id },
    [],
    {
      defaultValue: null,
      callback: (err) => {
        if (err) {
          notify(err.message, "warning");
          return;
        }
        notify("Hệ thống tiếp nhận và đang xử lý!", "info");
      },
    }
  );

  interface daysList {
    id: string;
    name: string;
  }
  const [notiType, setnotiType] = useState<string>("");
  const [varCheckTimepublic, setVarCheckTimepublic] = useState<any>(
    props.record.send_type
  );
  const [valueCourse, setValueCourse] = useState<any>();
  const [datepublic, setDatepublic] = useState<Date>(new Date());
  const [typeSetTime, setTypeSetTime] = useState<string>("");
  const [changeForm, setChangeform] = useState<boolean>(false);
  const [News, setNews] = useState<string>("");
  const [course, setCourse] = useState<string>("");
  const [statusCard, setStatusCard] = useState("");
  const [objectSend, setObjectSend] = useState<any>();
  const [timeSend, setTimeSend] = useState<any>(props.record.send_time);
  const [dateSend, setDateSend] = useState<any>(props.record.send_once);
  const [lable, setLable] = useState<string>("Chọn ngày");
  const [checkinput, setCheckInput] = useState<boolean>(false);
  const [courseList, setCourseList] = useState([]);
  console.log("props.record", props.record, courseID, valueCourse);

  const setID = useCallback((e: any) => {
    console.log("Create", e);
    setValueCourse(e);
  }, []);

  const academicLevelChoices = [
    { optionValue: "2", label: "T2" },
    { optionValue: "3", label: "T3" },
    { optionValue: "4", label: "T4" },
    { optionValue: "5", label: "T5" },
    { optionValue: "6", label: "T6" },
    { optionValue: "7", label: "T7" },
    { optionValue: "8", label: "CN" },
  ];
  const receivedItems: any = props.record.value_timers
    ? props.record.value_timers
    : [];

  const [academicLevel, setAcademicLevel] = useState(
    academicLevelChoices.map((option) => {
      if (receivedItems.indexOf(option.optionValue) != -1) {
        return {
          ...option,
          value: true,
        };
      }
      return {
        ...option,
        value: false,
      };
    })
  );
  const newList = academicLevel
    .filter((item: any) => item.value)
    .map((item: any) => `${item.optionValue}`);

  const style = {
    position: "absolute" as "absolute",
    top: "25%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  //=================get list course=================
  useEffect(() => {
    // customHttpClient(``, {
    if (notiType === "watch_course") {
      let res = customHttpClient(`/crud/courses`, {
        method: "GET",
      })
        .then((res: any) => res.json)
        .then((data: any) => {
          const dataCourse = data?.map((item: Course) => {
            return {
              id: item.id,
              name: item.name,
            };
          });
          setCourseList(dataCourse);
        })
        .then(() => {
          if (props.record?.course_id !== null) {
            setValueCourse(props.record?.course_id);
          }
        })
        .catch((error: any) => {
          console.log(error, "error");
        });
    }
  }, [notiType]);

  const requiredValidate = [required()];
  const requiredValidateTime = [
    required(),
    minValue(
      `${datepublic.getFullYear()}-${
        datepublic.getMonth() + 1 >= 10
          ? datepublic.getMonth() + 1
          : `0${datepublic.getMonth() + 1}`
      }-${
        datepublic.getDate() >= 10
          ? datepublic.getDate()
          : `0${datepublic.getDate()}`
      }`
    ),
  ];

  const redirect = useRedirect();
  const daysList: daysList[] = [
    { id: "2", name: "T2" },
    { id: "3", name: "T3" },
    { id: "4", name: "T4" },
    { id: "5", name: "T5" },
    { id: "6", name: "T6" },
    { id: "7", name: "T7" },
    { id: "8", name: "CN" },
  ];

  function CheckTypeNoti(props: any) {
    const disabled = props.disabled;
    const data = props.data;

    if (notiType === "watch_landing_page") {
      return (
        <Box flex={2} ml={{ xs: 0, sm: "0.5em" }}>
          <TextInput
            source="link_page"
            validate={requiredValidate}
            resource="notifications"
            disabled={disabled}
          />
        </Box>
      );
    } else if (notiType === "watch_post") {
      return (
        <>
          <Box flex={2} ml={{ xs: 0, sm: "0.5em" }}>
            <TextInput
              label="Tiêu đề bài viết"
              type="text"
              fullWidth
              source="title_post"
              validate={requiredValidate}
              resource="notifications"
              disabled={disabled}
            />
          </Box>
          <Box flex={3} ml={{ xs: 0, sm: "0.5em" }}>
            <TextInput
              fullWidth
              source="slug_post"
              label="Mô tả ngắn"
              resource="notifications"
              disabled={disabled}
            />
          </Box>
        </>
      );
    } else if (notiType === "watch_course") {
      return (
        <div style={{ width: 500 }}>
          {/* <CourseList
            source="course_id"
            resource="notifications"
            setCourse={setCourse}
            disabled={disabled}
          /> */}
          <SelectCourses
            listCourse={courseList}
            data={data}
            setId={setID}
            id={valueCourse}
          />
        </div>
      );
    } else if (notiType === "watch_new") {
      return (
        <NewsList
          source="post_id"
          resource="notifications"
          setNews={setNews}
          disabled={disabled}
        />
      );
    } else return <></>;
  }
  const validateUserCreation = async (values: any) => {
    const errors: any = {};
    if (values.status === "complete") {
      errors.status = "Required *";
    }
    return errors;
  };

  function CheckTimepublic(props: any) {
    const disable = props.disabled;
    if (varCheckTimepublic === "timer") {
      return (
        <>
          <Box flex={2} ml={{ xs: 0, sm: "0.5em" }}>
            <SelectInput
              label="Loại hẹn giờ"
              source="type_timers"
              validate={requiredValidate}
              resource="units"
              disabled={disable}
              onChange={(e: any) => {
                setChangeform(true);
                setTypeSetTime(e.target.value);
              }}
              choices={[
                { id: "once", name: "Một lần" },
                { id: "daily", name: "Lặp lại hằng ngày" },
                { id: "weekly", name: "Lặp lại hằng tuần" },
                { id: "monthly", name: "Lặp lại hằng tháng" },
              ]}
            />
          </Box>
          {typeSetTime !== "" && (
            <Box
              flex={3}
              ml={{ xs: 0, sm: "0.5em" }}
              style={{ marginRight: "2vw" }}
            >
              <TimeInput
                validate={requiredValidate}
                source="send_time"
                label="Hẹn giờ"
                disabled={disable}
                setTimeSend={setTimeSend}
              />
            </Box>
          )}
          <>
            {typeSetTime === "weekly" ? (
              // <CheckboxGroupInput
              //   label="Chọn ngày"
              //   choices={daysList}
              //   validate={requiredValidate}
              //   source="value_timers"
              //   style={{ marginLeft: "10px" }}
              //   disabled={disable}
              // />
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <p
                  style={{
                    paddingRight: "1vw",
                    color: checkinput ? "red" : "black",
                  }}
                >
                  {checkinput ? "Required" : "Chọn ngày:"}
                </p>
                <GroupInput
                  disabled={disable}
                  academicLevelChoices={academicLevelChoices}
                  setAcademicLevel={setAcademicLevel}
                  academicLevel={academicLevel}
                  setCheckInput={setCheckInput}
                />
              </div>
            ) : typeSetTime === "once" ? (
              <Box flex={3} ml={{ xs: 0, sm: "0.5em" }}>
                <DateInput
                  source="send_once"
                  label="Ngày gửi"
                  disabled={disable}
                  onChange={(e: any) => setDateSend(e.target.value)}
                  validate={requiredValidateTime}
                />
              </Box>
            ) : typeSetTime === "monthly" ? (
              <Box flex={4} ml={{ xs: 0, sm: "0.5em" }}>
                <TextInput
                  fullWidth={true}
                  placeholder="Nhập ngày ngăn cách bởi dấu , VD: 1,7,12"
                  label={lable}
                  source="value_timers"
                  onChange={() =>
                    setLable("Nhập ngày ngăn cách bởi dấu , VD: 1,7,12")
                  }
                  validate={requiredValidate}
                  disabled={disable}
                  resource="notifications"
                />
              </Box>
            ) : (
              <></>
            )}
          </>
        </>
      );
    } else if (varCheckTimepublic === "birthday")
      return (
        <Box flex={2} ml={{ xs: 0, sm: "0.5em" }}>
          <TimeInput
            validate={requiredValidate}
            source="send_time"
            label="Hẹn giờ"
          />
        </Box>
      );
    else return <></>;
  }

  if (filterType === NotificationFilter.ByCourse) {
    props.record.extra2 = props.record.extra2
      ? _.parseInt(props.record.extra2)
      : "";
  }

  const btn = document.getElementsByClassName(
    "MuiButton-sizeSmall"
  ) as HTMLCollectionOf<HTMLElement>;

  useEffect(() => {
    btn[2].style["display"] = "none";
  }, []);
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <FormWithRedirect
        {...props}
        validate={validateUserCreation}
        initialValues={{
          filterType: NotificationFilter.Mode,
          mode: 1,
        }}
        render={(formProps: any) => {
          let obj = formProps.form.getState().errors;
          const handleOpen = () => setOpen(true);
          const handleClose = () => setOpen(false);
          function isEmpty(object: any) {
            return Object.keys(object).length === 0;
          }
          let emptyObj = isEmpty(obj);

          const checkDisableForm =
            (formProps.record.send_type === "send_now" ||
              (new Date(
                `${formProps.record.send_once}:${formProps.record.send_time}`
              ).getTime() -
                new Date().getTime() <
                0 &&
                formProps.record.send_type === "timer" &&
                formProps.record.type_timers === "once")) &&
            formProps.record.id &&
            formProps.record.status !== "draft";

          if (!changeForm) {
            setnotiType(`${formProps.record.type ?? formProps.record.type}`);
            setObjectSend(
              formProps.record.is_send_all === 1
                ? 1
                : formProps.record.is_send_all === 0
                ? 0
                : null
            );
            setStatusCard(
              `${
                formProps.record.card_expiry_type
                  ? formProps.record.card_expiry_type
                  : ""
              }`
            );
            setVarCheckTimepublic(
              `${
                formProps.record.send_type ? formProps.record.send_type : null
              }`
            );
            setTypeSetTime(
              `${
                formProps.record.type_timers
                  ? formProps.record.type_timers
                  : null
              }`
            );
          }

          const handleOnsubmitNoti = async (data: any) => {
            let dataObject: any = {};
            if (
              new Date(`${dateSend}:${timeSend}`).getTime() -
                new Date().getTime() <
                0 &&
              typeSetTime === "once" &&
              varCheckTimepublic === "timer"
            ) {
              notify(
                "Thời gian hẹn giờ không được nhỏ hơn thời gian hiện tại, vui lòng kiểm tra lại."
              );
            } else if (
              typeSetTime === "weekly" &&
              newList.length < 1 &&
              varCheckTimepublic === "timer"
            ) {
              setCheckInput(true);
            } else {
              dataObject = {
                ...data,
                course_id: valueCourse,
                mode: 1,
                is_send_all: objectSend === 1 ? 1 : 0,
                value_timers:
                  typeSetTime === "weekly" && varCheckTimepublic === "timer"
                    ? newList + ""
                    : typeSetTime === "monthly" &&
                      varCheckTimepublic === "timer"
                    ? data.value_timers + ""
                    : null,
                status: data.status === "complete" ? null : data.status,
              };

              console.log("dataObject", dataObject);

              customHttpClientAdmin(
                `/admin/notifications/${
                  formProps.record.id
                    ? formProps.record.id + "/update"
                    : "create"
                }`,
                {
                  method: "POST",
                  body: JSON.stringify(dataObject),
                }
              )
                .then((res: any) => res.json)
                .then((success: any) => {
                  if (success.status === 200) {
                    redirect("/notifications");
                    notify(
                      `${
                        formProps.record.id
                          ? "Chỉnh sửa thông báo thành công"
                          : "Thêm thông báo thành công"
                      }`
                    );
                  }
                })
                .catch((error: any) => {
                  handleClose();
                  if (error.message === "value_timers_invalid")
                    notify("Số ngày bạn nhập sai định dạng");
                  else if (error.message === "The status field is required.") {
                    notify("Vui lòng chọn trạng thái của thông báo");
                    window.scrollTo({
                      top: 0,
                      behavior: "smooth",
                    });
                  } else if (error.message === "")
                    notify("Vui lòng nhập nội dung thông báo!");
                  console.log(error, "error");
                });
            }
          };
          // {...props}
          // initialValues={{ filterType: NotificationFilter.All }}
          // render={(formProps: any) => {
          return (
            <Card>
              <form>
                <CardContent>
                  <Box display={{ md: "block", lg: "flex" }}>
                    <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                      <TextInput
                        type="text"
                        fullWidth
                        source="title"
                        disabled={checkDisableForm}
                        validate={requiredValidate}
                        resource="notifications"
                      />
                    </Box>
                    <Box display={{ md: "block", lg: "flex" }}>
                      <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                        <StatusInput
                          source="status"
                          label="Trạng thái"
                          resource="notifications"
                          disabled={checkDisableForm}
                          status={formProps.record.status}
                          isCheckCreate={formProps.record.id}
                        />
                      </Box>
                    </Box>
                    <Box display={{ md: "block", lg: "flex" }}>
                      <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                        <DisplayInput
                          label="Hiển thị"
                          source="display"
                          resource="notifications"
                          disabled={checkDisableForm}
                        />
                      </Box>
                    </Box>
                    <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                      <UploadImage
                        source="imageUrl"
                        resource="notifications"
                        checkDisableForm={checkDisableForm}
                      />
                    </Box>
                  </Box>
                  <Box display={{ md: "block", lg: "flex" }}>
                    <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                      <TextInput
                        multiline
                        source="content"
                        fullWidth
                        label="Nội dung thông báo"
                        validate={requiredValidate}
                        disabled={checkDisableForm}
                      />
                    </Box>
                  </Box>
                  <Box display={{ md: "block", lg: "flex" }}>
                    <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                      <TypeNotiInput
                        source="type"
                        setnotiType={setnotiType}
                        setChangeform={setChangeform}
                        resource="notifications"
                        disable={checkDisableForm}
                      />
                    </Box>
                    <CheckTypeNoti
                      disabled={checkDisableForm}
                      data={props.record}
                    />
                  </Box>
                  {notiType === "watch_post" && (
                    <Box display={{ md: "block", lg: "flex" }}>
                      <Box display={{ md: "block", lg: "flex" }}>
                        <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                          <RichTextInput
                            source="content_post"
                            height={200}
                            resource="notifications"
                            checkDisableForm={checkDisableForm}
                          />
                        </Box>
                      </Box>
                    </Box>
                  )}
                  <Box display={{ md: "block", lg: "flex" }}>
                    <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                      <ObjectSendInput
                        source="is_send_all"
                        resource="notifications"
                        setObjectSend={setObjectSend}
                        setChangeform={setChangeform}
                        disabled={checkDisableForm}
                      />
                    </Box>
                  </Box>
                  {objectSend === 0 && (
                    <FormFillterUser
                      statusCard={statusCard}
                      setStatusCard={setStatusCard}
                      setChangeform={setChangeform}
                      disabled={checkDisableForm}
                    />
                  )}
                  <Box display={{ md: "block", lg: "flex" }}>
                    <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                      <SendTime
                        source="send_type"
                        resource="notifications"
                        setVarCheckTimepublic={setVarCheckTimepublic}
                        setChangeform={setChangeform}
                        disabled={checkDisableForm}
                      />
                    </Box>
                    <CheckTimepublic disabled={checkDisableForm} />
                  </Box>
                  {checkDisableForm && (
                    <p>
                      Thông báo đã được gửi đi lúc:{" "}
                      {varCheckTimepublic === "send_now"
                        ? new Date(formProps.record.updated_at).toLocaleString(
                            "en-GB",
                            {
                              hour12: false,
                            }
                          )
                        : new Date(
                            `${formProps.record.send_once}:${formProps.record.send_time}`
                          ).toLocaleString("en-GB", {
                            hour12: false,
                          })}
                    </p>
                  )}
                </CardContent>
                <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style}>
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                    >
                      {formProps.form.getState().values.status === "draft"
                        ? "Không gửi được thông báo ở trạng thái nháp"
                        : "Gửi ngay"}
                    </Typography>
                    <Typography id="modal-modal-description">
                      {formProps.form.getState().values.status === "draft"
                        ? "Thông báo đang ở trạng thái nháp, nếu bạn muốn gửi ngay bây giờ vui lòng chỉnh sửa trạng thái thành hoạt động"
                        : "Thông báo sẽ được gửi đi ngay bây giờ, bạn có chắc chắn muốn gửi"}
                    </Typography>
                    <div style={{ display: "flex", justifyContent: "end" }}>
                      <Button
                        onClick={() => {
                          if (emptyObj) {
                            handleOnsubmitNoti(
                              formProps.form.getState().values
                            );
                          } else formProps.handleSubmitWithRedirect();
                        }}
                      >
                        {formProps.form.getState().values.status === "draft"
                          ? "Tiếp tục lưu nháp"
                          : "Gửi ngay"}
                      </Button>
                      <Button onClick={handleClose}>
                        {formProps.form.getState().values.status === "draft"
                          ? "Quay lại"
                          : "Chỉnh sửa"}
                      </Button>
                    </div>
                  </Box>
                </Modal>
                <Toolbar
                  record={formProps.record}
                  basePath={formProps.basePath}
                  invalid={formProps.invalid}
                  handleSubmit={formProps.handleSubmit}
                  saving={formProps.saving}
                  undoable={false}
                  resource="notification"
                >
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    width="100%"
                  >
                    <span>
                      <SaveButton
                        disabled={checkDisableForm}
                        saving={formProps.saving}
                        // handleSubmitWithRedirect={
                        //   formProps.handleSubmitWithRedirect
                        // }
                        handleSubmitWithRedirect={() => {
                          if (emptyObj) {
                            if (
                              formProps.form.getState().values.send_type ===
                              "send_now"
                            )
                              handleOpen();
                            else
                              handleOnsubmitNoti(
                                formProps.form.getState().values
                              );
                          } else formProps.handleSubmitWithRedirect();
                        }}
                        onClick={() => {
                          const data = formProps.form.getState().values.content;
                          if (!data) {
                            setCheckInput(true);
                          }
                        }}
                      />

                      {/* {!_.isEmpty(props.record) && (
                        <Button
                          variant="contained"
                          style={{ marginLeft: 16 }}
                          color="primary"
                          disabled={loading}
                          startIcon={
                            loading ? <CircularProgress /> : <SendOutlined />
                          }
                          onClick={sendFunc}
                        >
                          Send
                        </Button>
                      )} */}
                    </span>
                    <DeleteButton record={formProps.record} />
                  </Box>
                </Toolbar>
              </form>
            </Card>
          );
        }}
      />
    </>
  );
};
const Create = (props: any) => {
  return (
    <CreateAdmin undoable={false} {...props} actions={<ListActions />}>
      <Form />
    </CreateAdmin>
  );
};

// const requiredValidate = [required()];

export default Create;
