import * as _ from "lodash";
import * as reactAdmin from "react-admin";
import crudProvider from "ra-nest-crud";
import { fetchUtils } from "react-admin";
import { useEffect } from "react";

const httpClient = (url: string, options: any = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }
  const token = localStorage.getItem("user_token");
  options.headers.set("Authorization", `Bearer ${token}`);
  return fetchUtils.fetchJson(url, options);
};

export const customHttpClient = (url: string, options: any = {}) => {
  return httpClient(process.env.REACT_APP_API_BASE_URL + url, options);
};

export const customHttpClientAdmin = (url: string, options: any = {}) => {
  return httpClient(process.env.REACT_APP_API_NEW_BASE + url, options);
};

const dataProvider = crudProvider(
  process.env.REACT_APP_API_BASE_URL + "/crud",
  httpClient
);

const dataProvider1 = crudProvider(
  process.env.REACT_APP_API_NEW_BASE + "/admin",
  httpClient
);

const customProvider = async (
  action: string,
  resource: string,
  params: any
) => {
  if (action === reactAdmin.UPDATE || action === reactAdmin.CREATE) {
    if (
      !["courses", "units", "questions", "posts", "categories"].includes(
        resource
      )
    ) {
      // fallback to the default implementation
      return defaultDataProvider(action, resource, params);
    }
    // Freshly dropped pictures are File objects and must be converted to base64 strings
    const newParams = await handleImageData(params);

    return defaultDataProvider(action, resource, newParams);
  }

  return defaultDataProvider(action, resource, params);
};

const handleSingleImage = async (params: any) => {
  const isNewPicture = typeof params.data.imageUrl === "object";
  if (isNewPicture) {
    const imageUrl = await convertFileToBase64(params.data.imageUrl);
    return {
      ...params,
      data: {
        ...params.data,
        ...{ imageUrl },
      },
    };
  }
  return params;
};

const handleMultipleImage = async (params: any) => {
  const images = await Promise.all(
    params.data.images.map(async (image: any) => {
      const isNewPicture = !image.id;
      if (isNewPicture) {
        const base64 = await convertFileToBase64(image);
        return { value: base64 };
      }
      return image;
    })
  );

  return {
    ...params,
    data: {
      ...params.data,
      ...{ images },
    },
  };
};

const handleMultipleAudios = async (params: any) => {
  const audios = await Promise.all(
    params.data.audios.map(async (audio: any) => {
      const isNewPicture = !audio.id;
      if (isNewPicture) {
        const base64 = await convertFileToBase64(audio);
        return { value: base64 };
      }
      return audio;
    })
  );

  return {
    ...params,
    data: {
      ...params.data,
      ...{ audios },
    },
  };
};

const handleImageData = async (params: any) => {
  let _params = params;

  if (_params.data && _params.data.hasOwnProperty("imageUrl")) {
    _params = await handleSingleImage(params);
  }

  if (_params.data && _params.data.hasOwnProperty("images")) {
    _params = await handleMultipleImage(_params);
  }

  if (_params.data && _params.data.hasOwnProperty("audios")) {
    _params = await handleMultipleAudios(_params);
  }

  return _params;
};

const defaultDataProvider = (action: string, resource: string, params: any) => {
  if (params.filter) {
    const keyMatchFks = _.keys(params.filter).filter((key) =>
      key.includes("Id")
    );

    const keyMatchNestedFks = _.keys(params.filter).filter((key) =>
      params.filter[key].hasOwnProperty("id")
    );

    const objectMatchNested = keyMatchNestedFks.map((key) => ({
      key: `${key}.id||$eq`,
      value: params.filter[key].id,
    }));

    const objectMatch = keyMatchFks.map((key) => ({
      key: `${key}||$eq`,
      value: params.filter[key],
    }));

    const _params = {
      ...params,
      filter: {
        ..._.omit(params.filter, [...keyMatchFks, ...keyMatchNestedFks]),
        ..._.mapValues(_.keyBy(objectMatch, "key"), "value"),
        ..._.mapValues(_.keyBy(objectMatchNested, "key"), "value"),
      },
    };

    if (resource === "notifications" || resource === "pushers")
      return dataProvider1(
        action,
        `notifications${resource === "notifications" ? "/normal" : "/pusher"}`,
        _params
      );
    else if (resource === "homeblocksold") {
      return dataProvider(action, "homeblocks", _params);
    } else if (
      resource === "slideshow" ||
      resource === "advertisements" ||
      resource === "homeblocks"
    ) {
      return dataProvider1(action, resource, _params);
    } else if (resource === "homeslides") {
      return dataProvider1(action, `advertisements/slider`, _params);
    } else return dataProvider(action, resource, _params);
  } else {
    if (resource === "notifications" || resource === "pushers") {
      return dataProvider1(
        action,
        `notifications${resource === "notifications" ? "/normal" : "/pusher"}`,
        params
      );
    } else if (resource === "homeblocksold") {
      return dataProvider(action, "homeblocks", params);
    } else if (
      resource === "slideshow" ||
      resource === "advertisements" ||
      resource === "homeblocks"
    ) {
      return dataProvider1(action, resource, params);
    } else if (resource === "homeslides") {
      console.log("asdasdasd , 2222");

      return dataProvider1(action, "advertisements", params);
    } else return dataProvider(action, resource, params);
  }
};

/**
 * Convert a `File` object returned by the upload input into a base 64 string.
 * That's not the most optimized way to store images in production, but it's
 * enough to illustrate the idea of data provider decoration.
 */
const convertFileToBase64 = (file: any): any =>
  new Promise((resolve, reject) => {
    if (!file) {
      resolve("");
    }
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;

    reader.readAsDataURL(file.rawFile);
  });
export default customProvider;
