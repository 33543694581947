import { TextField } from "@material-ui/core";
import React, { FC } from "react";
import { useInput } from "react-admin";
import { FieldProps } from "../types";

interface Props extends FieldProps<any> {
  className?: string;
  fullWidth?: any;
  resource?: string;
  validate?: any;
  disabled?: boolean;
  setTimeSend?: any;
}

const TimeInput: FC<Props> = (props) => {
  const { record, className, disabled, setTimeSend, source, ...rest } = props;
  const inputProps = useInput(props);

  const {
    input,
    meta: { touched, error },
    isRequired,
  } = inputProps;
  return (
    <TextField
      {...rest}
      value={input.value}
      type="time"
      disabled={disabled}
      required={isRequired}
      helperText={error}
      className={className}
      variant="filled"
      InputLabelProps={{
        shrink: true,
      }}
      inputProps={{
        step: 60, // 5 min
      }}
      onChange={(e) => {
        input.onChange(e.target.value);
        setTimeSend(e.target.value);
      }}
    />
  );
};

export default TimeInput;
