import Create from './Create';
import Edit from './Edit';
import List from './List';
import ReviewIcon from '@material-ui/icons/Accessibility';

export default {
  icon: ReviewIcon,
  list: List,
  create: Create,
  edit: Edit,
  name: "studentcardcodes",
};
