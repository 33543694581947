import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import CloudDownload from "@material-ui/icons/CloudDownload";
import * as FileSaver from "file-saver";
import _ from "lodash";
import { ImportButton } from "ra-import-csv";
import { useState } from "react";
import { default as React, FC } from "react";
import { Button as RAButton } from "react-admin";
import { useHistory, useRouteMatch } from "react-router";
import { Link } from "react-router-dom";
import { slugify } from "../helpers";
import { QuestionMetaKey } from "./Create";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 275,
    },
    bullet: {
      display: "inline-block",
      margin: "0 2px",
      transform: "scale(0.8)",
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
    formControl: {
      margin: theme.spacing(2),
      minWidth: 360,
    },
  })
);

const HEADER_MAPPER: any = {
  dap_an_1: "a1",
  dap_an_2: "a2",
  dap_an_3: "a3",
  dap_an_4: "a4",
  dap_an_5: "a5",
  dap_an_6: "a6",
  cau_hoi: "questionText",
  dap_an_dung: "answers",
  tieu_de: "questionTitle",
  giai_thich: "questionExplain",
};

const configImportQuestion = {
  parseConfig: {
    header: true,
    transformHeader: (h: string) => {
      const slug = slugify(h);
      return HEADER_MAPPER[slug] || slug;
    },
  },
};

const ImportPage: FC<any> = (props) => {
  const { isContest } = props;
  const match = useRouteMatch<{ id: string }>();
  const id = match.params.id;
  const classes = useStyles();
  const [type, setType] = useState<string>("");
  const history = useHistory();
  return (
    <div>
      <RAButton
        className="MuiButton-textPrimary MuiButton-textSizeSmall"
        component={Link}
        onClick={() => {
          history.goBack();
        }}
        label="List"
      >
        <FormatListBulletedIcon />
      </RAButton>
      <Card className={classes.root} variant="outlined">
        <CardContent>
          <div>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="demo-simple-select-outlined-label">
                Loại câu hỏi
              </InputLabel>
              <Select
                value={type}
                onChange={(e: any) => {
                  setType(e.target.value);
                }}
                label="Loại câu hỏi"
              >
                <MenuItem value={QuestionMetaKey.MULTIPLE_CHOICE}>
                  Lựa chọn đáp án đúng
                </MenuItem>
                <MenuItem value={QuestionMetaKey.FILL_WORD_MULTIPLE_ANSWER}>
                  Điền từ (max 3 đồng nghĩa)
                </MenuItem>
                <MenuItem value={QuestionMetaKey.ARRANGEMENT}>
                  Sắp xếp từ, chữ
                </MenuItem>
              </Select>
            </FormControl>
          </div>

          {type === QuestionMetaKey.MULTIPLE_CHOICE && (
            <MultipleChoice id={id} isContest={isContest} />
          )}
          {type === QuestionMetaKey.FILL_WORD_MULTIPLE_ANSWER && (
            <MultipleAnswer id={id} isContest={isContest} />
          )}
          {type === QuestionMetaKey.ARRANGEMENT && (
            <Arrangement id={id} isContest={isContest} />
          )}
        </CardContent>
      </Card>
    </div>
  );
};

const MultipleChoice = ({ id, isContest }: any) => {
  return (
    <>
      <RAButton
        className="MuiButton-textPrimary MuiButton-textSizeSmall"
        component={Link}
        onClick={() => {
          FileSaver.saveAs(
            `${process.env.REACT_APP_API_BASE_URL}/public/templates/example-import-multiple-choice.xlsx`,
            "example-import-multiple-choice.xlsx"
          );
        }}
        label="Tải mẫu"
      >
        <CloudDownload />
      </RAButton>
      <ImportButton
        resource="questions"
        basePath={`/questions`}
        {...configImportQuestion}
        label="Import"
        showOverWrite={false}
        addFields={(rows: any) => {
          return _.map(rows, (row, index) => ({
            ...row,
            answers: `${row.answers}`.split(","),
            ...(isContest
              ? { contestId: parseInt(id) }
              : { unitId: parseInt(id) }),
            sequenceNo: index + 1,
          }));
        }}
      />
    </>
  );
};

const MultipleAnswer = ({ id, isContest }: any) => {
  return (
    <>
      <RAButton
        className="MuiButton-textPrimary MuiButton-textSizeSmall"
        component={Link}
        onClick={() => {
          FileSaver.saveAs(
            `${process.env.REACT_APP_API_BASE_URL}/public/templates/example_import_multiple_answer.xlsx`,
            "example_import_multiple_answer.xlsx"
          );
        }}
        label="Tải mẫu"
      >
        <CloudDownload />
      </RAButton>
      <ImportButton
        resource="questionmultipleanswers"
        basePath={`/questionmultipleanswers`}
        parseConfig={{
          header: true,
          transformHeader: (h: string) => {
            const slug = slugify(h);
            const mapper: any = {
              cau_hoi: "questionText",
              tieu_de: "questionTitle",
              giai_thich: "questionExplain",
            };
            return mapper[slug] || slug;
          },
        }}
        label="Import"
        showOverWrite={false}
        addFields={(rows: any) => {
          return _.map(rows, (row, index) => {
            const answerKeys = _.keys(row).filter((key) =>
              _.startsWith(key, "dap_an")
            );
            return {
              ...row,
              answers: _(answerKeys)
                .map((key) => {
                  const answer = row[key];
                  if (!answer) return;
                  return answer;
                })
                .reject(_.isEmpty),
              ...(isContest
                ? { contestId: parseInt(id) }
                : { unitId: parseInt(id) }),
              sequenceNo: index + 1,
            };
          });
        }}
      />
    </>
  );
};

const Arrangement = ({ id, isContest }: any) => {
  return (
    <>
      <RAButton
        className="MuiButton-textPrimary MuiButton-textSizeSmall"
        component={Link}
        onClick={() => {
          FileSaver.saveAs(
            `${process.env.REACT_APP_API_BASE_URL}/public/templates/example_arrangement.xlsx`,
            "example_arrangement.xlsx"
          );
        }}
        label="Tải mẫu"
      >
        <CloudDownload />
      </RAButton>
      <ImportButton
        resource="questionarrangements"
        basePath={`/questionarrangements`}
        parseConfig={{
          header: true,
          transformHeader: (h: string) => {
            const slug = slugify(h);
            const mapper: any = {
              cau_hoi: "questionText",
              tieu_de: "questionTitle",
              giai_thich: "questionExplain",
            };
            return mapper[slug] || slug;
          },
        }}
        label="Import"
        showOverWrite={false}
        addFields={(rows: any) => {
          return _.map(rows, (row, index) => {
            const answerKeys = _.keys(row).filter((key) =>
              _.startsWith(key, "dap_an")
            );
            return {
              ...row,
              answers: _(answerKeys)
                .sort()
                .map((key) => {
                  const answer = row[key];
                  if (!answer) return;
                  return answer;
                })
                .reject(_.isEmpty),
              ...(isContest
                ? { contestId: parseInt(id) }
                : { unitId: parseInt(id) }),
              sequenceNo: index + 1,
            };
          });
        }}
      />
    </>
  );
};
export default ImportPage;
