import AvatarField from "../components/AvatarField";
import ListActions from "./ListActions";
import ListFilter from "./ListFilters";
import React, { FC } from "react";
import SexField from "../components/SexField";
import {
  Datagrid,
  DateField,
  List,
  TextField,
  useTranslate,
} from "react-admin";

const StudentList: FC<any> = (props) => {
  const translate = useTranslate();

  return (
    <List
      {...props}
      title={translate("resources.students.page.list")}
      actions={<ListActions />}
      filters={<ListFilter />}
      sort={{ field: "id", order: "ASC" }}>
      <Datagrid optimized rowClick="edit">
        <TextField source="id" />
        <AvatarField source="avatar" size="36" />
        <TextField source="email" />
        <TextField source="fullname" />
        <TextField source="dob" />
        <SexField source="sex" />
        <TextField source="telephone" />
        <TextField source="address" />
        <DateField source="createdAt" />
      </Datagrid>
    </List>
  );
};

export default StudentList;
