import * as _ from "lodash";
import ListActions from "./ListActions";
import React from "react";
import { Box, Card, CardContent } from "@material-ui/core";
import {
  Create as CreateAdmin,
  FormWithRedirect,
  NumberInput,
  required,
  TextInput,
  Toolbar,
  ReferenceArrayInput,
  AutocompleteArrayInput,
} from "react-admin";

export const Form = (props: any) => {
  return (
    <FormWithRedirect
      {...props}
      render={(formProps: any) => (
        <Card>
          <form>
            <CardContent>
              <Box display={{ md: "block", lg: "flex" }}>
                <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                  <TextInput
                    type="text"
                    fullWidth={true}
                    source="displayName"
                    validate={requiredValidate}
                    resource="cards"
                  />
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                  <NumberInput
                    source="value"
                    fullWidth={true}
                    validate={requiredValidate}
                    resource="cards"
                  />
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                  <NumberInput
                    source="expiredDate"
                    fullWidth={true}
                    validate={requiredValidate}
                    resource="cards"
                  />
                </Box>
              </Box>
              <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                <ReferenceArrayInput
                  source="courseIds"
                  reference="courses"
                  resource="courses"
                  validate={requiredValidate}
                  filterToQuery={(searchText: any) => {
                    return _.isEmpty(_.trim(searchText))
                      ? null
                      : {
                          name: searchText,
                        };
                  }}>
                  <AutocompleteArrayInput
                    optionText="name"
                    validate={requiredValidate}
                    fullWidth
                  />
                </ReferenceArrayInput>
              </Box>
            </CardContent>
            <Toolbar
              record={formProps.record}
              basePath={formProps.basePath}
              invalid={formProps.invalid}
              handleSubmit={formProps.handleSubmit}
              saving={formProps.saving}
              undoable={false}
              resource="cards"
            />
          </form>
        </Card>
      )}
    />
  );
};
const Create = (props: any) => {
  return (
    <CreateAdmin undoable={false} {...props} actions={<ListActions />}>
      <Form />
    </CreateAdmin>
  );
};

const requiredValidate = [required()];

export default Create;
