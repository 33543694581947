import * as _ from "lodash";
import React, { FC } from "react";
import {
  Datagrid,
  List,
  ReferenceField,
  TextField,
  useTranslate,
} from "react-admin";
import DateTimeField from "../components/DateTimeField";
import AvatarField from "../components/AvatarField";
import { HomeSlideModelName } from "./Create";
import ListActions from "./ListActions";
import ListFilter from "./ListFilters";
import { FunctionField } from "react-admin";
import { EditButton } from "react-admin";
import { DeleteButton } from "react-admin";
import { NumberField } from "react-admin";

const SourceField: FC<any> = (props) => {
  const model = _.get(props, "record.model");

  return (
    <ReferenceField reference={model} {...props} source="sourceId">
      <FunctionField
        render={(record: any) => {
          return record.name || record.title;
        }}
      />
    </ReferenceField>
  );
};

const ModelFieldField: FC<any> = (props) => {
  return <span>{HomeSlideModelName[_.get(props, "record.model")]}</span>;
};

const MajorList: FC<any> = (props) => {
  const translate = useTranslate();

  const calculatePhanTram = (record: {
    userView: number;
    userClick: number;
  }) => {
    const phanTram = Math.floor((record.userClick / record.userView) * 100);
    return _.isNaN(phanTram) ? 0 : phanTram + "%";
  };

  return (
    <List
      {...props}
      title={translate("resources.posts.page.list")}
      actions={<ListActions />}
      filters={<ListFilter />}
      bulkActionButtons={false}>
      <Datagrid optimized>
        <NumberField source="index" label="" />
        <TextField source="sequenceNo" label="Thứ tự" />
        <AvatarField source="imageUrl" label="Hình ảnh" />
        <TextField source="title" label="Tiêu đề" />
        <TextField source="type_slider" label="Loại" />
        <TextField source="device" label="Thiết bị" />
        <DateTimeField source="createdAt" label="Ngày tạo" />
        <TextField source="status" label="Trạng thái" />
        <TextField source="userView" label="User View" />
        <TextField source="userClick" label="User Click" />
        <FunctionField
          label="% Click"
          render={(record: any) => <span>{calculatePhanTram(record)}</span>}
        />
        <DeleteButton />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export default MajorList;
