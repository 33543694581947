import ChipField from "../components/ChipField";
import DateTimeField from "../components/DateTimeField";
import ListActions from "./ListActions";
import ListFilter from "./ListFilters";
import React, { FC } from "react";
import {
  Datagrid,
  List,
  ReferenceField,
  TextField,
  useTranslate,
} from "react-admin";

const StudentList: FC<any> = (props) => {
  const translate = useTranslate();
  return (
    <List
      {...props}
      title={translate("resources.studentcardcodes.page.list")}
      actions={<ListActions />}
      filters={<ListFilter />}
      sort={{ field: "id", order: "ASC" }}
    >
      <Datagrid optimized rowClick="edit">
        <TextField source="id" />
        <ReferenceField
          source="studentId"
          reference="students"
          label={translate("resources.students.fields.email")}
        >
          <TextField source="email" />
        </ReferenceField>
        <ReferenceField
          source="studentId"
          reference="students"
          label={translate("resources.students.fields.telephone")}
        >
          <TextField source="telephone" />
        </ReferenceField>
        <ReferenceField
          source="cardCodeId"
          reference="cardcodes"
          resources="cardcodes"
          label={translate("resources.cardcodes.fields.code")}
        >
          <ChipField source="code" />
        </ReferenceField>

        <ReferenceField
          source="cardCodeId"
          reference="cardcodes"
          label={translate("resources.cards.fields.displayName")}
        >
          <ReferenceField
            source="cardId"
            reference="cards"
            label={translate("resources.cards.fields.value")}
          >
            <TextField source="displayName" />
          </ReferenceField>
        </ReferenceField>

        <DateTimeField source="expiredAt" />
        <DateTimeField source="createdAt" />
      </Datagrid>
    </List>
  );
};

export default StudentList;
