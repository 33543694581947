import React, { cloneElement, FC } from 'react';
import {
  CreateButton,
  ListButton,
  sanitizeListRestProps,
  TopToolbar,
} from "react-admin";

const ListActions: FC<any> = ({
  currentSort,
  className,
  resource,
  filters,
  displayedFilters,
  exporter, // you can hide ExportButton if exporter = (null || false)
  filterValues,
  permanentFilter,
  hasCreate, // you can hide CreateButton if hasCreate = false
  basePath,
  selectedIds,
  onUnselectItems,
  showFilter,
  maxResults,
  total,
  hasShow,
  hasList,
  ...rest
}) => (
  <TopToolbar
    className={className}
    {...sanitizeListRestProps(rest)}
    hasshow={`${hasShow}`}
    haslist={`${hasList}`}>
    {!filters && <ListButton basePath={basePath} />}
    {filters &&
      cloneElement(filters, {
        undoable: "false",
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: "button",
      })}
    <CreateButton basePath={basePath} />
  </TopToolbar>
);

export default ListActions;
