import React from "react";
import { Route } from "react-router-dom";
import Configuration from "./configuration/Configuration";
import ChangePasswordAdmin from "./changepassword/ChangePasswordAdmin";

import UnitCreate from "./units/Create";
import ImportPage from "./questions/ImportPage";

import { GrantPermission } from "./permissions";

export default [
  <Route exact path="/configuration" render={() => <Configuration />} />,
  <Route exact path="/change-password" render={() => <ChangePasswordAdmin />} />,

  <Route
    exact
    path="/unit/create-by-section/:sectionId"
    render={(props) => (
      <UnitCreate {...props} basePath="/units" resource="units" />
    )}
  />,
  <Route
    exact
    path="/permissions/:userId"
    render={(props) => <GrantPermission {...props} />}
  />,
  <Route
    exact
    path="/units/:id/questions"
    render={(props) => <ImportPage {...props} />}
  />,
  <Route
    exact
    path="/contests/:id/questions"
    render={(props) => <ImportPage {...props} isContest />}
  />,
];
