import React from 'react';
import { Box } from '@material-ui/core';
import {
  ArrayInput,
  required,
  SimpleFormIterator,
  TextInput,
} from "react-admin";

export const ArrangeCharacter = (props: any) => {
  return (
    <>
      <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
        <ArrayInput source="answerMetas" resource="questions">
          <SimpleFormIterator>
            <TextInput
              source="value"
              resource="questions"
              required={requiredValidate}
            />
          </SimpleFormIterator>
        </ArrayInput>
      </Box>
    </>
  );
};

const requiredValidate = [required()];

export default ArrangeCharacter;
