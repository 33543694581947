import Avatar from "@material-ui/core/Avatar";
import React, { FC } from "react";
import { FieldProps } from "../types";

interface Props extends FieldProps<any> {
  className?: string;
  size?: string;
}

const AvatarField: FC<any> = ({
  record,
  size = "40",
  className,
  source = "avatar",
}) => {
  return record ? (
    <Avatar
      src={
        typeof record === "string"
          ? record
          : (record[source] || "").includes("http")
          ? record[source]
          : `${process.env.REACT_APP_API_BASE_URL}${record[source]}`
      }
      style={{ width: parseInt(size, 10), height: parseInt(size, 10) }}
      className={className}
    />
  ) : null;
};

export default AvatarField;
