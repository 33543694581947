import * as _ from "lodash";
import ListActions from "./ListActions";
import React from "react";
import { Box, Card, CardContent } from "@material-ui/core";
import {
  Create as CreateAdmin,
  FormWithRedirect,
  SelectInput,
  required,
  TextInput,
  Toolbar,
  ImageInput,
  NumberInput,
  DateTimeInput,
} from "react-admin";
import { RichTextInput } from "../components/RichTextInput";
import PreviewInputImage from "../components/PreviewInputImage";

export const Form = (props: any) => {
  return (
    <FormWithRedirect
      {...props}
      render={(formProps: any) => (
        <Card>
          <form>
            <CardContent>
              <Box display={{ md: "block", lg: "flex" }}>
                <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                  <TextInput
                    type="text"
                    fullWidth={true}
                    source="title"
                    validate={requiredValidate}
                    resource="posts"
                  />
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                  <TextInput
                    source="description"
                    fullWidth={true}
                    validate={requiredValidate}
                    resource="posts"
                  />
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                  <NumberInput
                    source="sequenceNo"
                    fullWidth={true}
                    validate={requiredValidate}
                    resource="posts"
                  />
                </Box>
              </Box>
              <Box display={{ md: "block", lg: "flex" }}>
                <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                  <SelectInput
                    fullWidth
                    source="status"
                    validate={requiredValidate}
                    resource="posts"
                    choices={[
                      { id: "publish", name: "publish" },
                      { id: "private", name: "draft" },
                    ]}
                  />
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                  <DateTimeInput
                    source="updateTime"
                    resource="posts"
                    fullWidth
                  />
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}></Box>
              </Box>
              <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                <ImageInput
                  source="imageUrl"
                  resource="posts"
                  multiple={false}
                  accept="image/*"
                  maxSize={2 * 1024 * 1024}
                  placeholder={<p>Kéo thả ảnh vào đây</p>}
                >
                  <PreviewInputImage source="imageUrl" title="title" />
                </ImageInput>
              </Box>
              <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                <RichTextInput source="content" />
              </Box>
            </CardContent>
            <Toolbar
              record={formProps.record}
              basePath={formProps.basePath}
              invalid={formProps.invalid}
              handleSubmit={formProps.handleSubmit}
              saving={formProps.saving}
              undoable={false}
              resource="posts"
            />
          </form>
        </Card>
      )}
    />
  );
};
const Create = (props: any) => {
  return (
    <CreateAdmin undoable={false} {...props} actions={<ListActions />}>
      <Form />
    </CreateAdmin>
  );
};

const requiredValidate = [required()];

export default Create;
