import React, { cloneElement, FC, useCallback, useState } from "react";
import {
  CreateButton,
  ListButton,
  sanitizeListRestProps,
  TopToolbar,
  Button,
} from "react-admin";
import { CloudUploadOutlined } from "@material-ui/icons";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import { useTranslate } from "react-admin";
import { useNotify } from "react-admin";
import { useRefresh } from "react-admin";

const ListActions: FC<any> = ({
  currentSort,
  className,
  resource,
  filters,
  displayedFilters,
  exporter, // you can hide ExportButton if exporter = (null || false)
  filterValues,
  permanentFilter,
  hasCreate, // you can hide CreateButton if hasCreate = false
  basePath,
  selectedIds,
  onUnselectItems,
  showFilter,
  maxResults,
  total,
  isShowApproved,
  showApproved,
  hasShow,
  hasList,
  ...rest
}) => {
  return (
    <TopToolbar
      className={className}
      {...sanitizeListRestProps(rest)}
      hasshow={`${hasShow}`}
      haslist={`${hasList}`}
    >
      {!filters && <ListButton basePath={basePath} />}
      {/* {filters &&
    cloneElement(filters, {
      undoable: "false",
      resource,
      showFilter,
      displayedFilters,
      filterValues,
      context: "button",
    })} */}
      {/* <CreateButton basePath={basePath} /> */}
      <Button
        variant="text"
        label="Tải lên"
        translate={"no"}
        onClick={() => {
          showApproved(() => true);
        }}
      >
        <CloudUploadOutlined style={{ fontSize: "20" }} />
      </Button>
    </TopToolbar>
  );
};

export default ListActions;
