import * as _ from "lodash";
import ListIcon from "@material-ui/icons/List";
import React, { cloneElement, FC } from "react";
import {
  CreateButton,
  ListButton,
  sanitizeListRestProps,
  TopToolbar,
  Button,
} from "react-admin";

import { ButtonBase } from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";

const ListActions: FC<any> = ({
  currentSort,
  className,
  resource,
  filters,
  displayedFilters,
  exporter, // you can hide ExportButton if exporter = (null || false)
  filterValues,
  permanentFilter,
  hasCreate, // you can hide CreateButton if hasCreate = false
  basePath,
  selectedIds,
  onUnselectItems,
  showFilter,
  maxResults,
  total,
  hasShow,
  hasList,
  mode,
  ...rest
}) => {
  const history = useHistory();
  const _mode = _.get(rest, "data.mode", undefined);
  return (
    <TopToolbar
      className={className}
      {...sanitizeListRestProps(rest)}
      hasshow={`${hasShow}`}
      haslist={`${hasList}`}
    >
      {!filters && (
        <Button
          component="button"
          variant="text"
          label="pos.action.listing"
          onClick={() => {
            if (_mode) {
              history.push(`/grades?mode=${_mode}`);
            } else {
              history.goBack();
            }
          }}
        >
          <ListIcon style={{ fontSize: "20" }} />
        </Button>
      )}
      {filters &&
        cloneElement(filters, {
          undoable: "false",
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button",
        })}
      {/* <CreateButton basePath={basePath} /> */}
      {!!mode && (
        <ButtonBase
          className="MuiButton-textPrimary MuiButton-textSizeSmall"
          component={Link}
          to={{
            pathname: "/grades/create",
            search: `?mode=${mode}`,
          }}
        >
          + Create
        </ButtonBase>
      )}
    </TopToolbar>
  );
};

export default ListActions;
