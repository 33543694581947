import React, { FC } from "react";
import { FieldProps } from "../types";
import { required, SelectInput, useTranslate } from "react-admin";

interface Props extends FieldProps<any> {
  className?: string;
  fullWidth?: any;
  resource?: string;
  disabled?: boolean;
  status?: string;
  isCheckCreate?: string;
}
const requiredValidate = [required()];
const StatusInput: FC<Props> = ({
  record,
  className,
  disabled,
  isCheckCreate,
  source,
  status,
  ...rest
}) => {
  const translate = useTranslate();

  return (
    <SelectInput
      validate={requiredValidate}
      {...rest}
      source={source}
      disabled={disabled}
      choices={
        status === "complete" && isCheckCreate
          ? [
              { id: "draft", name: translate("common.fields.draft") },
              { id: "activate", name: translate("common.fields.active") },
              { id: "complete", name: "Hoàn thành" },
            ]
          : [
              { id: "draft", name: translate("common.fields.draft") },
              { id: "activate", name: translate("common.fields.active") },
            ]
      }
    />
  );
};

export default StatusInput;
