import { Box, Card, CardContent, Input } from "@material-ui/core";
import * as _ from "lodash";
import React, { useEffect, useState } from "react";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import {
  AutocompleteInput,
  Create as CreateAdmin,
  FormWithRedirect,
  NumberInput,
  ReferenceInput,
  required,
  SelectInput,
  Toolbar,
} from "react-admin";
import UploadImage from "../components/UploadImage";
import ListActions from "./ListActions";
import { BooleanInput } from "react-admin";
import { TextInput } from "react-admin";
import { DataPopup } from "../advertisements/Create";
import { useNotify } from "react-admin";
import { useRedirect } from "react-admin";
import { customHttpClient, customHttpClientAdmin } from "../dataProvider";
import { MenuProps } from "../advertisements/components/MultipleSelect";
import SelectSearch from "react-select-search";
import "./styles.css";
import "react-select-search/style.css";

export enum HomeSlideModel {
  Post = "1",
  Course = "3",
  Link = "2",
}

export enum HomeSlideOnDevice {
  Mobile = "2",
  Web = "1",
  All = "3",
}

export const HomeSlideModelName: { [a: string]: string } = {
  [HomeSlideModel.Post]: "Bài đăng",
  [HomeSlideModel.Course]: "Khóa học",
  [HomeSlideModel.Link]: "Đường Link",
};

export const Form = (props: any) => {
  const [model, setModel] = useState(
    _.get(props, "record.type_slider", HomeSlideModel.Link)
  );
  const [valuePost, setValuePost] = useState<any>({ id: "", title: "" });
  const [listPost, setListPost] = useState<string[]>([]);
  const [listCourse, setListCourse] = useState<any>([{ name: "", value: "" }]);
  const [valueCourse, setValueCourse] = useState<number | any>();
  const noti = useNotify();
  const redirect = useRedirect();

  console.log(valueCourse, "valueCourse");

  const {
    dataSlider,
    idSlider,
    isEditSlider,
  }: {
    dataSlider: DataPopup;
    idSlider: string;
    isEditSlider: boolean;
  } = props;

  const getListDataPostAndCourse = () => {
    try {
      customHttpClient("/crud/posts", {
        method: "GET",
      })
        .then((res: any) => res.json)
        .then((response: any) => {
          setListPost(response);
        })
        .catch((error: any) => {
          console.log(error);
        });
      customHttpClient(
        "/crud/courses?limit=1000&page=1&sort%5B0%5D=id%2CDESC&offset=0",
        {
          method: "GET",
        }
      )
        .then((res: any) => res.json)
        .then((response: any) => {
          setListCourse(response.data);
        })
        .catch((error: any) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    if (isEditSlider) {
      setModel(
        dataSlider?.type_slider == HomeSlideModel.Link
          ? _.get(props, "record.type_slider", HomeSlideModel.Link)
          : dataSlider?.type_slider == HomeSlideModel.Post
          ? _.get(props, "record.type_slider", HomeSlideModel.Post)
          : dataSlider?.type_slider == HomeSlideModel.Course
          ? _.get(props, "record.type_slider", HomeSlideModel.Course)
          : _.get(props, "record.type_slider", HomeSlideModel.Link)
      );

      const titlePost: any = listPost.find(
        (item: any) => item.id == dataSlider?.post_id
      );
      const course: any = listCourse.find(
        (item: any) => item.id == dataSlider?.coures_id
      );

      setValuePost(
        model == HomeSlideModel.Post && dataSlider?.type_slider == model
          ? { id: dataSlider?.post_id, title: titlePost.title }
          : ""
      );
      setValueCourse(
        model == HomeSlideModel.Course && dataSlider?.type_slider == model
          ? dataSlider?.coures_id
          : ""
      );
    }
  }, [dataSlider, listPost, listCourse]);

  useEffect(() => {
    getListDataPostAndCourse();
  }, []);

  const btn = document.getElementsByClassName(
    "MuiButton-sizeSmall"
  ) as HTMLCollectionOf<HTMLElement>;

  useEffect(() => {
    if (isEditSlider) return;
    btn[2].style["display"] = "none";
  }, []);

  const hanldeSumitSlider = (data: DataPopup) => {
    console.log(data, "data");
    if (Number(data.sequenceNo) < 0) {
      noti("Số thứ tự không được bé hơn 0", "error");
      return;
    }
    if (data.imageUrl?.length < 0 && !data.imageUrl?.includes("https://")) {
      noti("Vui lòng chọn hình ảnh cho slider", "error");
      return;
    }

    const body = {
      type: data.type,
      type_slider: data.type_slider,
      title: data.title,
      sequenceNo: data.sequenceNo,
      imageUrl: data.imageUrl,
      device: data.device,
      status: data.status ? 1 : 0,
      url: model == HomeSlideModel.Link ? data.url : null,
      createdBy: data.createdBy,
      detail: {},
      coures_id: model == HomeSlideModel.Course ? valueCourse : null,
      post_id: model == HomeSlideModel.Post ? valuePost.id : null,
    };

    console.log(body, "body ===>>>>>>>>");
    customHttpClientAdmin(
      isEditSlider
        ? `/admin/advertisements/${idSlider}`
        : "/admin/advertisements",
      {
        method: isEditSlider ? "PATCH" : "POST",
        body: JSON.stringify(body),
      }
    )
      .then((res: any) => res.json)
      .then((response: any) => {
        if (response) {
          redirect("/homeslides");
          noti("Thành công", "log");
        } else console.log(response);
      })
      .catch((error: any) => {
        console.log(error);
      });
  };

  const handleChangePost = (event: SelectChangeEvent) => {
    const selectedValue = event.target.value;
    const selectedPost = listPost.find(
      (item: any) => item.title === selectedValue
    );
    setValuePost(selectedPost);
  };

  const handleChangeCourse = (event: SelectChangeEvent) => {
    const selectedValue = event.target.value;
    const selectedCourse = listCourse.find(
      (item: any) => item.name === selectedValue
    );
    console.log(selectedCourse, "selectedCourse");

    setValueCourse(selectedCourse);
  };

  return (
    <FormWithRedirect
      {...props}
      initialValues={{
        type_slider: isEditSlider
          ? dataSlider?.type_slider == HomeSlideModel.Link
            ? HomeSlideModel.Link
            : dataSlider?.type_slider == HomeSlideModel.Post
            ? HomeSlideModel.Post
            : HomeSlideModel.Course
          : HomeSlideModel.Link,
        device: isEditSlider
          ? dataSlider?.device == HomeSlideOnDevice.All
            ? HomeSlideOnDevice.All
            : dataSlider?.device == HomeSlideOnDevice.Mobile
            ? HomeSlideOnDevice.Mobile
            : HomeSlideOnDevice.Web
          : HomeSlideOnDevice.Mobile,
      }}
      render={(formProps: any) => {
        return (
          <Card>
            <form>
              <CardContent>
                <Box display={{ md: "block", lg: "flex" }}>
                  <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                    <Box display="none">
                      <TextInput
                        type="text"
                        defaultValue="slider"
                        source="type"
                        resource="advertisements"
                      />
                      <TextInput
                        type="text"
                        defaultValue={props?.createdBy}
                        source="createdBy"
                        resource="advertisements"
                      />
                    </Box>
                    <UploadImage
                      source="imageUrl"
                      validate={requiredValidate}
                      defaultValue={
                        isEditSlider ? dataSlider?.imageUrl : undefined
                      }
                    />
                  </Box>
                  <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                    <SelectInput
                      fullWidth
                      source="device"
                      validate={requiredValidate}
                      label="Chọn thiết bị"
                      choices={[
                        {
                          id: HomeSlideOnDevice.Mobile,
                          name: "Mobile",
                        },
                        {
                          id: HomeSlideOnDevice.Web,
                          name: "Web",
                        },
                        {
                          id: HomeSlideOnDevice.All,
                          name: "Cả hai",
                        },
                      ]}
                    />
                  </Box>
                  <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                    <SelectInput
                      fullWidth
                      source="type_slider"
                      validate={requiredValidate}
                      label="Chọn loại"
                      onChange={(e: any) => {
                        setModel(e.target.value);
                      }}
                      choices={_.keys(HomeSlideModel).map((key: any) => ({
                        id: (HomeSlideModel as any)[key],
                        name: (HomeSlideModelName as any)[
                          (HomeSlideModel as any)[key]
                        ],
                      }))}
                    />
                  </Box>
                  <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                    {model == HomeSlideModel.Course && (
                      // TODO:
                      <SelectSearch
                        className="select-search"
                        options={listCourse.map((item: any) => {
                          return {
                            value: item.id,
                            name: item.name,
                          };
                        })}
                        value={valueCourse ? valueCourse : undefined}
                        search
                        placeholder="Chọn khóa học"
                        onChange={(e) => setValueCourse(e)}
                      />
                    )}
                    {model == HomeSlideModel.Link && (
                      <>
                        <TextInput
                          label="Đường link"
                          validate={requiredValidate}
                          source="url"
                          resource="advertisements"
                          fullWidth
                          defaultValue={
                            isEditSlider ? dataSlider?.url : undefined
                          }
                        />
                      </>
                    )}
                    {model == HomeSlideModel.Post && (
                      <FormControl
                        variant="standard"
                        sx={{
                          m: 1,
                          minWidth: 500,
                        }}
                        style={{
                          backgroundColor: "#e5e5e5",
                        }}>
                        <InputLabel
                          id="demo-simple-select-standard-label"
                          style={{ paddingLeft: 5 }}>
                          Bài đăng
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          value={valuePost}
                          style={{
                            paddingLeft: 10,
                          }}
                          MenuProps={MenuProps}
                          onChange={handleChangePost}
                          renderValue={(value) => (
                            <span>{valuePost.title}</span>
                          )}>
                          {listPost?.map((item: any, index: number) => (
                            <MenuItem
                              data-id
                              value={item.title}
                              key={index}
                              style={{ whiteSpace: "normal" }}>
                              {item.title}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  </Box>
                </Box>
                <Box display={{ md: "block", lg: "flex" }}>
                  <Box flex={1} ml={{ xs: 0, sm: "0.5em" }} />

                  <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                    <BooleanInput
                      label="Ẩn/Hiện Slide"
                      source="status"
                      defaultValue={isEditSlider ? dataSlider?.status : 0}
                    />
                  </Box>
                  <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                    <NumberInput
                      source="sequenceNo"
                      label="Thứ tự"
                      min={0}
                      defaultValue={
                        isEditSlider ? dataSlider?.sequenceNo : undefined
                      }
                      validate={requiredValidate}
                    />
                  </Box>
                  <Box flex={0.8} ml={{ xs: 0, sm: "0.5em" }} />
                  <Box flex={0.5} ml={{ xs: 0, sm: "0.5em" }} />
                </Box>
                <Box display={{ md: "block", lg: "flex" }}>
                  <Box flex={0.2} ml={{ xs: 0, sm: "0.5em" }} />

                  <Box flex={0.75} ml={{ xs: 0, sm: "0.5em" }}>
                    <TextInput
                      label="Tiêu đề slides"
                      validate={requiredValidate}
                      source="title"
                      fullWidth
                      defaultValue={
                        isEditSlider ? dataSlider?.title : undefined
                      }
                    />
                  </Box>
                </Box>
              </CardContent>
              <Toolbar
                record={formProps.record}
                basePath={formProps.basePath}
                invalid={formProps.invalid}
                handleSubmit={() =>
                  hanldeSumitSlider(formProps.form.getState().values)
                }
                saving={formProps.saving}
                undoable={false}
                resource="homeslides"
              />
            </form>
          </Card>
        );
      }}
    />
  );
};
const Create = (props: any) => {
  return (
    <CreateAdmin undoable={false} {...props} actions={<ListActions />}>
      <Form createdBy={props?.permissions?.email} />
    </CreateAdmin>
  );
};

const requiredValidate = [required()];

export default Create;
