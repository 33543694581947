import React, { FC } from 'react';
import { Chip } from '@material-ui/core';
import { FieldProps } from '../types';
import { useTranslate } from 'react-admin';

interface Props extends FieldProps<any> {
  className?: string;
}

const getSex = (sex: string, translate: any): string => {
  return translate(`common.fields.${sex.toLowerCase()}`);
};

const SexField: FC<Props> = ({ record, className, source }) => {
  const translate = useTranslate();
  return record ? (
    <Chip
      className={className}
      label={
        source && record[source] ? getSex(record[source], translate) : "Unknown"
      }
    />
  ) : null;
};

export default SexField;
