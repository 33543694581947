import * as _ from "lodash";
import ListActions from "./ListActions";
import React from "react";
import { Box, Card, CardContent } from "@material-ui/core";
import {
  Create as CreateAdmin,
  FormWithRedirect,
  SelectInput,
  required,
  TextInput,
  Toolbar,
  ImageInput,
  NumberInput,
  DateTimeInput,
} from "react-admin";
import { RichTextInput } from "../components/RichTextInput";
import PreviewInputImage from "../components/PreviewInputImage";
import { ReferenceArrayInput } from "react-admin";
import { AutocompleteArrayInput } from "react-admin";

export const Form = (props: any) => {
  return (
    <FormWithRedirect
      {...props}
      render={(formProps: any) => (
        <Card>
          <form>
            <CardContent>
              <Box display={{ md: "block", lg: "flex" }}>
                <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                  <NumberInput
                    fullWidth={true}
                    source="minAge"
                    validate={requiredValidate}
                    resource="courseages"
                    label="tuổi >="
                  />
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                  <NumberInput
                    fullWidth={true}
                    source="maxAge"
                    validate={requiredValidate}
                    resource="courseages"
                    label="tuổi <="
                  />
                </Box>
                <Box flex={2} ml={{ xs: 0, sm: "0.5em" }}></Box>
              </Box>
              <Box>
                <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                  <ReferenceArrayInput
                    source="courseIds"
                    reference="courses"
                    resource="courses"
                    validate={requiredValidate}
                    filterToQuery={(searchText: any) => {
                      return _.isEmpty(_.trim(searchText))
                        ? null
                        : {
                            name: searchText,
                          };
                    }}
                  >
                    <AutocompleteArrayInput
                      optionText="name"
                      validate={requiredValidate}
                      fullWidth
                    />
                  </ReferenceArrayInput>
                </Box>
              </Box>
            </CardContent>
            <Toolbar
              record={formProps.record}
              basePath={formProps.basePath}
              invalid={formProps.invalid}
              handleSubmit={formProps.handleSubmit}
              saving={formProps.saving}
              undoable={false}
              resource="posts"
            />
          </form>
        </Card>
      )}
    />
  );
};
const Create = (props: any) => {
  return (
    <CreateAdmin undoable={false} {...props} actions={<ListActions />}>
      <Form />
    </CreateAdmin>
  );
};

const requiredValidate = [required()];

export default Create;
