import ListActions from "./ListActions";
import React from "react";
import * as _ from "lodash";
import { Edit } from "react-admin";
import { Form } from "./Create";
import StudentCourseInfo from "./StudentCourseInfo";

const WrapperForm = (props: any) => {
  const studentId = _.get(props.record, "id", undefined);
  return (
    <>
      <Form {...props} />
      {!!studentId && <StudentCourseInfo studentId={studentId} />}
    </>
  );
};
const MajorEdit = (props: any) => {
  return (
    <Edit component="div" undoable={false} {...props} actions={<ListActions />}>
      <WrapperForm />
    </Edit>
  );
};

export default MajorEdit;
