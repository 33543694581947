import { Box } from "@material-ui/core";
import React from "react";
import {
  ArrayInput,
  required,
  SimpleFormIterator,
  TextInput,
  useTranslate,
} from "react-admin";
import UploadAudio from "../components/UploadAudio";
import UploadImage from "../components/UploadImage";

export const Backtalk = (props: any) => {
  const translate = useTranslate();

  return (
    <>
      <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
        <ArrayInput
          source="answerMetas"
          resource="questions"
          label="Câu trả lời"
        >
          <SimpleFormIterator>
            <TextInput
              source="key"
              label={"Gợi ý"}
              resource="questions"
              required={requiredValidate}
            />
            <TextInput
              source="value"
              label={"Đáp án"}
              resource="questions"
              required={requiredValidate}
            />
            {/* <UploadImage source="imageUrl" label={"Ảnh"} resource="questions" /> */}
            <UploadAudio
              source="audioUrl"
              label={"Âm thanh"}
              resource="questions"
            />
          </SimpleFormIterator>
        </ArrayInput>
      </Box>
    </>
  );
};

const requiredValidate = [required()];

export default Backtalk;
