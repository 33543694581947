import { Box, Card, CardContent } from "@material-ui/core";
import React from "react";
import { NumberInput } from "react-admin";
import { ReferenceInput, AutocompleteInput } from "react-admin";
import {
  Create as CreateAdmin,
  FormWithRedirect,
  required,
  TextInput,
  Toolbar,
} from "react-admin";
import ListActions from "./ListActions";

export const Form = (props: any) => {
  return (
    <FormWithRedirect
      {...props}
      render={(formProps: any) => (
        <Card>
          <form>
            <CardContent>
              <Box display={{ md: "block", lg: "flex" }}>
                <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                  <TextInput
                    type="text"
                    label="Tên kỳ học"
                    fullWidth={true}
                    source="name"
                    validate={requiredValidate}
                    resource="periods"
                  />
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                  <NumberInput
                    label="Thứ tự"
                    fullWidth={true}
                    source="sequenceNo"
                    validate={requiredValidate}
                    resource="periods"
                  />
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                  <ReferenceInput
                    source="gradeId"
                    reference="grades"
                    resource="grades"
                    label="Lớp học"
                    disabled
                    validate={requiredValidate}
                  >
                    <AutocompleteInput
                      optionText="name"
                      validate={requiredValidate}
                      fullWidth
                    />
                  </ReferenceInput>
                </Box>
              </Box>
            </CardContent>
            <Toolbar
              record={formProps.record}
              basePath={formProps.basePath}
              invalid={formProps.invalid}
              handleSubmit={formProps.handleSubmit}
              saving={formProps.saving}
              undoable={false}
              resource="periods"
            />
          </form>
        </Card>
      )}
    />
  );
};
const Create = (props: any) => {
  return (
    <CreateAdmin undoable={false} {...props} actions={<ListActions />}>
      <Form />
    </CreateAdmin>
  );
};

const requiredValidate = [required()];

export default Create;
