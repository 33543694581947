import React, { FC } from "react";
import { FieldProps } from "../types";
import { required, SelectInput, useTranslate } from "react-admin";

interface Props extends FieldProps<any> {
  className?: string;
  fullWidth?: any;
  resource?: string;
  setObjectSend?: any;
  setChangeform?: any;
  disabled?: boolean;
}
const requiredValidate = [required()];
const ObjectSendInput: FC<Props> = ({
  record,
  className,
  source,
  setObjectSend,
  setChangeform,
  disabled,
  ...rest
}) => {
  const translate = useTranslate();

  return (
    <SelectInput
      validate={requiredValidate}
      label="Đối tượng gửi"
      {...rest}
      source={source}
      disabled={disabled}
      choices={[
        { id: 1, name: "Tất cả tài khoản" },
        { id: 0, name: "Lọc đối tượng" },
      ]}
      onChange={(e: any) => {
        setObjectSend(e.target.value);
        setChangeform(true);
      }}
    />
  );
};

export default ObjectSendInput;
