import * as _ from "lodash";
import React, { FC } from "react";
import {
  ChipField,
  Datagrid,
  List,
  TextField,
  useTranslate,
} from "react-admin";
import AvatarField from "../components/AvatarField";
import DateTimeField from "../components/DateTimeField";
import ListActions from "./ListActions";
import ListFilter from "./ListFilters";
import { NotificationFilterName } from "./Create";
import { Button } from "react-admin";
import { EditButton } from "react-admin";
import { CloneButton } from "react-admin";

const FilterTypeField: FC<any> = (props) => {
  return <span>{NotificationFilterName[_.get(props, "record.mode")]}</span>;
};
const MajorList: FC<any> = (props) => {
  const translate = useTranslate();
  return (
    <List
      {...props}
      title={translate("resources.posts.page.list")}
      actions={<ListActions />}
      filters={<ListFilter />}
    >
      <Datagrid optimized rowClick="edit">
        <TextField source="id" label="Id" />
        <AvatarField source="imageUrl" label="Ảnh" />
        <TextField source="title" label="Tiêu đề" />
        <TextField source="status_vn" label="Trạng thái" />
        <TextField source="send_type_vn" label="Loại thời gian gửi" />
        <TextField source="type_vn" label="Loại thông báo" />
        <DateTimeField source="created_at" label="Ngày tạo" />
        <CloneButton label="Copy" />
      </Datagrid>
    </List>
  );
};

export default MajorList;
