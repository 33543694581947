import ListActions from './ListActions';
import ListFilter from './ListFilters';
import MoneyField from '../components/MoneyField';
import React, { FC } from 'react';
import {
  Datagrid,
  List,
  TextField,
  useTranslate,
  ArrayField,
  SingleFieldList,
  ChipField,
} from "react-admin";

const MajorList: FC<any> = (props) => {
  const translate = useTranslate();
  return (
    <List
      {...props}
      title={translate("resources.cards.page.list")}
      actions={<ListActions />}
      filters={<ListFilter />}>
      <Datagrid optimized rowClick="edit">
        <TextField source="id" />
        <TextField source="displayName" />
        <MoneyField source="value" />
        <ArrayField source="courses">
          <SingleFieldList>
            <ChipField source="name" />
          </SingleFieldList>
        </ArrayField>
        <TextField source="expiredDate" />
      </Datagrid>
    </List>
  );
};

export default MajorList;
