import React from "react";
import { Edit as EditAdmin } from "react-admin";
import ListActions from "./ListActions";

const Form = (props: any) => {
  return (
    // <FormWithRedirect
    //   {...props}
    //   render={(formProps: any) => (
    //     <Card>
    //       <form>
    //         <CardContent>
    //           <Box display={{ md: "block", lg: "flex" }}>
    //             <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
    //               <TextInput
    //                 type="text"
    //                 fullWidth={true}
    //                 source="displayName"
    //                 validate={requiredValidate}
    //                 resource="cardcodes"
    //               />
    //             </Box>
    //             <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
    //               <NumberInput
    //                 source="value"
    //                 fullWidth={true}
    //                 validate={requiredValidate}
    //                 resource="cardcodes"
    //               />
    //             </Box>
    //             <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
    //               <ReferenceArrayInput
    //                 source="courseIds"
    //                 reference="courses"
    //                 resource="courses"
    //                 validate={requiredValidate}
    //                 filterToQuery={(searchText: any) => {
    //                   return _.isEmpty(_.trim(searchText))
    //                     ? null
    //                     : {
    //                         name: searchText,
    //                       };
    //                 }}>
    //                 <AutocompleteArrayInput
    //                   optionText="name"
    //                   validate={requiredValidate}
    //                   fullWidth
    //                 />
    //               </ReferenceArrayInput>
    //             </Box>
    //           </Box>
    //         </CardContent>
    //         <Toolbar
    //           record={formProps.record}
    //           basePath={formProps.basePath}
    //           invalid={formProps.invalid}
    //           handleSubmit={formProps.handleSubmit}
    //           saving={formProps.saving}
    //           undoable={false}
    //           resource="cardcodes"
    //         />
    //       </form>
    //     </Card>
    //   )}
    // />
    <></>
  );
};
const Edit = (props: any) => {
  return (
    <EditAdmin
      component="div"
      undoable={false}
      {...props}
      actions={<ListActions />}>
      <Form />
    </EditAdmin>
  );
};

export default Edit;
