import * as _ from "lodash";
import { useEffect, useReducer, useMemo } from "react";

const callApi = async (path: string) => {
  console.log(path);

  const token = localStorage.getItem("user_token");
  if (!token) return Promise.reject();
  return fetch(process.env.REACT_APP_API_BASE_URL + path, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

interface StateT<T> {
  loading: boolean;
  error: Error | null;
  data: T | null;
}

interface Action {
  type: string;
  payload?: any;
}

interface IOptions {
  defaultValue: any;
  condition?: boolean;
}

const initState = (_default: any): StateT<any> => ({
  loading: false,
  error: null,
  data: _default,
});

const reducer = (state: StateT<any>, action: Action) => {
  switch (action.type) {
    case "startFetch":
      return { ...state, loading: true };
    case "fetchSuccess":
      return { ...state, loading: false, data: action.payload };
    case "fetchError":
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export const useApiFetch = (
  path: string,
  when: any[] = [],
  _options?: IOptions
) => {
  const options = useMemo(
    () => _.merge({ defaultValue: null, condition: true }, _options),
    [_options]
  );

  const [{ data, error, loading }, dispatch] = useReducer(
    reducer,
    initState(options.defaultValue)
  );
  let mount = true;
  useEffect(() => {
    mount = true;
    return () => {
      mount = false;
    };
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      dispatch({ type: "startFetch" });
      try {
        const res = await callApi(path);

        if (!res.ok) {
          throw new Error("Đăng nhập lại");
        }
        const dataJSON = await res.json();
        const { status, data: _data, message } = dataJSON;

        if (status < 400) {
          if (mount) {
            dispatch({
              type: "fetchSuccess",
              payload: _data,
            });
          }
        } else {
          throw new Error(message);
        }
      } catch (err) {
        if (!mount) {
          return;
        }
        dispatch({ type: "fetchError", payload: error });
      }
    };
    if (!options.condition) return;
    fetchData();
  }, [JSON.stringify({ when, options })]);
  return { error, data, loading };
};
