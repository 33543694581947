import React, { ComponentType, FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { SvgIconProps } from '@material-ui/core/SvgIcon';

interface Props {
  bgColor: string;
  Icon: ComponentType<SvgIconProps>;
}

const useStyles = makeStyles({
  card: {
    display: "flex",
    justifyContent: "center",
    marginBottom: 10,
  },
  cardContent: {
    width: 80,
    borderRadius: 3,
  },
  icon: {
    textAlign: "center",
    width: 54,
    height: 54,
    padding: 14,
    color: "#fff",
  },
});

const CardIcon: FC<Props> = ({ Icon, bgColor }) => {
  const classes = useStyles();
  return (
    <div className={classes.card}>
      <div className={classes.cardContent} style={{ backgroundColor: bgColor }}>
        <Icon className={classes.icon} />
      </div>
    </div>
  );
};

export default CardIcon;
