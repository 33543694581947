import React, { FC, useEffect, useState } from "react";
import { FieldProps } from "../types";
import { required, SelectInput, useTranslate } from "react-admin";
import { SimpleForm } from "react-admin";
import { TextInput } from "react-admin";
import { Edit } from "react-admin";
import { ReferenceField } from "react-admin";
import { TextField } from "react-admin";
import { Labeled } from "react-admin";
import { Box } from "@material-ui/core";
import { NumberInput } from "react-admin";
import { Delete, Send } from "@material-ui/icons";
import { Button, Pagination } from "@mui/material";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { customHttpClientAdmin } from "../dataProvider";
import { useNotify } from "react-admin";

interface Props extends FieldProps<any> {
  className?: string;
  fullWidth?: any;
  resource?: string;
  setVarCheckTimepublic?: any;
  setStatusCard?: any;
  statusCard?: any;
  setChangeform?: any;
  handleApprove?: any;
  disabled?: boolean;
}

const requiredValidate = [required()];

const FormFillterUser: FC<Props> = ({
  record,
  className,
  source,
  setStatusCard,
  statusCard,
  setVarCheckTimepublic,
  setChangeform,
  disabled,
  ...rest
}) => {
  const notify = useNotify();

  const [filterAge, setfilterAge] = useState<boolean>(true);
  const [filterDateSign, setfilterDateSign] = useState<boolean>(true);
  const [filterStatusSign, setfilterStatusSign] = useState<boolean>(true);
  const [filterHSD, setfilterHSD] = useState<boolean>(true);
  const [dataTable, setDataTable] = useState([]);
  const [count, setCount] = React.useState<number>(0);
  const [page, setPage] = React.useState(1);
  const [ageFrom, setAgeFrom] = React.useState();
  const [ageTo, setAgeTo] = React.useState();
  const [days, setDays] = React.useState();
  const [account, setAccount] = React.useState();
  const [daysRegister, setDaysRegister] = React.useState();
  const [url, setUrl] = useState<string>(
    `/admin/notifications/recipient-list?page=1`
  );

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    setUrl(
      `/admin/notifications/recipient-list?page=${value}${
        ageFrom && filterAge ? `&age_from=${ageFrom}` : ""
      }${ageTo && filterAge ? `&age_to=${ageTo}` : ""}${
        account && filterStatusSign ? `&status_active=${account}` : ""
      }${
        daysRegister && filterDateSign ? `&days_register=${daysRegister}` : ""
      }${statusCard ? `&card_expiry_type=${statusCard}` : ""}`
    );
  };

  useEffect(() => {
    setUrl(
      `/admin/notifications/recipient-list?page=${page}${
        ageFrom ? `&age_from=${ageFrom}` : ""
      }${ageTo ? `&age_to=${ageTo}` : ""}${
        account && filterStatusSign ? `&status_active=${account}` : ""
      }${
        daysRegister && filterDateSign ? `&days_register=${daysRegister}` : ""
      }${statusCard && filterHSD ? `&card_expiry_type=${statusCard}` : ""}${
        days && filterHSD ? `&card_expiry_days=${days}` : ""
      }`
    );
  }, [page]);

  useEffect(() => {
    setUrl(
      `/admin/notifications/recipient-list?page=1${
        ageFrom && filterAge ? `&age_from=${ageFrom}` : ""
      }${ageTo && filterAge ? `&age_to=${ageTo}` : ""}${
        account && filterStatusSign ? `&status_active=${account}` : ""
      }${
        daysRegister && filterDateSign ? `&days_register=${daysRegister}` : ""
      }${statusCard && filterHSD ? `&card_expiry_type=${statusCard}` : ""}${
        days && filterHSD ? `&card_expiry_days=${days}` : ""
      }`
    );
  }, [
    ageFrom,
    ageTo,
    account,
    daysRegister,
    filterAge,
    filterDateSign,
    statusCard,
    filterHSD,
    days,
    filterStatusSign,
  ]);

  useEffect(() => {
    if (url !== `/admin/notifications/recipient-list?page=1`) handleApprove();
  }, [page]);

  const handleApprove = async () => {
    customHttpClientAdmin(url, {
      method: "GET",
    })
      .then((res: any) => res.json)
      .then((success: any) => {
        if (success.total === 0) {
          notify("Không tìm thấy đối tượng được yêu cầu!");
        } else if (page < 2 && count !== success.total) {
          notify("Danh sách người dùng nhận được thông báo", "success");
        }
        setDataTable(success.data);
        setCount(success.total);
      })
      .catch((error: any) => {
        console.log(error, "error");
      });
  };

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 90 },
    {
      field: "fullname",
      headerName: "Full name",
      width: 150,
    },
    {
      field: "email",
      headerName: "Email",
      width: 150,
    },
    {
      field: "dob",
      headerName: "dob",
      width: 110,
    },
    {
      field: "telephone",
      headerName: "Telephone",
      width: 160,
    },
  ];
  const rows = dataTable;

  return (
    <>
      {filterAge && (
        <Box display={{ md: "block", lg: "flex" }}>
          <Box
            flex={1}
            ml={{ xs: 0, sm: "0.5em" }}
            style={{ display: "flex", alignItems: "center" }}
          >
            <Button
              aria-label="delete"
              size="small"
              onClick={() => setfilterAge(false)}
              disabled={disabled}
            >
              <Delete fontSize="inherit" color="action" />
            </Button>
            <span>Lọc Theo độ tuổi:</span>
          </Box>
          <Box
            flex={2}
            ml={{ xs: 0, sm: "0.5em" }}
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              columnGap: "3%",
            }}
          >
            <NumberInput
              label="Từ"
              source="age_from"
              min={3}
              max={100}
              onChange={(e: any) => setAgeFrom(e.target.value)}
              disabled={disabled}
            />
            -
            <NumberInput
              label="Đến"
              source="age_to"
              min={3}
              max={100}
              onChange={(e: any) => setAgeTo(e.target.value)}
              disabled={disabled}
            />
          </Box>
        </Box>
      )}
      {filterDateSign && (
        <Box display={{ md: "block", lg: "flex" }}>
          <Box
            flex={1}
            ml={{ xs: 0, sm: "0.5em" }}
            style={{ display: "flex", alignItems: "center" }}
          >
            <Button
              aria-label="delete"
              size="small"
              onClick={() => setfilterDateSign(false)}
              disabled={disabled}
            >
              <Delete fontSize="inherit" color="action" />
            </Button>
            <span>Số ngày từ ngày đăng ký tài khoản:</span>
          </Box>
          <Box flex={2} ml={{ xs: 0, sm: "0.5em" }}>
            <NumberInput
              label="Ngày"
              source="days_register"
              min={3}
              onChange={(e: any) => setDaysRegister(e.target.value)}
              disabled={disabled}
            />
          </Box>
        </Box>
      )}
      {filterStatusSign && (
        <Box display={{ md: "block", lg: "flex" }}>
          <Box
            flex={1}
            ml={{ xs: 0, sm: "0.5em" }}
            style={{ display: "flex", alignItems: "center" }}
          >
            <Button
              aria-label="delete"
              size="small"
              onClick={() => setfilterStatusSign(false)}
              disabled={disabled}
            >
              <Delete fontSize="inherit" color="action" />
            </Button>
            <span>Lọc theo Tình trạng kích hoạt:</span>
          </Box>
          <Box
            flex={2}
            ml={{ xs: 0, sm: "0.5em" }}
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              columnGap: "2%",
            }}
          >
            <SelectInput
              label="Tài khoản"
              source="status_active"
              disabled={disabled}
              onChange={(e: any) => setAccount(e.target.value)}
              choices={[
                {
                  id: "downloaded_not_logged_in",
                  name: "Đã tải app, chưa đăng nhập",
                },
                {
                  id: "card_not_activated",
                  name: "Đã có tài khoản, chưa kích hoạt thẻ",
                },
                { id: "actived_test_card", name: "Đã kích hoạt mã học thử" },
                {
                  id: "actived_premium_card",
                  name: "Đã kích hoạt thẻ trả phí",
                },
              ]}
            />
          </Box>
        </Box>
      )}
      {filterHSD && (
        <Box display={{ md: "block", lg: "flex" }}>
          <Box
            flex={1}
            ml={{ xs: 0, sm: "0.5em" }}
            style={{ display: "flex", alignItems: "center" }}
          >
            <Button
              aria-label="delete"
              size="small"
              onClick={() => setfilterHSD(false)}
              disabled={disabled}
            >
              <Delete fontSize="inherit" color="action" />
            </Button>
            <span>Lọc tài khoản theo HSD thẻ:</span>
          </Box>
          <Box
            flex={2}
            ml={{ xs: 0, sm: "0.5em" }}
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              columnGap: "3%",
            }}
          >
            <SelectInput
              label="Thẻ"
              source="card_expiry_type"
              disabled={disabled}
              choices={[
                { id: "expiry_date", name: "Còn hạn" },
                { id: "expiration_soon", name: "Sắp hết hạn" },
                { id: "out_of_date", name: "Đã hết hạn" },
              ]}
              onChange={(e: any) => {
                setStatusCard(e.target.value);
                setChangeform(true);
              }}
            />
            {statusCard !== "" && statusCard !== "expiry_date" && (
              <NumberInput
                label="Ngày"
                source="card_expiry_days"
                min={1}
                onChange={(e: any) => setDays(e.target.value)}
              />
            )}
          </Box>
        </Box>
      )}
      <Box display={{ md: "block", lg: "flex" }}>
        <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
          <Button
            aria-label="delete"
            size="small"
            endIcon={<Send />}
            variant="contained"
            onClick={handleApprove}
            disabled={disabled}
          >
            Áp dụng
          </Button>
        </Box>
      </Box>
      {count > 0 && (
        <div
          style={{
            height: 500,
            width: "100%",
            margin: "2vh 0",
          }}
        >
          <DataGrid
            columns={columns}
            rows={rows}
            pageSize={20}
            hideFooterPagination
            pagination
          />
          {Math.ceil(count / 20) > 1 && (
            <Pagination
              count={Math.ceil(count / 20)}
              size="small"
              onChange={handleChange}
              variant="outlined"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                transform: "translateY(-120%)",
              }}
            />
          )}
        </div>
      )}
    </>
  );
};

export default FormFillterUser;
