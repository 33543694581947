import { Box, Card, CardContent } from "@material-ui/core";
import * as _ from "lodash";
import React from "react";
import {
  Create as CreateAdmin,
  FormWithRedirect,
  NumberInput,
  required,
  SelectInput,
  Toolbar,
} from "react-admin";
import UploadImage from "../components/UploadImage";
import ListActions from "./ListActions";
import { TextInput } from "react-admin";
import { RichTextInput } from "../components/RichTextInput";
import { translate } from "react-admin";
import { DateTimeInput } from "react-admin";
import { SaveButton } from "react-admin";
import { DeleteButton } from "react-admin";
import { customHttpClientAdmin } from "../dataProvider";
import moment from "moment";
import { useNotify } from "react-admin";
import { useRedirect } from "react-admin";

export enum HomeSlideOnDevice {
  Web = 1,
  Mobile = 2,
  All = 3,
}
export enum SliderStatus {
  Hide = 0,
  Show = 1,
}

type DataFormSubmit = {
  service: string;
  createdBy: string;
  sequenceNo: number;
  title: string;
  imageUrl: string;
  status: number;
  timeStart: string;
  timeEnd: string;
  description: string;
  device: number;
};

export const Form = (props: any) => {
  const noti = useNotify();
  const redirect = useRedirect();

  return (
    <FormWithRedirect
      {...props}
      render={(formProps: any) => {
        const handleSubmit = (data: DataFormSubmit) => {
          const dateTime = moment.utc().format("YYYY-MM-DD HH:mm:ss");
          const timeStart = moment
            .utc(data.timeStart)
            .format("YYYY-MM-DD HH:mm:ss");
          const timeEnd = moment
            .utc(data.timeEnd)
            .format("YYYY-MM-DD HH:mm:ss");

          const start = moment(data.timeStart)
            .add(0, "hour")
            .format("YYYY-MM-DD HH:mm:ss");

          const end = moment(data.timeEnd)
            .add(0, "hour")
            .format("YYYY-MM-DD HH:mm:ss");

          if (data.sequenceNo < 0) {
            noti("Thứ tự không được để trống hoặc nhỏ hơn 0", "warning");
            return;
          }
          if (dateTime > timeStart) {
            noti(
              "Thời gian hiện thị không được nhỏ hơn thời gian hiện tại",
              "warning"
            );
            return;
          }
          if (timeStart > timeEnd) {
            noti(
              "Thời gian hiện thị không được lớn hơn thời gian kết thúc",
              "warning"
            );
            return;
          }
          customHttpClientAdmin(
            `/admin/slideshow${
              formProps.isEdit ? `/${formProps.record.id}` : ""
            }`,
            {
              method: formProps.isEdit ? "PATCH" : "POST",
              body: JSON.stringify({
                ...data,
                timeEnd: end,
                timeStart: start,
              }),
            }
          )
            .then((res: any) => res.json)
            .then((success: any) => {
              console.log(success, "success");
              redirect("/slideshow");
              noti("Thành công", "log");
            })
            .catch((error: any) => {
              console.log(error, "error");
            });
        };
        return (
          <Card>
            <form>
              <CardContent>
                <Box display={{ md: "block", lg: "flex" }}>
                  <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                    <NumberInput
                      min={0}
                      source="sequenceNo"
                      label="Số thứ tự"
                      validate={requiredValidate}
                      resource="slideshow"
                    />
                  </Box>
                  <Box flex={1} ml={{ xs: 0, sm: "0.9em" }}>
                    <TextInput
                      type="text"
                      fullWidth={true}
                      label="Tiêu đề"
                      source="title"
                      validate={requiredValidate}
                      resource="slideshow"
                    />
                  </Box>
                  <Box display="none">
                    <TextInput
                      type="text"
                      defaultValue="arena"
                      source="service"
                      resource="slideshow"
                    />
                    <TextInput
                      type="text"
                      defaultValue={props?.createdBy}
                      source="createdBy"
                      resource="slideshow"
                    />
                  </Box>
                  <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                    <UploadImage source="imageUrl" />
                  </Box>
                </Box>
                <Box display={{ md: "block", lg: "flex" }}>
                  <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                    <SelectInput
                      fullWidth
                      source="status"
                      validate={requiredValidate}
                      label="Trạng thái"
                      choices={[
                        {
                          id: SliderStatus.Hide,
                          name: "Ẩn",
                        },
                        {
                          id: SliderStatus.Show,
                          name: "Kích hoạt",
                        },
                      ]}
                      resource="slideshow"
                    />
                  </Box>
                  <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                    <DateTimeInput
                      source="timeStart"
                      label="Thời gian hiện thị"
                      validate={requiredValidate}
                      resource="slideshow"
                      // onChange={(e) => setIsChangeTimeStart(e.target.value)}
                    />
                  </Box>
                  <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                    <DateTimeInput
                      source="timeEnd"
                      label="Thời gian kết thúc"
                      validate={requiredValidate}
                      resource="slideshow"
                    />
                  </Box>
                  <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                    <SelectInput
                      fullWidth
                      source="device"
                      validate={requiredValidate}
                      label="Chọn thiết bị"
                      choices={[
                        {
                          id: HomeSlideOnDevice.Mobile,
                          name: "Mobile",
                        },
                        {
                          id: HomeSlideOnDevice.Web,
                          name: "Web",
                        },
                        {
                          id: HomeSlideOnDevice.All,
                          name: "Cả hai",
                        },
                      ]}
                      resource="slideshow"
                    />
                  </Box>
                </Box>
                <Box display={{ md: "block", lg: "flex" }}>
                  <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                    <RichTextInput
                      label="Thông báo script"
                      // resource="questions"
                      source="description"
                      isScript={true}
                      placeholder={translate(
                        "resources.courses.fields.notification"
                      )}
                      validate={requiredValidate}
                      resource="slideshow"
                    />
                  </Box>
                </Box>
              </CardContent>

              <Toolbar
                record={formProps.record}
                basePath={formProps.basePath}
                invalid={formProps.invalid}
                handleSubmit={formProps.handleSubmit}
                saving={formProps.saving}
                undoable={false}
                resource="slideshow">
                <SaveButton
                  saving={formProps.saving}
                  handleSubmitWithRedirect={() =>
                    handleSubmit(formProps.form.getState().values)
                  }
                  // handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                  undoable={false}
                  // onClick={handleSubmitQuestion}
                />
                {formProps.isEdit && <DeleteButton undoable={false} />}
              </Toolbar>
            </form>
          </Card>
        );
      }}
    />
  );
};
const Create = (props: any) => {
  return (
    <CreateAdmin undoable={false} {...props} actions={<ListActions />}>
      <Form createdBy={props?.permissions?.email} />
    </CreateAdmin>
  );
};

const requiredValidate = [required()];

export default Create;
