import React from "react";
import {
  required,
  TextInput,
  useTranslate,
  useInput,
} from "react-admin";

export const MonoAnswer = (props: any) => {
  const translate = useTranslate();
  const { input } = useInput(props);
//   console.log("inputProps", valueAns);
  return (
    <>
      <TextInput
        label="Đáp án"
        source="answerMetas[0].value"
        type="text"
        fullWidth
        // required={requiredValidate}
      />
    </>
  );
};

const requiredValidate = [required()];

export default MonoAnswer;
