import React, { FC } from "react";
import { Filter, NumberInput, TextInput, useTranslate } from "react-admin";
import "./index.css";
const Categories = (props: any) => {
  const translate = useTranslate();
  return (
    <NumberInput
      id="categories"
      source="categories.id"
      label={translate("resources.courses.fields.categories")}
    />
  );
};

const ListFilter: FC<any> = (props) => {
  return (
    <Filter {...props}>
      <NumberInput source="id" />
      <TextInput source="name" />
      <NumberInput source="sequenceNo" />
      <Categories source="categories" />
    </Filter>
  );
};

export default ListFilter;
