import React from "react";
import { Box } from "@material-ui/core";
import {
  ArrayInput,
  BooleanInput,
  required,
  SimpleFormIterator,
  TextInput,
} from "react-admin";
import UploadImage from "../components/UploadImage";
import UploadAudio from "../components/UploadAudio";

export const MultipleChoice = (props: any) => {
  return (
    <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
      <ArrayInput source={"answerMetas"} resource="questions">
        <SimpleFormIterator>
          <TextInput
            source="value"
            resource="questions"
            validate={requiredValidate}
          />
          <UploadImage source="imageUrl" resource="questions" />
          <UploadAudio source="audioUrl" resource="questions" />
          <BooleanInput source="isCorrect" resource="questions" />
        </SimpleFormIterator>
      </ArrayInput>
    </Box>
  );
};

const requiredValidate = [required()];

export default MultipleChoice;
