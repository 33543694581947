import {
  Datagrid,
  List,
  NumberField,
  TextField,
  useTranslate,
} from "react-admin";
import React, { FC } from "react";

import ListActions from "./ListActions";
import ListFilter from "./ListFilters";
import { BooleanField } from "react-admin";
import DateTimeField from "../components/DateTimeField";
import AvatarField from "../components/AvatarField";

const MajorList: FC<any> = (props) => {
  const translate = useTranslate();
  return (
    <List
      {...props}
      title={"Quản lý thông báo lỗi hệ thống"}
      actions={<ListActions />}
      filters={<ListFilter />}
    >
      <Datagrid optimized rowClick="edit">
        <AvatarField source="imageUrl" label="Ảnh bảo trì" />
        <TextField source="description" label="Mô tả" />
        <BooleanField source="isMaintaining" label="Bật bảo trì" />
        <DateTimeField source="createdAt" label="Ngày tạo" />
        <DateTimeField source="updatedAt" label="Ngày cập nhật" />
      </Datagrid>
    </List>
  );
};

export default MajorList;
