import React from "react";
import { Box } from "@material-ui/core";
import {
  ArrayInput,
  BooleanInput,
  required,
  SimpleFormIterator,
  TextInput,
} from "react-admin";
import UploadImage from "../components/UploadImage";
import UploadAudio from "../components/UploadAudio";

const GameTrain = (props: any) => {
  return (
    <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
      <ArrayInput
        source="answerMetas"
        resource="questions"
        label="Danh sách giá trị trong ô và đáp án"
      >
        <SimpleFormIterator>
          <UploadAudio
            source="audioUrl"
            label={"Ảnh trả lời"}
            resource="questions"
          />
          <UploadImage
            source="imageUrl"
            label={"Ảnh trả lời"}
            resource="questions"
          />
          <TextInput
            source="value"
            resource="questions"
            label="Trả lời"
            validate={requiredValidate}
          />
        </SimpleFormIterator>
      </ArrayInput>
    </Box>
  );
};

const requiredValidate = [required()];

export default GameTrain;
