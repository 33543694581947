import React, { FC } from "react";
import {
  ArrayField,
  Datagrid,
  List,
  ReferenceField,
  SingleFieldList,
  TextField,
  useTranslate,
  NumberField,
} from "react-admin";
import ChipField from "../components/ChipField";
import DateTimeField from "../components/DateTimeField";
import ListActions from "./ListActions";
import ListFilter from "./ListFilters";

const MajorList: FC<any> = (props) => {
  const translate = useTranslate();
  return (
    <List
      {...props}
      title={translate("resources.courses.page.list")}
      actions={<ListActions />}
      filters={<ListFilter />}
    >
      <Datagrid optimized rowClick="edit">
        <TextField source="id" />
        <TextField source="name" />
        <TextField source="unitCount" />
        <TextField source="duration" />
        <NumberField source="sequenceNo" />
        <ArrayField source="categories">
          <SingleFieldList>
            <ChipField source="name" />
          </SingleFieldList>
        </ArrayField>
        <ReferenceField
          source="lectureId"
          reference="users"
          label={translate("resources.users.fields.createdBy")}
        >
          <ChipField source="email" size="small" />
        </ReferenceField>
        <DateTimeField source="createdAt" />
      </Datagrid>
    </List>
  );
};

export default MajorList;
