import ListActions from "./ListActions";
import React, { useEffect, useState } from "react";
import { Edit as EditAdmin } from "react-admin";
import { Form } from "./Create";
import { customHttpClientAdmin } from "../dataProvider";
import { SaveContextProvider } from "react-admin";

const Edit = (props: any) => {
  const [dataPopup, setDataPopup] = useState<any>(null);
  const [listPro, setListPro] = useState<string[]>([]);
  const [
    selectedElementDistristProvinces,
    setSelectedElementDistristProvinces,
  ] = useState<string[]>([]);

  const initData = async () => {
    try {
      await customHttpClientAdmin(
        `/admin/advertisements/${props?.match.params.id}`,
        {
          method: "GET",
        }
      )
        .then((res: any) => res.json)
        .then((response: any) => {
          setDataPopup(response);
        })
        .catch((error: any) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  // console.log(listPro, "listPro");

  useEffect(() => {
    customHttpClientAdmin("/school/provinces", {
      method: "POST",
    })
      .then((res: any) => res.json)
      .then((response: any) => {
        if (response.status === 200) {
          setListPro(response.data);
        } else console.log(response);
      })
      .catch((error: any) => {
        console.log(error);
      });
  }, []);

  const dataProvinces = async () => {
    let initSelectedProvinces = dataPopup?.dataSliderDetail?.area.map(
      (item: any) => {
        const selectedProvince = listPro.find(
          (province: any) => province.id == item.province
        );
        return selectedProvince;
      }
    );
    if (initSelectedProvinces.length > 0) {
      const updatedList = initSelectedProvinces?.map(
        (item: any, index: number) => {
          let dataPro: string[] = [];
          customHttpClientAdmin(`/school/${item?.id}/districts`, {
            method: "POST",
          })
            .then((res: any) => res.json)
            .then((response: any) => {
              if (response.status === 200) {
                const distrist = dataPopup?.dataSliderDetail?.area.map(
                  (i: any) => i.district
                );
                const data =
                  dataPopup?.dataSliderDetail?.area.length > 0
                    ? JSON.parse(distrist[index])
                    : "";

                const filteredItems: any = response.data?.filter((item: any) =>
                  data?.includes(item.id)
                );
                dataPro.push(filteredItems);
              } else console.log(response);
            })
            .catch((error: any) => {
              console.log(error);
            });

          return {
            id: index,
            provices: item?.id || [],
            provincesName: item?.name,
            defaultDistrist: dataPro,
            isRemove: true,
            arrayDistrist: [],
          };
        }
      );
      setSelectedElementDistristProvinces(updatedList);
    }
  };

  useEffect(() => {
    if (dataPopup?.dataSliderDetail?.area.length > 0) {
      dataPopup?.dataSliderDetail?.area.map((i: any) => {
        if (i.type_area == 3) {
          dataProvinces();
        }
      });
    }
  }, [listPro, dataPopup]);

  useEffect(() => {
    initData();
  }, []);

  if (!dataPopup && listPro?.length > 0) {
    return <div>Loading...</div>;
  } else {
    return (
      <Form
        dataSlider={dataPopup?.dataSlider}
        dataSliderDetail={dataPopup?.dataSliderDetail}
        idPopup={props?.match?.params.id}
        isEditPopup
        createdBy={props?.permissions?.email}
        selectedElementDistristProvinces={selectedElementDistristProvinces}
      />
    );
  }
};

export default Edit;
