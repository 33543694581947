import { Box, Card, CardContent } from "@material-ui/core";
import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import {
  AutocompleteArrayInput,
  BooleanInput,
  Create as CreateAdmin,
  FormWithRedirect,
  ImageInput,
  NumberInput,
  ReferenceArrayInput,
  required,
  SelectInput,
  TextInput,
  Toolbar,
  useTranslate,
  useRedirect,
  SaveButton,
} from "react-admin";
import PreviewInputImage from "../components/PreviewInputImage";
import ListActions from "./ListActions";
import { customHttpClient, customHttpClientAdmin } from "../dataProvider";
import { Editor } from "@tinymce/tinymce-react";
import { useNotify } from "react-admin";

export enum CourseVisible {
  All = 1,
  Mobile = 2,
  Web = 3,
}
type Languege = {
  code: string;
  name: string;
};

export enum CourseScope {
  Open = "open",
  Limit = "limit",
}

export type PropsData = {
  benefitDescriptions?: any;
  canComment?: number;
  categories?: any;
  categoryIds?: any;
  commentCount?: number;
  createdAt?: string;
  description?: string;
  duration?: number;
  forChildren?: boolean;
  goalDescriptions?: any;
  highlight?: number;
  id?: number;
  imageUrl?: any;
  isActive?: number | boolean;
  isPlus?: number | boolean;
  language?: string;
  lectureId?: number;
  level?: number;
  name?: string;
  newPrice?: number | undefined;
  oldPrice?: number | undefined;
  quizzCount?: number;
  requirementDescriptions?: any;
  scope?: string;
  sequenceNo?: number;
  sequenceNoPlus?: number;
  slug?: string;
  status?: string;
  studentCount?: string;
  type?: string;
  unitCount?: number;
  updatedAt?: string;
  updatedBy?: number;
  visibleOn?: number;
  isComingSoon?:number
};

export const Form = (props: any) => {
  console.log("propsForm", props?.record?.imageUrl);
  const translate = useTranslate();
  const redirect = useRedirect();
  const notify = useNotify();
  const [imgLink, setImgLink] = useState<any>(props?.record?.imageUrl || "");
  const [loadImg, setloadImg] = useState<boolean>(false);

  const [languages, setLanguages] = useState([{ id: "", name: "" }]);
  const [dataCourseDetail, setDataCourseDetail] = useState<PropsData>();
  const [coursePlus, setCoursePlus] = useState<number>(0);
  const [isComingSoon, setIsComingSoon] = useState<number>(0);
  const [description, setDescription] = useState<string>("");
  const [oldPrice, setOldPrice] = useState<number>(0);
  const [newPrice, setNewPrice] = useState<number>(0);
  const [sequeNo, setSequeNo] = useState<number>(0);
  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    customHttpClientAdmin(`/admin/languages/list`, {
      method: "GET",
    })
      .then((res: any) => res.json)
      .then((success: any) => {
        const data = success.data?.map((item: Languege) => {
          return {
            id: item.code,
            name: item.name,
          };
        });
        setLanguages(data);
      })
      .catch((error: any) => {
        console.log(error, "error");
      });
    // call api chi tiet khoa hoc

    props.isEdit &&
      customHttpClientAdmin(`/admin/courses/${props.record.id}`, {
        method: "GET",
      })
        .then((res: any) => res.json)
        .then((success: any) => {
          console.log('successData',success)
          setDataCourseDetail(success?.data);
          setCoursePlus(success?.data.isPlus);
          setIsComingSoon(success?.data.isComingSoon)
          setDescription(success?.data.description);
          setSequeNo(success?.data.sequenceNo);
        })
        .catch((error: any) => {
          console.log(error, "error");
        });
  };

  const uploadImage = useCallback((file: any) => {
    if (file) {
      setloadImg(true);
      let formdata = new FormData();
      formdata.append("file", file);

      customHttpClient(`/api/upload/image`, {
        method: "POST",
        body: formdata,
      })
        .then((res: any) => {
          if (res?.body) {
            const resImage = JSON.parse(res?.body);
            const img = _.get(resImage, "data.url");
            setImgLink(img);
            setloadImg(false);
            notify("upload image success");
          }
        })
        .catch((error: any) => {
          setloadImg(false);
          notify("Ảnh vượt quá dung lượng cho phép", "error");
        });
    } else {
      setImgLink("");
    }
  }, []);

  return (
    <FormWithRedirect
      {...props}
      initialValues={{ visibleOn: CourseVisible.All }}
      render={(formProps: any) => {
        const handleOnsubmitCourses = async (data: PropsData) => {
          if (oldPrice < 0 || newPrice < 0) {
            notify(`Giá phải lớn hơn 0.`, {
              type: "warning",
            });
          }
          if (Number(data.sequenceNoPlus) < 0) {
            notify(`Thứ tự nhập vào phải lớn hơn hoặc bằng 0`, {
              type: "warning",
            });
          }
          if (data.isPlus) {
            if (Number(data.sequenceNo) < 0) {
              notify(`Thứ tự nhập vào phải lớn hơn hoặc bằng 0`, {
                type: "warning",
              });
              return;
            }
          }

          // const toBase64 = (file: Blob) =>
          //   new Promise((resolve, reject) => {
          //     const reader = new FileReader();
          //     reader.readAsDataURL(file);
          //     reader.onload = () => resolve(reader.result);
          //     reader.onerror = (error) => reject(error);
          //   });
          // const image = data?.imageUrl?.rawFile
          //   ? await toBase64(data?.imageUrl?.rawFile)
          //   : data?.imageUrl;

          const dataObject = {
            ...data,
            imageUrl: imgLink,
            description: description,
            sequenceNo: data.sequenceNo === null ? "" : data.sequenceNo,
            level: data.level == undefined ? "" : data.level,
          };

          const callApi = () => {
            customHttpClientAdmin(
              `/admin/courses/${
                formProps.isEdit ? formProps.record.id + "/update" : "create"
              }`,
              {
                method: "POST",
                body: JSON.stringify(dataObject),
              }
            )
              .then((res: any) => res.json)
              .then((success: any) => {
                if (success.status === 200) {
                  redirect("/courses");
                  notify(`Thành công`);
                }
              })
              .catch((error: any) => {
                notify(`Vui lòng thử lại sau.`, {
                  type: "error",
                });
              });
          };

          if (data.isPlus) {
            if (
              data.language &&
              data.name &&
              data.categoryIds &&
              data.type &&
              data.status &&
              Number(data.sequenceNoPlus) > 0 &&
              Number(data.oldPrice) > 0 &&
              Number(data.newPrice) > 0
            ) {
              callApi();
            }
          } else {
            if (sequeNo < 0) {
              notify(`Thứ tự nhập vào phải lớn hơn hoặc bằng 0`, {
                type: "warning",
              });
              return;
            }
            if (
              data.language &&
              data.name &&
              data.categoryIds &&
              data.type &&
              data.status
            ) {
              callApi();
            }
          }
        };

        const onClickCoursePlus = () => {
          if (coursePlus === 0) {
            setCoursePlus(1);
          } else {
            setCoursePlus(0);
          }
        };

        const onClickComingSoon = () => {
          if (isComingSoon === 0) {
            setIsComingSoon(1);
          } else {
            setIsComingSoon(0);
          }
        };

        return (
          <Card>
            <form>
              <CardContent>
                <Box
                  display={{ md: "block", lg: "flex" }}
                  style={{ alignItems: "center" }}
                >
                  <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                    <TextInput
                      type="text"
                      source="name"
                      label="Tên khóa học"
                      defaultValue={dataCourseDetail?.name}
                      fullWidth
                      validate={requiredValidate}
                      resource="courses"
                    />
                  </Box>
                  <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                    <SelectInput
                      fullWidth
                      source="status"
                      validate={requiredValidate}
                      resource="units"
                      choices={[
                        { id: "publish", name: "publish" },
                        { id: "private", name: "draft" },
                      ]}
                    />
                  </Box>
                  <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                    <TextInput
                      fullWidth
                      source="level"
                      label="Phân loại trình độ"
                      resource="units"
                      defaultValue={
                        Number(dataCourseDetail?.level) > 0
                          ? dataCourseDetail?.level
                          : null
                      }
                    />
                  </Box>
                  <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                    <BooleanInput
                      source="isComingSoon"
                      label="Comming Soon"
                      defaultValue={dataCourseDetail?.isComingSoon}
                      fullWidth
                      resource="courses"
                      onClick={onClickComingSoon}
                    />
                  </Box>
                  <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                    <ImageInput
                      source="imageUrl"
                      resource="courses"
                      multiple={false}
                      accept="image/*"
                      maxSize={2 * 1024 * 1024}
                      placeholder={<p>Kéo thả ảnh vào đây</p>}
                      onChange={(e: any) => {
                        uploadImage(e);
                      }}
                    >
                      <PreviewInputImage
                        source="imageUrl"
                        title="title"
                        loading={loadImg}
                      />
                    </ImageInput>
                  </Box>
                </Box>
                <Box
                  display={{ md: "block", lg: "flex" }}
                  style={{ alignItems: "center" }}
                >
                  <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                    <SelectInput
                      source="type"
                      fullWidth
                      validate={requiredValidate}
                      choices={[
                        { id: "VIP", name: "Chỉ khi kích hoạt thẻ" },
                        { id: "OPEN", name: "Mở" },
                      ]}
                      resource="courses"
                    />
                  </Box>
                  <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                    <ReferenceArrayInput
                      source="categoryIds"
                      reference="categories"
                      resource="courses"
                      validate={requiredValidate}
                      // filter={{ "parentId||$notnull": true }}
                      filterToQuery={(searchText: any) => {
                        return _.isEmpty(_.trim(searchText))
                          ? null
                          : {
                              name: searchText,
                            };
                      }}
                    >
                      <AutocompleteArrayInput
                        optionText="name"
                        validate={requiredValidate}
                        fullWidth
                      />
                    </ReferenceArrayInput>
                  </Box>

                  <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                    <NumberInput
                      source="sequenceNo"
                      fullWidth
                      resource="courses"
                      min={0}
                      defaultValue={
                        Number(dataCourseDetail?.sequenceNo) > 0
                          ? dataCourseDetail?.sequenceNo
                          : ""
                      }
                      onChange={(e: any) => setSequeNo(e.target.value)}
                    />
                  </Box>
                  <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                    <BooleanInput
                      source="canComment"
                      fullWidth
                      resource="courses"
                      defaultValue={dataCourseDetail?.canComment}
                    />
                  </Box>
                </Box>
                <Box
                  display={{ md: "block", lg: "flex" }}
                  style={{ alignItems: "center" }}
                >
                  <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                    <SelectInput
                      source="visibleOn"
                      fullWidth
                      resource="courses"
                      choices={[
                        { id: CourseVisible.All, name: "Cả 2" },
                        { id: CourseVisible.Mobile, name: "Mobile only" },
                        { id: CourseVisible.Web, name: "Web only" },
                      ]}
                    />
                  </Box>
                  <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                    <SelectInput
                      source="scope"
                      fullWidth
                      label="Quyền truy cập"
                      resource="courses"
                      choices={[
                        { id: CourseScope.Open, name: "Mở" },
                        { id: CourseScope.Limit, name: "Hạn chế" },
                      ]}
                    />
                  </Box>
                  <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                    <SelectInput
                      fullWidth
                      source="language"
                      label="Ngôn ngữ"
                      validate={requiredValidate}
                      resource="courses"
                      choices={languages}
                      defaultValue={dataCourseDetail?.language}
                    />
                  </Box>
                  <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                    <BooleanInput
                      source="forChildren"
                      fullWidth
                      resource="courses"
                      defaultValue={dataCourseDetail?.forChildren}
                    />
                  </Box>
                  <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                    <BooleanInput
                      source="isPlus"
                      label="Khóa Plus"
                      defaultValue={dataCourseDetail?.isPlus}
                      fullWidth
                      resource="courses"
                      onClick={onClickCoursePlus}
                    />
                  </Box>
                </Box>
                {/* --------- */}
                {coursePlus === 1 && (
                  <Box
                    display={{ md: "block", lg: "flex" }}
                    style={{ alignItems: "center" }}
                  >
                    <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                      <NumberInput
                        source="sequenceNoPlus"
                        label="Thứ tự khóa Plus"
                        defaultValue={
                          Number(dataCourseDetail?.sequenceNoPlus) > 0
                            ? dataCourseDetail?.sequenceNoPlus
                            : null
                        }
                        min={0}
                        fullWidth
                        validate={requiredValidate}
                        resource="courses"
                      />
                    </Box>
                    <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                      <BooleanInput
                        source="highlight"
                        label="Nổi bật"
                        fullWidth
                        validate={requiredValidate}
                        resource="courses"
                        defaultValue={dataCourseDetail?.highlight}
                      />
                    </Box>
                    <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                      <NumberInput
                        source="oldPrice"
                        label="Giá cũ"
                        min={0}
                        fullWidth
                        validate={requiredValidate}
                        defaultValue={
                          Number(dataCourseDetail?.oldPrice) > 0
                            ? dataCourseDetail?.oldPrice
                            : null
                        }
                        resource="courses"
                        onChange={(e: any) => setOldPrice(e.target.value)}
                      />
                    </Box>
                    <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                      <NumberInput
                        source="newPrice"
                        label="Giá mới"
                        fullWidth
                        min={0}
                        validate={requiredValidate}
                        defaultValue={
                          Number(dataCourseDetail?.newPrice) > 0
                            ? dataCourseDetail?.newPrice
                            : null
                        }
                        resource="courses"
                        onChange={(e: any) => setNewPrice(e.target.value)}
                      />
                    </Box>
                    <Box flex={0.5} ml={{ xs: 0, sm: "0.5em" }}></Box>
                  </Box>
                )}

                <Box
                  display={{ md: "block", lg: "flex" }}
                  style={{ alignItems: "center" }}
                >
                  <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                    <Editor
                      tinymceScriptSrc='/tinymce/tinymce.min.js'
                      apiKey="s1vqarrumcibda69nl55nhckmz87w4a7w8382tjfvvhigahr"
                      value={description}
                      init={{
                        height: 500,
                        menubar: false,
                        images_upload_url: "/api/upload/image",
                        // images_upload_handler: (
                        //   blobInfo: any,
                        //   success: any,
                        //   failure: any
                        // ) => {
                        //   console.log(blobInfo);
                        //   const formData = new FormData();
                        //   formData.append("file", blobInfo.blob());

                        //   customHttpClient("/api/upload/image", {
                        //     method: "POST",
                        //     body: formData,
                        //   })
                        //     .then((res: any) => res.json)
                        //     .then((message: any) => {
                        //       if (message.data.url.includes("http")) {
                        //         success(message.data.url);
                        //       } else {
                        //         success(
                        //           process.env.REACT_APP_API_BASE_URL +
                        //             message.data.url
                        //         );
                        //       }
                        //     });
                        // },
                        images_upload_handler(blobInfo, progress) {
                          return new Promise((resolve, reject) => {
                            const formData = new FormData();
                            formData.append("file", blobInfo.blob());

                            customHttpClient("/api/upload/image", {
                              method: "POST",
                              body: formData,
                            })
                              .then((res: any) => res.json)
                              .then((message: any) => {
                                if (message.data.url.includes("http")) {
                                  resolve(message.data.url);
                                } else {
                                  resolve(
                                    process.env.REACT_APP_API_BASE_URL +
                                      message.data.url
                                  );
                                }
                              });
                          });
                        },
                        plugins:
                          "print preview powerpaste casechange importcss tinydrive searchreplace autolink autosave save directionality advcode visualblocks visualchars fullscreen image link media mediaembed template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists checklist wordcount tinymcespellchecker a11ychecker imagetools textpattern noneditable help formatpainter permanentpen pageembed charmap tinycomments mentions quickbars linkchecker emoticons advtable code",
                        mobile: {
                          plugins:
                            "print preview powerpaste casechange importcss tinydrive searchreplace autolink autosave save directionality advcode visualblocks visualchars fullscreen image link media mediaembed template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists checklist wordcount tinymcespellchecker a11ychecker textpattern noneditable help formatpainter pageembed charmap mentions quickbars linkchecker emoticons advtable",
                        },
                        toolbar:
                          "undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange permanentpen formatpainter removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | image media pageembed link anchor codesample | a11ycheck ltr rtl code",
                        tinycomments_mode: "embedded",
                      }}
                      onEditorChange={(e) => setDescription(e)}
                    />
                  </Box>
                </Box>
              </CardContent>

              <Toolbar
                record={formProps.record}
                basePath={formProps.basePath}
                invalid={formProps.invalid}
                saving={formProps.saving}
                undoable={false}
                resource="courses"
                // redirect={false}
              >
                <SaveButton
                  saving={formProps.saving}
                  handleSubmitWithRedirect={() =>
                    handleOnsubmitCourses(formProps.form.getState().values)
                  }
                  undoable={false}
                />
              </Toolbar>
            </form>
          </Card>
        );
      }}
    />
  );
};

const Create = (props: any) => {
  return (
    <>
      <CreateAdmin {...props} undoable={false} actions={<ListActions />}>
        <Form />
      </CreateAdmin>
    </>
  );
};

const requiredValidate = [required()];

export default Create;
