import MajorCreate from "./Create";
import MajorEdit from "./Edit";
import MajorList from "./List";
import ReviewIcon from "@material-ui/icons/PostAddOutlined";

export default {
  icon: ReviewIcon,
  list: MajorList,
  create: MajorCreate,
  edit: MajorEdit,
  name: "homeblocksold",
};
