import { Box, Card, CardContent } from "@material-ui/core";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import * as _ from "lodash";
import React, {
  useEffect,
  useLayoutEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import {
  Create as CreateAdmin,
  FormWithRedirect,
  NumberInput,
  required,
  SelectInput,
  Toolbar,
} from "react-admin";
import UploadImage from "../components/UploadImage";
import ListActions from "./ListActions";
import { BooleanInput } from "react-admin";
import { TextInput } from "react-admin";
import { Delete } from "@material-ui/icons";
import { Button } from "react-admin";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Button as ButtonMUI } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { Theme, useTheme } from "@mui/material/styles";
import SelectedInputCheck from "./components/SelectedInputCheck";
import MultipleSelect, { MenuProps } from "./components/MultipleSelect";
import { customHttpClient, customHttpClientAdmin } from "../dataProvider";
import "./components/styles.css";
import { useNotify } from "react-admin";
import { useRedirect } from "react-admin";

export enum HomeSlideModel {
  Post = "1",
  Link = "2",
  Image = "3",
}
enum Area {
  Country = "1",
  Provices = "2",
  Distrist = "3",
}

export enum ObjectFilter {
  Obj = "allUser",
  FilterObj = "ageAndProvice",
}

export enum HomeSlideOnDevice {
  Mobile = "2",
  Web = "1",
  All = "3",
}

type Address = {
  id: number;
  name: string;
};

export const HomeSlideModelName: { [a: string]: string } = {
  [HomeSlideModel.Post]: "Bài đăng",
  [HomeSlideModel.Link]: "Đường Link",
  [HomeSlideModel.Image]: "Popup Ảnh",
};

export const FillterObject: { [a: string]: string } = {
  [ObjectFilter.Obj]: "Tất cả tài khoản",
  [ObjectFilter.FilterObj]: "Lọc đối tượng",
};

export const AreaOption: { [a: string]: string } = {
  [Area.Provices]: "Theo Tỉnh/Thành phố",
  [Area.Distrist]: "Theo Quận /Huyện",
  [Area.Country]: "Cả nước",
};

export type ElementAddress = {
  id: number;
  provices: any;
  isRemove: boolean;
  distrist: string[];
  arrayDistrist?: string[];
  listDistrist?: string[];
  provincesName?: any;
  defaultDistrist?: any;
};

export type DataPopup = {
  type: string;
  type_slider: string;
  title: string;
  sequenceNo: string;
  imageUrl: string;
  device: string;
  status: string;
  description: string;
  url: string;
  createdBy: string;
  detail?: any;
  from_age?: string;
  to_age?: string;
  type_area?: string;
  province?: string[] | string | undefined;
  district?: string[] | undefined;
  post_id?: string | number;
  coures_id?: string;
};

function getStyles(name: string, personName: string[], theme: Theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
type DetailSlider = {
  age: string[] | any;
  area: string[] | any;
};

export const Form = (props: any) => {
  const {
    dataSlider,
    idPopup,
    isEditPopup,
    dataSliderDetail,
    selectedElementDistristProvinces,
  }: {
    dataSlider: DataPopup;
    idPopup: string;
    isEditPopup: boolean;
    dataSliderDetail: DetailSlider;
    selectedElementDistristProvinces: any;
  } = props;

  const [model, setModel] = useState(
    _.get(props, "record.type_slider", HomeSlideModel.Image)
  );
  const [modelObj, setModelObj] = useState(
    _.get(props, "record.obj", ObjectFilter.Obj)
  );

  console.log(model, "model");
  const theme = useTheme();
  const noti = useNotify();
  const redirect = useRedirect();
  const [area, setArea] = useState(
    _.get(props, "record.type_area", Area.Country)
  );
  const [isShowAge, setIsShowAge] = useState<boolean>(true);
  const [isShowArea, setIsShowArea] = useState<boolean>(true);
  const [valuePro, setValuePro] = React.useState<string[]>([]);
  const [listPost, setListPost] = useState<string[]>([]);
  const [isLoadingEdit, setIsLoadingEdit] = useState<boolean>(false);
  const [valuePost, setValuePost] = useState<any>({ id: "", title: "" });
  const myElementRef = useRef<HTMLDivElement>(null);
  const [listProvices, setListProvices] = useState<Address[]>([
    {
      id: -1,
      name: "Chọn tỉnh",
    },
  ]);
  const [selectedProvices, setSelectedProvices] = useState<any>(-1);
  const [listElementAddress, setListElementAddress] = useState<
    ElementAddress[]
  >(
    isEditPopup
      ? selectedElementDistristProvinces
      : [
          {
            id: 1,
            provices: [],
            distrist: [],
            isRemove: false,
            arrayDistrist: [],
          },
        ]
  );
  console.log(
    listElementAddress,
    "listElementAddress =============*************>>"
  );

  useEffect(() => {
    loadDataProvices();
  }, []);

  React.useEffect(() => {
    if (isEditPopup) {
      setValuePost(
        model == HomeSlideModel.Post && dataSlider?.type_slider == model
          ? { id: dataSlider.post_id, title: dataSlider.title }
          : ""
      );
      setModelObj(
        dataSliderDetail?.age.length > 0 || dataSliderDetail?.area.length > 0
          ? _.get(props, "record.obj", ObjectFilter.FilterObj)
          : _.get(props, "record.obj", ObjectFilter.Obj)
      );
      setModel(
        dataSlider?.type_slider == HomeSlideModel.Link
          ? _.get(props, "record.type_slider", HomeSlideModel.Link)
          : dataSlider?.type_slider == HomeSlideModel.Post
          ? _.get(props, "record.type_slider", HomeSlideModel.Post)
          : _.get(props, "record.type_slider", HomeSlideModel.Image)
      );
      setIsShowAge(dataSliderDetail?.age.length > 0 ? true : false);
      setArea(
        isEditPopup && dataSliderDetail?.area.length > 0
          ? dataSliderDetail?.area[0].type_area == Area.Country
            ? Area.Country
            : dataSliderDetail?.area[0].type_area == Area.Distrist
            ? Area.Distrist
            : dataSliderDetail?.area[0].type_area == Area.Provices
            ? Area.Provices
            : Area.Country
          : Area.Country
      );
      if (dataSliderDetail?.area.length > 0) {
        if (dataSliderDetail?.area[0]?.type_area == 2) {
          initSelectedDataProvince();
        }
      }
    }
  }, [isEditPopup, dataSlider, dataSliderDetail, listProvices]);

  useEffect(() => {
    if (isEditPopup) {
      if (dataSliderDetail?.area.length > 0) {
        if (dataSliderDetail?.area[0]?.type_area == 2) {
          initSelectedDataProvince();
        }
        if (dataSliderDetail?.area[0]?.type_area == 3) {
          setListElementAddress(selectedElementDistristProvinces);
          setIsLoadingEdit(true);
        }
      }
    }
  }, [selectedElementDistristProvinces]);

  // console.log(listElementAddress, "listElementAddress ===>>>>>");

  const initSelectedDataProvince = () => {
    const data =
      dataSliderDetail?.area.length > 0
        ? JSON.parse(dataSliderDetail?.area[0].province)
        : "";
    console.log(data, "data");
    console.log(listProvices, "provinceEdit");

    const filteredItems: any = listProvices?.filter((item: any) =>
      data?.includes(item.id)
    );
    console.log(filteredItems, "filteredItems");

    setValuePro(filteredItems);
  };

  const loadDataProvices = async () => {
    await customHttpClientAdmin("/school/provinces", {
      method: "POST",
    })
      .then((res: any) => res.json)
      .then((response: any) => {
        if (response.status === 200) {
          setListProvices(response.data);
        } else console.log(response);
      })
      .catch((error: any) => {
        console.log(error);
      });
    customHttpClient("/crud/posts", {
      method: "GET",
    })
      .then((res: any) => res.json)
      .then((response: any) => {
        setListPost(response);
      })
      .catch((error: any) => {
        console.log(error);
      });
  };

  // Xử lý get distist

  useEffect(() => {
    setSelectedProvices(valuePro?.map((i: any) => i?.id));
  }, [valuePro]);

  const btn = document.getElementsByClassName(
    "MuiButton-sizeSmall"
  ) as HTMLCollectionOf<HTMLElement>;

  useEffect(() => {
    if (!isEditPopup) {
      btn[2].style["display"] = "none";
    }
  }, []);

  // TODO: ===== Handle select provives =====
  const onChangeSelectedProvices = (
    event: SelectChangeEvent<typeof valuePro>
  ) => {
    const {
      target: { value },
    } = event;

    setValuePro(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(" ") : value
    );
  };

  const handleChangePost = (event: SelectChangeEvent) => {
    console.log(event.target, "event");
    const selectedValue = event.target.value;
    const selectedPost = listPost.find(
      (item: any) => item.title === selectedValue
    );

    setValuePost(selectedPost);
  };

  const hanldeSumitPopup = (data: DataPopup) => {
    const provinceArray = listElementAddress.map((item) => item.provices);
    const districtArray = listElementAddress.map((item) => item.distrist);

    const result: {
      [key: string]: {
        type_area: string | undefined;
        province: any;
        district: string;
      };
    } = {};

    districtArray.forEach((district, index) => {
      result[index.toString()] = {
        type_area: data.type_area,
        province: provinceArray[index],
        district: JSON.stringify(district),
      };
    });

    let areaData = {};

    if (modelObj !== ObjectFilter.Obj) {
      if (area === Area.Country) {
        areaData = {
          "0": {
            type_area: data.type_area,
          },
        };
      } else if (area === Area.Provices) {
        areaData = {
          "0": {
            type_area: data.type_area,
            province: JSON.stringify(selectedProvices),
          },
        };
      } else if (area === Area.Distrist) {
        areaData = result;
      }
    }
    console.log(data, "data");

    // Validate

    if (Number(data.sequenceNo) < 0) {
      noti("Số thứ tự không được bé hơn 0", "error");
      return;
    }

    if (data.imageUrl?.length < 0 && !data.imageUrl?.includes("https://")) {
      noti("Vui lòng chọn hình ảnh cho popup", "error");
      return;
    }

    if (modelObj !== ObjectFilter.Obj && isShowAge) {
      const fromAge = Number(data.from_age);
      const toAge = Number(data.to_age);
      if (fromAge == null || undefined || toAge == null || undefined) {
        noti("Độ tuổi không được bỏ trống. Vui lòng nhập tuổi", "error");
        return;
      }

      if (fromAge < 2 || toAge < 2) {
        noti("Độ tuổi phải lớn hơn hoặc bằng 3", "error");
        return;
      }

      if (fromAge > toAge) {
        noti("Tuổi bắt đầu phải lớn hơn tuổi kết thúc", "error");
        return;
      }
    }

    const body = {
      type: data.type,
      type_slider: data.type_slider,
      title:
        model === HomeSlideModel.Post
          ? valuePost?.title
          : model == HomeSlideModel.Image
          ? "Image"
          : data.title,
      sequenceNo: data.sequenceNo,
      imageUrl: data.imageUrl,
      device: data.device,
      status: data.status ? 1 : 0,
      description: data.description,
      url: model == HomeSlideModel.Link ? data.url : null,
      createdBy: data.createdBy,
      post_id: model == HomeSlideModel.Post ? valuePost.id : null,
      detail: {
        age: {
          from_age: isShowAge ? data.from_age : undefined,
          to_age: isShowAge ? data.to_age : undefined,
        },
        area: isShowArea ? areaData : undefined,
      },
    };

    console.log(body, "body");
    customHttpClientAdmin(
      isEditPopup
        ? `/admin/advertisements/${idPopup}`
        : "/admin/advertisements",
      {
        method: isEditPopup ? "PATCH" : "POST",
        body: JSON.stringify(body),
      }
    )
      .then((res: any) => res.json)
      .then((response: any) => {
        if (response) {
          redirect("/advertisements");
          noti("Thành công", "log");
        } else console.log(response);
      })
      .catch((error: any) => {
        console.log(error);
      });
  };

  const renderElementDistPro = () => {
    if (
      dataSliderDetail?.area.length > 0 &&
      dataSliderDetail?.area[0]?.type_area == 3 &&
      isLoadingEdit
    ) {
      return (
        <>
          {listElementAddress?.map((item: any, index: number) => {
            const handleRemove = () => {
              const filterResult = listElementAddress.filter(
                (element: any) => element.id !== item.id
              );
              setListElementAddress(filterResult);
            };

            const disDef = item?.defaultDistrist?.flatMap(function (item: any) {
              return item?.map(function (subItem: any) {
                return subItem;
              });
            });
            return (
              <div
                key={index}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  flexDirection: "row",
                  marginBottom: 20,
                }}
              >
                <Box
                  flex={1}
                  ml={{ xs: 0, sm: "0.5em" }}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                    columnGap: "3%",
                  }}
                >
                  <SelectedInputCheck
                    data={listProvices}
                    elementList={listElementAddress}
                    setDataProAndDist={setListElementAddress}
                    title="Chọn tỉnh/thành phố"
                    indexItem={item?.id}
                    idProvince={item?.provices}
                    dataDefault={item?.provincesName}
                  />
                </Box>
                <Box
                  flex={1}
                  ml={{ xs: 0, sm: "0.5em" }}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                  }}
                >
                  <MultipleSelect
                    setDataAddress={setListElementAddress}
                    data={item.listDistrist}
                    title="Chọn quận/huyện"
                    defaultValueDist={disDef}
                    elementList={listElementAddress}
                    indexItem={item.id}
                    isEdit={isEditPopup}
                  />
                </Box>
                {listElementAddress.length > 1 && (
                  <ButtonMUI
                    variant="outlined"
                    color="error"
                    onClick={handleRemove}
                    style={{ marginTop: -20 }}
                    startIcon={<DeleteIcon />}
                  >
                    Remove
                  </ButtonMUI>
                )}
              </div>
            );
          })}
        </>
      );
    } else {
      return listElementAddress?.map((item: ElementAddress, index: number) => {
        return (
          <div
            key={index}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexDirection: "row",
              marginBottom: 20,
            }}
          >
            <Box
              flex={0.5}
              ml={{ xs: 0, sm: "0.5em" }}
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                columnGap: "3%",
              }}
            >
              <SelectedInputCheck
                data={listProvices}
                elementList={listElementAddress}
                setDataProAndDist={setListElementAddress}
                title="Chọn tỉnh/thành phố"
                indexItem={index}
              />
            </Box>
            <Box
              flex={1}
              ml={{ xs: 0, sm: "0.5em" }}
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              <MultipleSelect
                setDataAddress={setListElementAddress}
                data={item.listDistrist}
                title="Chọn quận/huyện"
                elementList={listElementAddress}
                indexItem={index}
              />
            </Box>
            {listElementAddress.length > 1 && (
              <ButtonMUI
                variant="outlined"
                color="error"
                onClick={() => {
                  const filterResult = listElementAddress.filter(function (
                    element: any
                  ) {
                    return element.id !== item.id;
                  });
                  setListElementAddress(filterResult);
                }}
                style={{ marginTop: -20 }}
                startIcon={<DeleteIcon />}
              >
                Remove
              </ButtonMUI>
            )}
          </div>
        );
      });
    }
  };

  const renderFilter = () => {
    if (isEditPopup) {
      if (dataSliderDetail.area.length > 0) {
        return (
          <>
            <Box
              flex={1}
              ml={{ xs: 0, sm: "0.5em" }}
              style={{ display: "flex", alignItems: "center" }}
            >
              <Button
                aria-label="delete"
                size="small"
                onClick={() => setIsShowArea(false)}
                // disabled={disabled}
              >
                <Delete fontSize="inherit" color="action" />
              </Button>
              <span>Lọc Theo khu vực:</span>
            </Box>
            <Box flex={0.5} ml={{ xs: 0, sm: "0.5em" }} />
            <Box
              flex={
                area == Area.Country
                  ? 1
                  : area == Area.Provices
                  ? 1.3
                  : area == Area.Distrist
                  ? 0.5
                  : 1
              }
              ml={{ xs: 0, sm: "0.5em" }}
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                columnGap: "3%",
              }}
            >
              <SelectInput
                fullWidth
                source="type_area"
                // validate={requiredValidate}
                resource="advertisements"
                label="Loại khu vực"
                onChange={(e: any) => {
                  setArea(e.target.value);
                  setListElementAddress([
                    {
                      id: 1,
                      provices: -1,
                      distrist: [],
                      isRemove: false,
                      arrayDistrist: [],
                    },
                  ]);
                }}
                choices={_.keys(Area).map((key: any) => ({
                  id: (Area as any)[key],
                  name: (AreaOption as any)[(Area as any)[key]],
                }))}
              />
            </Box>
          </>
        );
      }
    } else {
      return (
        <>
          <Box
            flex={1}
            ml={{ xs: 0, sm: "0.5em" }}
            style={{ display: "flex", alignItems: "center" }}
          >
            <Button
              aria-label="delete"
              size="small"
              onClick={() => setIsShowArea(false)}
              // disabled={disabled}
            >
              <Delete fontSize="inherit" color="action" />
            </Button>
            <span>Lọc Theo khu vực:</span>
          </Box>
          <Box flex={0.5} ml={{ xs: 0, sm: "0.5em" }} />
          <Box
            flex={
              area == Area.Country
                ? 1
                : area == Area.Provices
                ? 1.3
                : area == Area.Distrist
                ? 0.5
                : 1
            }
            ml={{ xs: 0, sm: "0.5em" }}
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              columnGap: "3%",
            }}
          >
            <SelectInput
              fullWidth
              source="type_area"
              // validate={requiredValidate}
              resource="advertisements"
              label="Loại khu vực"
              onChange={(e: any) => {
                setArea(e.target.value);
                setListElementAddress([
                  {
                    id: 1,
                    provices: -1,
                    distrist: [],
                    isRemove: false,
                    arrayDistrist: [],
                  },
                ]);
              }}
              choices={_.keys(Area).map((key: any) => ({
                id: (Area as any)[key],
                name: (AreaOption as any)[(Area as any)[key]],
              }))}
            />
          </Box>
        </>
      );
    }
  };

  return (
    <FormWithRedirect
      {...props}
      initialValues={{
        type_slider: isEditPopup
          ? dataSlider?.type_slider == HomeSlideModel.Link
            ? HomeSlideModel.Link
            : dataSlider?.type_slider == HomeSlideModel.Post
            ? HomeSlideModel.Post
            : HomeSlideModel.Image
          : HomeSlideModel.Image,
        device: isEditPopup
          ? dataSlider?.device == HomeSlideOnDevice.All
            ? HomeSlideOnDevice.All
            : dataSlider?.device == HomeSlideOnDevice.Mobile
            ? HomeSlideOnDevice.Mobile
            : HomeSlideOnDevice.Web
          : HomeSlideOnDevice.Mobile,
        type_area:
          isEditPopup && dataSliderDetail?.area.length > 0
            ? dataSliderDetail?.area[0].type_area == Area.Country
              ? Area.Country
              : dataSliderDetail?.area[0].type_area == Area.Distrist
              ? Area.Distrist
              : dataSliderDetail?.area[0].type_area == Area.Provices
              ? Area.Provices
              : Area.Country
            : Area.Country,
        obj: isEditPopup
          ? dataSliderDetail?.age.length > 0 ||
            dataSliderDetail?.area.length > 0
            ? ObjectFilter.FilterObj
            : ObjectFilter.Obj
          : ObjectFilter.Obj,
      }}
      render={(formProps: any) => {
        return (
          <Card>
            <form>
              <CardContent>
                <Box display={{ md: "block", lg: "flex" }}>
                  <Box display="none">
                    <TextInput
                      type="text"
                      defaultValue="popup"
                      source="type"
                      resource="advertisements"
                    />
                    <TextInput
                      type="text"
                      defaultValue={props?.createdBy}
                      source="createdBy"
                      resource="advertisements"
                    />
                  </Box>
                  <Box flex={0.8} ml={{ xs: 0, sm: "0.5em" }}>
                    <UploadImage
                      source="imageUrl"
                      defaultValue={
                        isEditPopup ? dataSlider?.imageUrl : undefined
                      }
                    />
                  </Box>
                  <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                    <SelectInput
                      fullWidth
                      source="device"
                      resource="advertisements"
                      validate={requiredValidate}
                      label="Chọn thiết bị"
                      choices={[
                        {
                          id: HomeSlideOnDevice.Mobile,
                          name: "Mobile",
                        },
                        {
                          id: HomeSlideOnDevice.Web,
                          name: "Web",
                        },
                        {
                          id: HomeSlideOnDevice.All,
                          name: "Cả hai",
                        },
                      ]}
                    />
                  </Box>

                  <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                    <SelectInput
                      fullWidth
                      source="type_slider"
                      validate={requiredValidate}
                      label="Chọn loại"
                      resource="advertisements"
                      onChange={(e: any) => {
                        setModel(e.target.value);
                      }}
                      choices={_.keys(HomeSlideModel).map((key: any) => ({
                        id: (HomeSlideModel as any)[key],
                        name: (HomeSlideModelName as any)[
                          (HomeSlideModel as any)[key]
                        ],
                      }))}
                    />
                  </Box>

                  {/* =========================options type popup===================== */}
                  <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                    {model == HomeSlideModel.Link && (
                      <>
                        <TextInput
                          label="Đường link"
                          defaultValue={
                            isEditPopup ? dataSlider?.url : undefined
                          }
                          validate={requiredValidate}
                          source="url"
                          resource="advertisements"
                          fullWidth
                        />
                      </>
                    )}
                    {model === HomeSlideModel.Post && (
                      <FormControl
                        variant="standard"
                        sx={{
                          m: 1,
                          minWidth: 300,
                        }}
                        style={{
                          backgroundColor: "#e5e5e5",
                        }}
                      >
                        <InputLabel
                          id="demo-simple-select-standard-label"
                          style={{ paddingLeft: 5 }}
                        >
                          Bài đăng
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          value={valuePost}
                          style={{
                            paddingLeft: 10,
                          }}
                          MenuProps={MenuProps}
                          onChange={handleChangePost}
                          renderValue={(value) => (
                            <span>{valuePost.title}</span>
                          )}
                        >
                          {listPost?.map((item: any, index: number) => (
                            <MenuItem
                              data-id
                              value={item.title}
                              key={index}
                              style={{ whiteSpace: "normal" }}
                            >
                              {item.title}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  </Box>
                </Box>
                <Box display={{ md: "block", lg: "flex" }}>
                  <Box flex={0.8} ml={{ xs: 0, sm: "0.5em" }} />
                  <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                    <NumberInput
                      min={0}
                      source="sequenceNo"
                      defaultValue={
                        isEditPopup ? dataSlider?.sequenceNo : undefined
                      }
                      label="Số thứ tự"
                      validate={requiredValidate}
                      resource="advertisements"
                    />
                  </Box>
                  <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                    <BooleanInput
                      label="Ẩn/Hiện Pop-up"
                      source="status"
                      resource="advertisements"
                      defaultValue={isEditPopup ? dataSlider?.status : 0}
                    />
                  </Box>
                  <Box flex={1} ml={{ xs: 0, sm: "0.5em" }} />
                </Box>
                {model === HomeSlideModel.Link && (
                  <Box display={{ md: "block", lg: "flex" }}>
                    <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                      <TextInput
                        label="Tiêu đề pop-up"
                        validate={requiredValidate}
                        multiline
                        source="title"
                        resource="advertisements"
                        fullWidth
                        defaultValue={
                          isEditPopup ? dataSlider?.title : undefined
                        }
                      />
                    </Box>
                  </Box>
                )}
                <Box display={{ md: "block", lg: "flex" }}>
                  <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                    <TextInput
                      label="Mô tả ngắn"
                      source="description"
                      multiline
                      resource="advertisements"
                      fullWidth
                      defaultValue={
                        isEditPopup ? dataSlider?.description : undefined
                      }
                    />
                  </Box>
                </Box>
                <Box display={{ md: "block", lg: "flex" }}>
                  <Box flex={0.2} ml={{ xs: 0, sm: "0.5em" }}>
                    <SelectInput
                      fullWidth
                      source="obj"
                      resource="advertisements"
                      // validate={requiredValidate}
                      label="Đối tượng"
                      onChange={(e: any) => {
                        setModelObj(e.target.value);
                        setIsShowAge(true);
                        setIsShowArea(true);

                        setListElementAddress([
                          {
                            id: 1,
                            provices: -1,
                            distrist: [],
                            isRemove: false,
                            arrayDistrist: [],
                          },
                        ]);
                      }}
                      choices={_.keys(ObjectFilter).map((key: any) => ({
                        id: (ObjectFilter as any)[key],
                        name: (FillterObject as any)[
                          (ObjectFilter as any)[key]
                        ],
                      }))}
                    />
                  </Box>
                </Box>
                <Box display={{ md: "block", lg: "flex" }}>
                  {modelObj == ObjectFilter.FilterObj && isShowAge && (
                    <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                      <Box display={{ md: "block", lg: "flex" }}>
                        <Box
                          flex={1}
                          ml={{ xs: 0, sm: "0.5em" }}
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <Button
                            aria-label="delete"
                            size="small"
                            onClick={() => setIsShowAge(false)}
                            // disabled={disabled}
                          >
                            <Delete fontSize="inherit" color="action" />
                          </Button>
                          <span>Lọc Theo độ tuổi:</span>
                        </Box>
                        <Box
                          flex={2}
                          ml={{ xs: 0, sm: "0.5em" }}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "row",
                            columnGap: "3%",
                          }}
                        >
                          <NumberInput
                            label="Từ"
                            source="from_age"
                            defaultValue={
                              isEditPopup && dataSliderDetail?.age.length > 0
                                ? dataSliderDetail?.age[0].from_age
                                : undefined
                            }
                            min={3}
                            resource="advertisements"
                            max={100}

                            // onChange={(e: any) => setAgeFrom(e.target.value)}
                            // disabled={disabled}
                          />
                          -
                          <NumberInput
                            label="Đến"
                            source="to_age"
                            defaultValue={
                              isEditPopup && dataSliderDetail?.age.length > 0
                                ? dataSliderDetail?.age[0].to_age
                                : undefined
                            }
                            resource="advertisements"
                            min={3}
                            max={100}
                            // onChange={(e: any) => setAgeTo(e.target.value)}
                            // disabled={disabled}
                          />
                        </Box>
                      </Box>
                    </Box>
                  )}
                </Box>
                <Box display={{ md: "block", lg: "flex" }}>
                  {modelObj == ObjectFilter.FilterObj && isShowArea && (
                    <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                      <Box display={{ md: "block", lg: "flex" }}>
                        <Box
                          flex={1}
                          ml={{ xs: 0, sm: "0.5em" }}
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <Button
                            aria-label="delete"
                            size="small"
                            onClick={() => setIsShowArea(false)}
                            // disabled={disabled}
                          >
                            <Delete fontSize="inherit" color="action" />
                          </Button>
                          <span>Lọc Theo khu vực:</span>
                        </Box>
                        <Box flex={0.5} ml={{ xs: 0, sm: "0.5em" }} />
                        <Box
                          flex={
                            area == Area.Country
                              ? 1
                              : area == Area.Provices
                              ? 1.3
                              : area == Area.Distrist
                              ? 0.5
                              : 1
                          }
                          ml={{ xs: 0, sm: "0.5em" }}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "row",
                            columnGap: "3%",
                          }}
                        >
                          <SelectInput
                            fullWidth
                            source="type_area"
                            // validate={requiredValidate}
                            resource="advertisements"
                            label="Loại khu vực"
                            onChange={(e: any) => {
                              setArea(e.target.value);
                              setListElementAddress([
                                {
                                  id: 1,
                                  provices: -1,
                                  distrist: [],
                                  isRemove: false,
                                  arrayDistrist: [],
                                },
                              ]);
                            }}
                            choices={_.keys(Area).map((key: any) => ({
                              id: (Area as any)[key],
                              name: (AreaOption as any)[(Area as any)[key]],
                            }))}
                          />
                        </Box>
                        {/* {renderFilter()} */}
                        {area === Area.Distrist && (
                          <>
                            <Box flex={1} ml={{ xs: 0, sm: "0.5em" }} />
                            <Box flex={1} ml={{ xs: 0, sm: "0.5em" }} />
                            <Box flex={0.4} ml={{ xs: 0, sm: "0.5em" }} />
                          </>
                        )}
                        {area === Area.Country && (
                          <>
                            <Box flex={1} ml={{ xs: 0, sm: "0.5em" }} />
                            <Box flex={1} ml={{ xs: 0, sm: "0.5em" }} />
                          </>
                        )}
                        {area === Area.Provices && (
                          <Box
                            flex={1}
                            ml={{ xs: 0, sm: "0.5em" }}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              flexDirection: "row",
                              columnGap: "3%",
                            }}
                          >
                            {/* TODO: Check muitlple Provice */}
                            <div>
                              <FormControl
                                sx={{
                                  m: 1,
                                  width: 500,
                                  border: 0,
                                  borderWidth: 0,
                                  marginTop: -1.2,
                                  backgroundColor: "#e5e5e5",
                                }}
                                variant="standard"
                              >
                                <InputLabel
                                  id="demo-simple-select-standard-label"
                                  style={{ paddingLeft: 5 }}
                                >
                                  Chọn tỉnh / thành phố
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-standard-label"
                                  id="demo-simple-select-standard-provices"
                                  multiple
                                  MenuProps={MenuProps}
                                  sx={{
                                    ".MuiSelect-selectMenu": {
                                      overflow: "auto",
                                      wordWrap: "break-word",
                                    },
                                  }}
                                  style={{
                                    paddingLeft: 10,
                                  }}
                                  value={valuePro}
                                  onChange={(e: any) =>
                                    onChangeSelectedProvices(e)
                                  }
                                  renderValue={(selected: any) => (
                                    <React.Fragment>
                                      {valuePro?.map(
                                        (i: any, index: number) => (
                                          <span
                                            key={index}
                                            style={{
                                              padding: 7,
                                              marginRight: 10,
                                              width: "100%",
                                              borderRadius: 8,
                                              backgroundColor: "#fff",
                                              fontSize: 12,
                                              fontWeight: "bold",
                                            }}
                                          >
                                            {i.name}
                                          </span>
                                        )
                                      )}
                                    </React.Fragment>
                                  )}
                                  // input={<OutlinedInput label="Name" />}
                                  // MenuProps={MenuProps}
                                >
                                  {listProvices?.map(
                                    (item: any, index: number) => (
                                      <MenuItem
                                        key={item.id}
                                        value={item}
                                        style={getStyles(
                                          item.name,
                                          valuePro,
                                          theme
                                        )}
                                      >
                                        {item.name}
                                      </MenuItem>
                                    )
                                  )}
                                </Select>
                              </FormControl>
                            </div>
                          </Box>
                        )}
                      </Box>
                    </Box>
                  )}
                </Box>
                <Box display={{ md: "block", lg: "flex" }}>
                  {modelObj == ObjectFilter.FilterObj && isShowArea && (
                    <>
                      <Box flex={2} ml={{ xs: 0, sm: "0.5em" }} />
                      <Box flex={2} ml={{ xs: 0, sm: "0.5em" }} />

                      <Box
                        flex={3}
                        ml={{ xs: 0, sm: "0.5em" }}
                        style={{ marginBottom: 100 }}
                      >
                        {area === Area.Distrist && (
                          <>
                            {renderElementDistPro()}
                            <ButtonMUI
                              style={{ margin: 20 }}
                              variant="contained"
                              onClick={() => {
                                if (isEditPopup) {
                                  setListElementAddress([
                                    ...listElementAddress,
                                    {
                                      id:
                                        listElementAddress[
                                          listElementAddress.length - 1
                                        ].id + 1,
                                      provices: -1,
                                      provincesName: "",
                                      defaultDistrist: [],
                                      distrist: [],
                                      isRemove: true,
                                      arrayDistrist: [],
                                      listDistrist: [],
                                    },
                                  ]);
                                } else {
                                  setListElementAddress([
                                    ...listElementAddress,
                                    {
                                      id:
                                        listElementAddress[
                                          listElementAddress.length - 1
                                        ].id + 1,
                                      provices: [],
                                      distrist: [],
                                      isRemove: true,
                                      arrayDistrist: [],
                                    },
                                  ]);
                                }
                              }}
                              startIcon={<AddCircleIcon />}
                            >
                              ADD
                            </ButtonMUI>
                          </>
                        )}
                      </Box>
                      <Box flex={0.2} ml={{ xs: 0, sm: "0.5em" }} />
                    </>
                  )}
                </Box>
              </CardContent>

              <Toolbar
                record={formProps.record}
                basePath={formProps.basePath}
                invalid={formProps.invalid}
                handleSubmit={() =>
                  hanldeSumitPopup(formProps.form.getState().values)
                }
                saving={formProps.saving}
                undoable={false}
                resource="advertisements"
              />
            </form>
          </Card>
        );
      }}
    />
  );
};

const Create = (props: any) => {
  return (
    <CreateAdmin undoable={false} {...props} actions={<ListActions />}>
      <Form createdBy={props?.permissions?.email} />
    </CreateAdmin>
  );
};

const requiredValidate = [required()];

export default Create;
