import React, { FC } from "react";
import { Datagrid, List, TextField, useTranslate } from "react-admin";
import DateTimeField from "../components/DateTimeField";
import ListActions from "./ListActions";
import ListFilter from "./ListFilters";

const MajorList: FC<any> = (props) => {
  const translate = useTranslate();
  return (
    <List
      {...props}
      title={translate("resources.periods.page.list")}
      actions={<ListActions />}
      filters={<ListFilter />}
    >
      <Datagrid optimized rowClick="edit">
        <TextField source="id" />
        <TextField source="name" />
        <DateTimeField source="createdAt" />
      </Datagrid>
    </List>
  );
};

export default MajorList;
