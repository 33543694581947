import { ThemeName, TypeGrade } from '../types';

export const CHANGE_THEME = 'CHANGE_THEME';
export const TYPE_GRADE = 'TYPE_GRADE';

export const changeTheme = (theme: ThemeName) => ({
    type: CHANGE_THEME,
    payload: theme,
});

export const changeTypeGrade = (grades: TypeGrade) => ({
    type: TYPE_GRADE,
    payload: grades,
});
