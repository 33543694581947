import ListActions from "./ListActions";
import ListFilter from "./ListFilters";
import MoneyField from "../components/MoneyField";
import React, { FC } from "react";
import {
  Datagrid,
  List,
  TextField,
  useTranslate,
  ReferenceField,
  SingleFieldList,
  ChipField,
} from "react-admin";
import AvatarField from "../components/AvatarField";
import DateTimeField from "../components/DateTimeField";

const MajorList: FC<any> = (props) => {
  const translate = useTranslate();
  return (
    <List
      {...props}
      title={translate("resources.posts.page.list")}
      actions={<ListActions />}
      filters={<ListFilter />}
    >
      <Datagrid optimized rowClick="edit">
        <TextField source="id" />
        <AvatarField source="imageUrl" />
        <TextField source="title" />
        <TextField source="description" />
        <ReferenceField source="createdBy" reference="users">
          <TextField source="email" />
        </ReferenceField>
        <DateTimeField source="createdAt" />
      </Datagrid>
    </List>
  );
};

export default MajorList;
