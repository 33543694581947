import React, { cloneElement, FC, useState } from "react";
import {
  ListButton,
  sanitizeListRestProps,
  TopToolbar,
  CreateButton,
  useTranslate,
} from "react-admin";
import { Button } from "react-admin";
import { CloudDownloadOutlined } from "@material-ui/icons";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  TextField,
  Button as ButtonM,
} from "@material-ui/core";
import { customHttpClient } from "../dataProvider";
import FileSaver from "file-saver";

interface IProps {
  open: boolean;
  setOpen: any;
}

const FormDialog: FC<IProps> = (props) => {
  const { open, setOpen } = props;
  const [startDate, setStartDate] = useState<any>();
  const [endDate, setEndDate] = useState<any>();

  const handleExport = () => {
    customHttpClient("/api/studentcardcodes/export", {
      method: "POST",
      body: JSON.stringify({ startDate, endDate }),
    }).then((res: any) => {
      setOpen(false);
      FileSaver.saveAs(
        `${process.env.REACT_APP_API_BASE_URL}/public/gens/${res.body}`,
        res.body
      );
    });
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        Export danh sách học viên đăng ký
      </DialogTitle>
      <DialogContent>
        <TextField
          id="date"
          label="Start Date"
          type="date"
          required
          value={startDate}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => {
            setStartDate(e.target.value);
          }}
        />
        <TextField
          id="date2"
          required
          label="End Date"
          value={endDate}
          type="date"
          onChange={(e) => {
            setEndDate(e.target.value);
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </DialogContent>
      <DialogActions>
        <ButtonM onClick={handleExport} color="primary">
          Export
        </ButtonM>
      </DialogActions>
    </Dialog>
  );
};

const ListActions: FC<any> = ({
  currentSort,
  className,
  resource,
  filters,
  displayedFilters,
  exporter, // you can hide ExportButton if exporter = (null || false)
  filterValues,
  permanentFilter,
  hasCreate, // you can hide CreateButton if hasCreate = false
  basePath,
  selectedIds,
  onUnselectItems,
  showFilter,
  maxResults,
  total,
  hasList,
  hasShow,
  ...rest
}) => {
  const translate = useTranslate();
  const [open, setOpen] = useState(false);
  return (
    <>
      <TopToolbar
        className={className}
        {...sanitizeListRestProps(rest)}
        haslist={`${hasList}`}
        hasshow={`${hasShow}`}
      >
        {!filters && <ListButton basePath={basePath} />}
        {filters &&
          cloneElement(filters, {
            undoable: "false",
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: "button",
          })}
        <CreateButton
          basePath={basePath}
          label={translate(`resources.studentcardcodes.actions.btnActive`)}
        />
        <Button
          variant="text"
          translate={false}
          label="Export"
          onClick={() => {
            setOpen(() => true);
          }}
        >
          <CloudDownloadOutlined style={{ fontSize: "20" }} />
        </Button>
      </TopToolbar>
      <FormDialog open={open} setOpen={setOpen} />
    </>
  );
};

export default ListActions;
