import ListActions from "./ListActions";
import React from "react";
import SexInput from "../components/SexInput";
import { Box, Card, CardContent } from "@material-ui/core";
import {
  Create,
  DateInput,
  FormWithRedirect,
  PasswordInput,
  required,
  TextInput,
  Toolbar,
} from "react-admin";

const MajorCreate = (props: any) => {
  return (
    <Create {...props} undoable={false} actions={<ListActions />}>
      <Form />
    </Create>
  );
};

export const Form = (props: any) => {
  return (
    <FormWithRedirect
      {...props}
      render={(formProps: any) => (
        <Card>
          <form>
            <CardContent>
              <Box display={{ md: "block", lg: "flex" }}>
                <Box display={{ xs: "block", sm: "flex" }}>
                  <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                    <TextInput source="avatar" resource="users" fullWidth />

                    <TextInput
                      source="email"
                      resource="users"
                      fullWidth
                      validate={requiredValidate}
                    />
                    <PasswordInput
                      source="password"
                      validate={requiredValidate}
                      resource="users"
                      fullWidth
                    />
                    <TextInput
                      source="fullname"
                      resource="users"
                      validate={requiredValidate}
                      fullWidth
                    />
                  </Box>
                </Box>
              </Box>
            </CardContent>
            <Toolbar
              record={formProps.record}
              basePath={formProps.basePath}
              invalid={formProps.invalid}
              handleSubmit={formProps.handleSubmit}
              saving={formProps.saving}
              resource="admins"
              undoable={false}
            />
          </form>
        </Card>
      )}
    />
  );
};

const requiredValidate = [required()];

export default MajorCreate;
