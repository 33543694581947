import * as _ from 'lodash';
import React, { FC } from 'react';
import { optionRenderer } from './Create';
import {
  Filter,
  NumberInput,
  TextInput,
  ReferenceInput,
  AutocompleteInput,
} from "react-admin";

const ListFilter: FC<any> = (props) => (
  <Filter {...props}>
    <NumberInput source="id" />
    <TextInput source="name" />
    <ReferenceInput
      source="sectionId"
      reference="sections"
      resource="sections"
      filterToQuery={(searchText: any) => {
        return _.isEmpty(_.trim(searchText)) ? null : { name: searchText };
      }}>
      <AutocompleteInput fullWidth optionText={optionRenderer} />
    </ReferenceInput>
  </Filter>
);

export default ListFilter;
