import { Filter, NumberInput, TextInput } from "react-admin";
import React, { FC } from "react";

const ListFilter: FC<any> = (props) => (
  <Filter {...props}>
    <NumberInput source="id" />
  </Filter>
);

export default ListFilter;
