import { Chip } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import MuiExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import * as _ from "lodash";
import moment from "moment";
import React, { FC } from "react";
import { useApiFetch } from "../helpers";
import { useState } from "react";

const useStyles = makeStyles((theme: any) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    // color: theme.palette.text.secondary,
    paddingLeft: "20px",
  },
  secondaryHeadingBlock: {
    display: "flex",
    alignItems: "center",
  },
  secondaryHeadingBlock2: {
    display: "flex",
    flexDirection: "column",
    paddingRight: "30px",
  },
  headingWrapper: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    alignItems: "center",
  },
}));

const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: "rgba(0, 0, 0, .03)",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiExpansionPanelSummary);

interface IProps {
  studentId: number;
}
const StudentCourseInfo: React.FC<IProps> = (props) => {
  const classes = useStyles();

  const { studentId } = props;
  const { loading, data: studentCourses } = useApiFetch(
    `/api/studentcourses/${studentId}/courses`,
    [studentId],
    {
      defaultValue: [],
    }
  );
  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Card>
      <CardContent>
        <Typography gutterBottom>Lịch sử học</Typography>
        <div className={classes.root}>
          {studentCourses.map((sc: any) => (
            <SimpleExpansionPanel key={sc.id} sc={sc} />
          ))}
        </div>
      </CardContent>
    </Card>
  );
};

const SimpleExpansionPanel: FC<{ sc: any }> = (props) => {
  const classes = useStyles();
  const studentCourse = _.get(props, "sc", {});
  const course = _.get(props, "sc.course", {});
  const [expanded, setExpanded] = useState(false);
  const { loading, data: studentUnits } = useApiFetch(
    `/api/studentunits/${studentCourse.studentId}/courses/${studentCourse.courseId}/units`,
    [studentCourse],
    {
      defaultValue: [],
      condition: expanded,
    }
  );

  return (
    <ExpansionPanel onChange={(e, expanded) => setExpanded(() => expanded)}>
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content">
        <div className={classes.headingWrapper}>
          <Typography className={classes.heading}>{course.name}</Typography>
          <div className={classes.secondaryHeadingBlock}>
            <Chip label={studentCourse.status} />

            <Typography className={classes.secondaryHeading}>
              Start: {moment(studentCourse.startAt).format("HH:mm DD/MM/YYYY")}
            </Typography>
          </div>
        </div>
      </ExpansionPanelSummary>
      {studentUnits.map((su: any) => (
        <ExpansionPanelDetails>
          <div className={classes.headingWrapper}>
            <Typography className={classes.heading}>
              {_.get(su, "unit.name")}
            </Typography>
            <div className={classes.secondaryHeadingBlock}>
              <Chip label={_.get(su, "status")} />
              <div className={classes.secondaryHeadingBlock2}>
                <Typography className={classes.secondaryHeading}>
                  Start &nbsp;:{" "}
                  {moment(_.get(su, "startAt")).format("HH:mm DD/MM/YYYY")}
                </Typography>
                {_.get(su, "endAt") && (
                  <Typography className={classes.secondaryHeading}>
                    Finish:{" "}
                    {moment(_.get(su, "endAt")).format("HH:mm DD/MM/YYYY")}
                  </Typography>
                )}
              </div>
            </div>
          </div>
        </ExpansionPanelDetails>
      ))}
    </ExpansionPanel>
  );
};

export default StudentCourseInfo;
