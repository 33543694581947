import React, { FC } from "react";
import { Filter, NumberInput, TextInput } from "react-admin";

const ListFilter: FC<any> = (props) => (
  <Filter {...props}>
    <TextInput source="title" label="Lọc theo tiêu đề" />
  </Filter>
);

export default ListFilter;
