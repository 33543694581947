import ChipField from '../components/ChipField';
import DateTimeField from '../components/DateTimeField';
import ListActions from './ListActions';
import ListFilter from './ListFilters';
import React, { FC } from 'react';
import {
  Datagrid,
  List,
  TextField,
  useTranslate,
  ReferenceField,
} from "react-admin";

const MajorList: FC<any> = (props) => {
  const translate = useTranslate();
  return (
    <List
      {...props}
      title={translate("resources.units.page.list")}
      actions={<ListActions />}
      filters={<ListFilter />}>
      <Datagrid optimized rowClick="edit">
        <TextField source="id" />
        <TextField source="name" />
        <ReferenceField
          source="sectionId"
          reference="sections"
          label={translate("resources.sections.fields.name")}>
          <ChipField source="name" />
        </ReferenceField>

        <ReferenceField
          source="sectionId"
          reference="sections"
          label={translate("resources.courses.fields.name")}>
          <ReferenceField
            source="courseId"
            reference="courses"
            label={translate("resources.courses.fields.name")}>
            <ChipField source="name" />
          </ReferenceField>
        </ReferenceField>

        <DateTimeField source="createdAt" />
      </Datagrid>
    </List>
  );
};

export default MajorList;
