import * as React from "react";
import { Theme, useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import "./styles.css";
import Checkbox from "@mui/material/Checkbox";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name: string, personName: string[], theme: Theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

interface Iprops {
  data: any;
  title: string;
  setSelected: any;
}

const MultipleSelect = ({ data, title, setSelected }: Iprops) => {
  const theme = useTheme();
  const [personName, setPersonName] = React.useState<string[]>([]);

  React.useEffect(() => {
    setPersonName([]);
  }, [data]);

  React.useEffect(() => {
    setSelected(personName.map((i: any) => i?.id));
  }, [personName]);

  const handleChange = (event: SelectChangeEvent<typeof personName>) => {
    const {
      target: { value },
    } = event;

    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(" ") : value
    );
  };

  return (
    <div>
      <FormControl
        sx={{
          m: 1,
          width: 700,
          border: 0,
          borderWidth: 0,
          backgroundColor: "#e5e5e5",
        }}
        variant="standard">
        <InputLabel
          id="demo-simple-select-standard-label"
          style={{ paddingLeft: 5 }}>
          {title}
        </InputLabel>
        <Select
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          multiple
          MenuProps={MenuProps}
          sx={{
            ".MuiSelect-selectMenu": {
              overflow: "auto",
              wordWrap: "break-word",
            },
          }}
          style={{
            paddingLeft: 15,
          }}
          value={personName}
          onChange={(e) => handleChange(e)}
          renderValue={(selected: string[]) =>
            selected?.map((i: any, idx: number) => (
              <span
                key={idx}
                style={{
                  padding: 5,
                  marginRight: 10,
                  width: "100%",
                  borderRadius: 10,
                  backgroundColor: "#fff",
                  fontSize: 13,
                  fontWeight: "700",
                  marginBottom: 10,
                }}>
                {i.device + " - " + i.title}
              </span>
            ))
          }
          // input={<OutlinedInput label="Name" />}
          // MenuProps={MenuProps}
        >
          {data?.map((item: any, index: any) => (
            <MenuItem
              key={item.id}
              value={item}
              style={getStyles(item.name, personName, theme)}>
              <Checkbox checked={personName.indexOf(item) > -1} />
              {item.device + " - " + item.title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default React.memo(MultipleSelect);
