import ListActions from "./ListActions";
import React from "react";
import { Edit } from "react-admin";
import { Form } from "./Create";

const MajorEdit = (props: any) => {
  return (
    <Edit
      component="div"
      {...props}
      undoable={false}
      actions={<ListActions showPermission />}>
      <Form />
    </Edit>
  );
};

export default MajorEdit;
