import * as _ from "lodash";
import React, { FC, useState, useEffect } from "react";
import { useApiFetch, useCustomResfresh } from "../helpers";
import DoneIcon from "@material-ui/icons/Done";
import CancelIcon from "@material-ui/icons/CancelOutlined";
import {
  Checkbox,
  FormControlLabel,
  Grid,
  Card,
  CardContent,
  Typography,
  makeStyles,
  CircularProgress,
  RadioGroup,
  Radio,
} from "@material-ui/core";
import { useApiMutate } from "../helpers/useApiMutate";
import { useTranslate, Button } from "react-admin";
import { RouteComponentProps } from "react-router";

interface IProps extends RouteComponentProps<{ userId: string }> {}

const setChecked = (userPers: string[][], per: string[]) => {
  return _.findIndex(userPers, (uPer) => _.isEqual(uPer, per)) > -1;
};

const useStyles = makeStyles({
  title: {
    margin: "8px 0 8px 8px",
    fontWeight: 600,
  },
  wrapperLabel: {
    padding: "16px 0 8px 0",
    display: "flex",
    alignItems: "center",
  },
  label: {
    fontSize: 16,
    fontWeight: 600,
  },
  wrapperRadio: {
    margin: "0 0 0 4px",
  },
  bottomAction: {
    margin: 16,
    display: "flex",
    justifyContent: "space-between",
  },
  checkbox: {
    // padding: 4,
  },
});

const CheckBoxList: FC<any> = (props) => {
  const classes = useStyles();
  const translate = useTranslate();
  const { chunk, permissions, setChoosePermissions } = props;
  return (
    <>
      {/* {chunk.length > 1 && (
        <FormControlLabel
          control={
            <Checkbox
              checked={_.every(
                chunk,
                (inChunk) =>
                  _.findIndex(permissions, (inPer) =>
                    _.isEqual(inPer, inChunk)
                  ) > -1
              )}
              className={classes.checkbox}
              onChange={(e) => {
                if (e.target.checked) {
                  setChoosePermissions((pers: string[][]) =>
                    _.union([...pers, ...chunk])
                  );
                } else {
                  setChoosePermissions((pers: string[][]) =>
                    _.reject(
                      pers,
                      (per) =>
                        _.findIndex(chunk, (inChunk: string[]) =>
                          _.isEqual(per, inChunk)
                        ) > -1
                    )
                  );
                }
              }}
            />
          }
          label={translate(`resources.permissions.fields.all`)}
        />
      )} */}

      {_.map(chunk, ([resource, action]: string[]) => (
        <Checkbox
          value={[resource, action]}
          checked={setChecked(permissions, [resource, action])}
          className={classes.checkbox}
          onChange={(e) => {
            if (e.target.checked) {
              setChoosePermissions((pers: string[][]) => [
                ...pers,
                [resource, action],
              ]);
            } else {
              setChoosePermissions((pers: string[][]) =>
                _.reject(pers, (per) => _.isEqual(per, [resource, action]))
              );
            }
          }}
        />
      ))}
    </>
  );
};

const GrantPermissions: FC<IProps> = (props) => {
  const classes = useStyles();
  const translate = useTranslate();
  const { userId } = props.match.params;
  const { refresh, setRefresh } = useCustomResfresh();
  const [permissions, setChoosePermissions] = useState<string[][]>([]);
  const { loading, data } = useApiFetch("/api/permissions", [], {
    defaultValue: [],
  });
  const {
    loading: loadingUser,
    data: userPermissions,
  } = useApiFetch(`/api/permissions/${userId}`, [refresh, userId]);

  useEffect(() => {
    setChoosePermissions(userPermissions);
  }, [userPermissions]);

  const { mutate } = useApiMutate(
    "/api/permissions",
    {
      userId,
      permissions,
    },
    [permissions, refresh],
    {
      defaultValue: [],
      callback: () => {
        setRefresh();
      },
    }
  );
  if (loading || loadingUser) return <CircularProgress />;

  const chunks = _.chain(data)
    .groupBy((item: any) => item[0].slice(0, 8))
    .values()
    .value();

  return (
    <>
      <Typography variant="h5" component="h2" className={classes.title}>
        {translate("resources.permissions.page.list")}
      </Typography>
      <Card>
        <CardContent>
          {chunks.map((chunk: any) => {
            return (
              <>
                <Grid container spacing={1} className={classes.wrapperLabel}>
                  <CheckBoxList
                    chunk={chunk}
                    permissions={permissions}
                    setChoosePermissions={setChoosePermissions}
                  />
                  <Typography
                    variant="h5"
                    component="h4"
                    className={classes.label}>
                    {translate(
                      `resources.permissions.zones.${chunk[0][0].slice(0, 11)}`
                    )}
                  </Typography>
                </Grid>
              </>
            );
          })}
        </CardContent>
        <div className={classes.bottomAction}>
          <Button
            color="primary"
            component="span"
            variant="contained"
            label="pos.action.save"
            onClick={() => {
              mutate();
            }}>
            <DoneIcon style={{ fontSize: "20" }} />
          </Button>
          <Button
            variant="text"
            style={{ color: "red" }}
            label="pos.action.reset"
            onClick={() => {
              setChoosePermissions(userPermissions);
            }}>
            <CancelIcon style={{ fontSize: "20" }} />
          </Button>
        </div>
      </Card>
    </>
  );
};

export default GrantPermissions;
