import { Box, Card, CardContent } from "@material-ui/core";
import * as _ from "lodash";
import React, {useState } from "react";
import {
  ArrayInput,
  AutocompleteInput,
  Create as CreateAdmin,
  FormDataConsumer,
  FormWithRedirect,
  NumberInput,
  ReferenceInput,
  required,
  SelectInput,
  SimpleFormIterator,
  TextInput,
  Toolbar,
} from "react-admin";
import UploadImage from "../components/UploadImage";
import ListActions from "./ListActions";

export enum HomeBlockModel {
  Category = "categories",
  Course = "courses",
  Contest = "contest",
}

export const HomeBlockModelName: { [a: string]: string } = {
  [HomeBlockModel.Category]: "Category",
  [HomeBlockModel.Course]: "Khóa học",
  [HomeBlockModel.Contest]: "Bài thi",
};

export const Form = (props: any) => {
  const initLang = [
    {id: "en-UK" , name : "Tiếng Anh"},
    {id: "ko-KR" , name : "Tiếng Hàn"},
    {id: "ja-JP" , name : "Tiếng Nhật"},
    {id: "zh-CN" , name : "Tiếng Trung"},

  ]
  const [languages] = useState(initLang);
  return (
    <FormWithRedirect
      {...props}
      initialValues={{
        model: HomeBlockModel.Course,
      }}
      render={(formProps: any) => {
        return (
          <Card>
            <form>
              <CardContent>
                <Box display={{ md: "block", lg: "flex" }}>
                  <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                    <TextInput source="title" fullWidth />
                  </Box>
                  <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                    <NumberInput source="sequenceNo" fullWidth />
                  </Box>
                  <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                    <SelectInput
                      fullWidth
                      source="language"
                      label="Ngôn ngữ"
                      validate={requiredValidate}
                      resource="code"
                      choices={initLang}
                    />
                  </Box>
                </Box>
                <Box display={{ md: "block", lg: "flex" }}>
                  <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                    <ArrayInput source="blocks" resource="homeblocks">
                      <SimpleFormIterator>
                        <SelectInput
                          fullWidth
                          source="model"
                          validate={requiredValidate}
                          label="Chọn loại"
                          choices={_.keys(HomeBlockModel).map((key: any) => ({
                            id: (HomeBlockModel as any)[key],
                            name: (HomeBlockModelName as any)[
                              (HomeBlockModel as any)[key]
                            ],
                          }))}
                        />
                        <FormDataConsumer>
                          {(props: any) => {
                            const { getSource, scopedFormData } = props;
                            return (
                              <>
                                {(scopedFormData || {}).model ===
                                  HomeBlockModel.Course && (
                                  <ReferenceInput
                                    label="Khóa học"
                                    fullWidth
                                    validate={requiredValidate}
                                    source={getSource("sourceId")}
                                    reference="courses"
                                    record={scopedFormData}
                                    filterToQuery={(searchText: any) => {
                                      return _.isEmpty(_.trim(searchText))
                                        ? null
                                        : {
                                            name: searchText,
                                          };
                                    }}
                                  >
                                    <AutocompleteInput
                                      optionText="name"
                                      fullWidth
                                    />
                                  </ReferenceInput>
                                )}

                                {(scopedFormData || {}).model ===
                                  HomeBlockModel.Category && (
                                  <ReferenceInput
                                    label="Category"
                                    fullWidth
                                    record={scopedFormData}
                                    source={getSource("sourceId")}
                                    reference="categories"
                                    filterToQuery={(searchText: any) => {
                                      return _.isEmpty(_.trim(searchText))
                                        ? null
                                        : {
                                            name: searchText,
                                          };
                                    }}
                                  >
                                    <AutocompleteInput
                                      optionText="name"
                                      fullWidth
                                    />
                                  </ReferenceInput>
                                )}
                                {(scopedFormData || {}).model ===
                                  HomeBlockModel.Contest && (
                                  <>
                                    <SelectInput
                                      label="Loại"
                                      fullWidth
                                      record={scopedFormData}
                                      source={getSource("sourceId")}
                                      choices={[
                                        {
                                          id: "offline",
                                          name: "Offline",
                                        },
                                        {
                                          id: "online",
                                          name: "Online",
                                        },
                                      ]}
                                    />
                                    <TextInput
                                      label="Tên hiển thị"
                                      fullWidth
                                      record={scopedFormData}
                                      source={getSource("name")}
                                    />
                                    <UploadImage
                                      label="Ảnh"
                                      fullWidth
                                      record={scopedFormData}
                                      source={getSource("imageUrl")}
                                    />
                                  </>
                                )}
                              </>
                            );
                          }}
                        </FormDataConsumer>
                      </SimpleFormIterator>
                    </ArrayInput>
                  </Box>
                </Box>
              </CardContent>
              <Toolbar
                record={formProps.record}
                basePath={formProps.basePath}
                invalid={formProps.invalid}
                handleSubmit={formProps.handleSubmit}
                saving={formProps.saving}
                undoable={false}
                resource="notifications"
              />
            </form>
          </Card>
        );
      }}
    />
  );
};
const Create = (props: any) => {
  return (
    <CreateAdmin undoable={false} {...props} actions={<ListActions />}>
      <Form />
    </CreateAdmin>
  );
};

const requiredValidate = [required()];

export default Create;
