import Card from '@material-ui/core/Card';
import CardIcon from './CardIcon';
import DollarIcon from '@material-ui/icons/Domain';
import React, { FC } from 'react';
import Typography from '@material-ui/core/Typography';
import { useStyles } from './UnitTotal';
import { useTranslate } from 'react-admin';

interface Props {
  value?: number;
}

const CourseTotal: FC<Props> = ({ value }) => {
  const translate = useTranslate();
  const classes = useStyles();
  return (
    <div className={classes.main}>
      <Card className={classes.card}>
        <CardIcon Icon={DollarIcon} bgColor="#303f9f" />
        <Typography className={classes.title} color="textSecondary">
          {translate("pos.dashboard.course_total")}
        </Typography>
        <Typography className={classes.value} variant="h5" component="h2">
          {value}
        </Typography>
      </Card>
    </div>
  );
};

export default CourseTotal;
