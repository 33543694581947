import "./App.css";

import { Admin, Resource } from "react-admin";
import { Layout, Login } from "./layout";

import { Dashboard } from "./dashboard";
import React from "react";
import admins from "./admins";
import authProvider from "./authProvider";
import cardcodes from "./cardcodes";
import cards from "./cards";
import categories from "./categories";
import courses from "./courses";
import customRoutes from "./routes";
import dataProvider from "./dataProvider";
import polyglotI18nProvider from "ra-i18n-polyglot";
import posts from "./posts";
import questions from "./questions";
import sections from "./sections";
import studentcardcodes from "./studentcardcodes";
import students from "./students";
import themeReducer from "./themeReducer";
import units from "./units";
import users from "./users";
import versions from "./versions";
import viMessages from "./i18n/vi";
import notifications from "./notifications";
import homeslides from "./homeslides";
import homeblocks from "./homeblocks";
import homeblocksold from "./homeblocksold";
import slideshow from "./HomeSliderArena";
import courseages from "./courseages";
import systemstatus from "./systemstatus";
import homophones from "./homophones";
import questionreports from "./questionreports";
import grades from "./grades";
import periods from "./periods";
import contests from "./contests";
import pushers from "./pushers";
import statistical from "./statistical";
import advertisements from "./advertisements";
import statisticalSlider from "./statisticalSlider";
import gradesReducer from "./gradesReducer";

const i18nProvider = polyglotI18nProvider((locale) => {
  if (locale === "fr") {
    return import("./i18n/fr").then((messages) => messages.default);
  }

  // Always fallback on english
  return viMessages;
}, "vi");

const App = () => {
  return (
    <Admin
      dataProvider={dataProvider}
      customReducers={{ theme: themeReducer, grades: gradesReducer }}
      customRoutes={customRoutes}
      authProvider={authProvider}
      dashboard={() => <Dashboard />}
      loginPage={Login}
      layout={Layout}
      i18nProvider={i18nProvider}
    >
      <Resource {...cards} />
      <Resource {...students} />
      <Resource {...studentcardcodes} />
      <Resource {...cardcodes} />
      <Resource {...units} />
      <Resource {...sections} />
      <Resource {...courses} />
      <Resource {...users} />
      <Resource {...admins} />
      <Resource {...categories} />
      <Resource {...posts} />
      <Resource {...questions} />
      <Resource {...versions} />
      <Resource {...notifications} />
      <Resource {...pushers} />
      <Resource {...homeslides} />
      <Resource {...slideshow} />
      <Resource {...statistical} />
      <Resource {...statisticalSlider} />

      <Resource {...advertisements} />

      <Resource {...homeblocks} />
      <Resource {...courseages} />
      <Resource {...systemstatus} />
      <Resource {...homophones} />
      <Resource {...questionreports} />
      <Resource {...grades} />
      <Resource {...periods} />
      <Resource {...contests} />
      <Resource {...homeblocksold} />
    </Admin>
  );
};

export default App;
