import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { ElementAddress } from "../Create";
import { MenuProps } from "./MultipleSelect";
import { customHttpClientAdmin } from "../../dataProvider";

interface IProps {
  data: any;
  title: string;
  setDataProAndDist: any;
  elementList: any;
  indexItem: number;
  dataDefault?: any;
  idProvince?: number;
}

const SelectedInputCheck = ({
  data,
  title,
  setDataProAndDist,
  elementList,
  indexItem,
  dataDefault,
  idProvince,
}: IProps) => {
  const [value, setValue] = React.useState<any>(
    Number(idProvince) > 0 ? idProvince : "-1"
  );
  const [listDistrist, setListDistrist] = React.useState<string[]>([]);
  const [defaultValue, setDefautValue] = React.useState<string>(
    dataDefault ? dataDefault : ""
  );

  React.useEffect(() => {
    setValue(idProvince ? idProvince : "-1");
  }, [idProvince]);

  React.useEffect(() => {
    setDefautValue(dataDefault ? dataDefault : "");
  }, [dataDefault]);

  const handleChange = (event: SelectChangeEvent) => {
    setValue(event.target.value);

    const selectedProvince = data.find(
      (province: any) => province.id == event.target.value
    );
    setDefautValue(selectedProvince.name);
  };

  React.useEffect(() => {
    if (value > 0) {
      // console.log("asdasdsa 111");

      setDataProAndDist(
        elementList?.map((item: ElementAddress, i: number) => {
          if (i === indexItem) {
            return {
              ...item,
              provices: value,
              listDistrist: listDistrist,
            };
          } else return item;
        })
      );
    } else {
      console.log("asdasdsa2 222");

      setDataProAndDist(
        elementList?.map((item: ElementAddress, i: number) => {
          if (i === indexItem) {
            return {
              ...item,
              provices: value,
              arrayDistrist: listDistrist,
            };
          } else return item;
        })
      );
    }
  }, [listDistrist]);

  React.useEffect(() => {
    // setDataProAndDist(value);
    customHttpClientAdmin(`/school/${value}/districts`, {
      method: "POST",
    })
      .then((res: any) => res.json)
      .then((response: any) => {
        if (response.status === 200) {
          setListDistrist(response.data);
        } else console.log(response);
      })
      .catch((error: any) => {
        console.log(error);
      });
  }, [value]);

  return (
    <div>
      <FormControl
        variant="standard"
        sx={{
          m: 1,
          minWidth: 200,
          marginTop: -1.2,
        }}
        style={{
          backgroundColor: "#e5e5e5",
        }}>
        <InputLabel
          id="demo-simple-select-standard-label"
          style={{ paddingLeft: 5 }}>
          {title}
        </InputLabel>
        <Select
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          value={value}
          renderValue={(value: any) => <span>{defaultValue}</span>}
          style={{
            paddingLeft: 10,
          }}
          MenuProps={MenuProps}
          onChange={handleChange}
          label={title}>
          {data?.map((item: any, index: number) => (
            <MenuItem value={item.id} key={index}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default React.memo(SelectedInputCheck);
