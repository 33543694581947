import { Box } from "@material-ui/core";
import React from "react";
import {
  ArrayInput,
  required,
  SimpleFormIterator,
  TextInput,
  useTranslate,
} from "react-admin";

export const FillTextMultipleAnswer = (props: any) => {
  const translate = useTranslate();

  return (
    <>
      <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
        <ArrayInput
          source="answerMetas"
          resource="questions"
          label="Câu trả lời"
        >
          <SimpleFormIterator>
            <TextInput
              source="value"
              label={"Đáp án"}
              resource="questions"
              required={requiredValidate}
            />
            <TextInput
              source="value1"
              label={"Từ đồng nghĩa 1"}
              resource="questions"
            />
            <TextInput
              source="value2"
              label={"Từ đồng nghĩa 2"}
              resource="questions"
            />
          </SimpleFormIterator>
        </ArrayInput>
      </Box>
    </>
  );
};

const requiredValidate = [required()];

export default FillTextMultipleAnswer;
