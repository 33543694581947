import Card from '@material-ui/core/Card';
import CardIcon from './CardIcon';
import DollarIcon from '@material-ui/icons/FolderShared';
import React, { FC } from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslate } from 'react-admin';


interface Props {
  value?: number;
}

export const useStyles = makeStyles({
  main: {
    flex: "1",
    marginRight: "1em",
    marginTop: 25,
  },
  card: {
    overflow: "inherit",
    textAlign: "center",
    padding: 16,
    minHeight: 52,
  },
  title: {
    fontSize: 20,
    fontWeight: 600,
  },
  value: {
    fontSize: 30,
    fontWeight: 800,
  },
});

const UnitTotal: FC<Props> = ({ value }) => {
  const translate = useTranslate();
  const classes = useStyles();
  return (
    <div className={classes.main}>
      <Card className={classes.card}>
        <CardIcon Icon={DollarIcon} bgColor="#ab47bc" />
        <Typography className={classes.title} color="textSecondary">
          {translate("pos.dashboard.unit_total")}
        </Typography>
        <Typography className={classes.value} variant="h5" component="h2">
          {value}
        </Typography>
      </Card>
    </div>
  );
};

export default UnitTotal;
