import { Theme, useMediaQuery } from "@material-ui/core";
import PageBuilderIcon from "@material-ui/icons/CardMembershipOutlined";
import React, { FC, useState } from "react";
import { DashboardMenuItem, MenuItemLink, useTranslate } from "react-admin";
import { useSelector } from "react-redux";
import admins from "../admins";
import cardcodes from "../cardcodes";
import cards from "../cards";
import categories from "../categories";
import courses from "../courses";
import { useCanAccess } from "../helpers";
import homeslides from "../homeslides";
import notifications from "../notifications";
import posts from "../posts";
import studentcardcodes from "../studentcardcodes";
import students from "../students";
import { AppState } from "../types";
import SubMenu from "./SubMenu";
import homeblocks from "../homeblocks";
import homeblocksold from "../homeblocksold";
import courseages from "../courseages";
import systemstatus from "../systemstatus";
import homophones from "../homophones";
import questionreports from "../questionreports";
import contests from "../contests";
import slideshow from "../HomeSliderArena";
import Advertising from "../advertisements";

interface Props {
  dense: boolean;
  logout: () => void;
  onMenuClick: () => void;
}

const Menu: FC<Props> = ({ onMenuClick, dense, logout }) => {
  const translate = useTranslate();
  const canAccessAdmin = useCanAccess({ action: "read", resource: "admin" });
  const canAccessPost = useCanAccess({ action: "read", resource: "post" });
  const canAccessReport = useCanAccess({ action: "read", resource: "report" });
  const canAccessContest = useCanAccess({
    action: "read",
    resource: "contest",
  });
  const canAccessStudent = useCanAccess({
    action: "read",
    resource: "student",
  });
  const canAccessStudentCardCode = useCanAccess({
    action: "read",
    resource: "studentcardcode",
  });
  const canAccessCardCode = useCanAccess({
    action: "read",
    resource: "cardcode",
  });
  const canAccessCard = useCanAccess({ action: "read", resource: "card" });
  const canAccessCourse = useCanAccess({ action: "read", resource: "course" });
  const canAccessCategory = useCanAccess({
    action: "read",
    resource: "category",
  });

  const canAccessVersion = useCanAccess({
    action: "read",
    resource: "version",
  });

  const canAccessNotification = useCanAccess({
    action: "read",
    resource: "noti",
  });

  const canAccessPageBuilder = useCanAccess({
    action: "read",
    resource: "pagebuilder",
  });

  const [state, setState] = useState<any>({
    pagebuilder: false,
  });

  const isXSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("xs")
  );

  const handleToggle = (menu: string) => {
    setState((state: any) => ({ ...state, [menu]: !state[menu] }));
  };

  const open = useSelector((state: AppState) => state.admin.ui.sidebarOpen);
  useSelector((state: AppState) => state.theme); // force rerender on theme change

  return (
    <>
      <DashboardMenuItem onClick={onMenuClick} sidebarIsOpen={open} />

      {canAccessStudent && (
        <MenuItemLink
          to={`/students`}
          primaryText={translate(`resources.students.name`, {
            smart_count: 2,
          })}
          leftIcon={<students.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
      )}

      {canAccessStudentCardCode && (
        <MenuItemLink
          to={`/studentcardcodes`}
          primaryText={translate(`resources.studentcardcodes.name`, {
            smart_count: 2,
          })}
          leftIcon={<studentcardcodes.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
      )}

      {canAccessCardCode && (
        <MenuItemLink
          to={`/cardcodes`}
          primaryText={translate(`resources.cardcodes.name`, {
            smart_count: 2,
          })}
          leftIcon={<cardcodes.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
      )}
      {canAccessCard && (
        <MenuItemLink
          to={`/cards`}
          primaryText={translate(`resources.cards.name`, {
            smart_count: 2,
          })}
          leftIcon={<cards.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
      )}

      {canAccessCourse && (
        <MenuItemLink
          to={`/courses`}
          primaryText={translate(`resources.courses.name`, {
            smart_count: 2,
          })}
          leftIcon={<courses.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
      )}

      {canAccessPost && (
        <MenuItemLink
          to={`/posts`}
          primaryText={translate(`resources.posts.name`, {
            smart_count: 2,
          })}
          leftIcon={<posts.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
      )}

      {canAccessCategory && (
        <MenuItemLink
          to={`/categories`}
          primaryText={translate(`resources.categories.name`, {
            smart_count: 2,
          })}
          leftIcon={<categories.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
      )}
      {canAccessAdmin && (
        <MenuItemLink
          to={`/admins`}
          primaryText={translate(`resources.admins.name`, {
            smart_count: 2,
          })}
          leftIcon={<admins.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
      )}
      {canAccessVersion && (
        <MenuItemLink
          to={`/versions`}
          primaryText={translate(`resources.versions.name`, {
            smart_count: 2,
          })}
          leftIcon={<admins.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
      )}

      {canAccessNotification && (
        <SubMenu
          handleToggle={() => handleToggle("notification")}
          isOpen={state.notification}
          sidebarIsOpen={open}
          name="Thông báo"
          icon={<PageBuilderIcon />}
          dense={dense}
        >
          <MenuItemLink
            to={`/notifications`}
            primaryText="Thông báo thường"
            leftIcon={<notifications.icon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
            dense={dense}
          />
          <MenuItemLink
            to={`/pushers`}
            primaryText="Thông báo trộn"
            leftIcon={<notifications.icon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
            dense={dense}
          />
        </SubMenu>
      )}
      {canAccessReport && (
        <MenuItemLink
          to={`/questionreports`}
          primaryText="Quản lý report"
          leftIcon={<questionreports.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
      )}

      {canAccessContest && (
        <>
          <SubMenu
            handleToggle={() => handleToggle("allcontests")}
            isOpen={state.allcontests}
            sidebarIsOpen={open}
            name="pos.menu.allcontest"
            icon={<PageBuilderIcon />}
            dense={dense}
          >
            <MenuItemLink
              to={`/grades?mode=online`}
              primaryText={"Online"}
              leftIcon={<contests.icon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />

            <MenuItemLink
              to={`/grades?mode=offline`}
              primaryText={"Offline"}
              leftIcon={<contests.icon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
          </SubMenu>
        </>
      )}

      {canAccessPageBuilder && (
        <SubMenu
          handleToggle={() => handleToggle("pagebuilder")}
          isOpen={state.pagebuilder}
          sidebarIsOpen={open}
          name="pos.menu.pagebuilder"
          icon={<PageBuilderIcon />}
          dense={dense}
        >
          <MenuItemLink
            to={`/homeslides`}
            primaryText={"Home slide"}
            leftIcon={<homeslides.icon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
            dense={dense}
          />
          <MenuItemLink
            to={`/slideshow`}
            primaryText={"Homeslide Arena"}
            leftIcon={<slideshow.icon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
            dense={dense}
          />
          <MenuItemLink
            to={`/advertisements`}
            primaryText={"Pop-up"}
            leftIcon={<Advertising.icon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
            dense={dense}
          />
          <MenuItemLink
            to={`/homeblocks`}
            primaryText={"Home block"}
            leftIcon={<homeblocks.icon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
            dense={dense}
          />
          <MenuItemLink
            to={`/homeblocksold`}
            primaryText={"Home block cũ"}
            leftIcon={<homeblocksold.icon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
            dense={dense}
          />
          <MenuItemLink
            to={`/courseages`}
            primaryText={"Khóa học theo tuổi"}
            leftIcon={<courseages.icon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
            dense={dense}
          />
          <MenuItemLink
            to={`/systemstatus`}
            primaryText={"Thông báo bảo trì"}
            leftIcon={<systemstatus.icon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
            dense={dense}
          />
          <MenuItemLink
            to={`/homophones`}
            primaryText={"Từ đồng nghĩa"}
            leftIcon={<homophones.icon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
            dense={dense}
          />
        </SubMenu>
      )}

      {/* <MenuItemLink
        to={`/questions`}
        primaryText={translate(`resources.questions.name`, {
          smart_count: 2,
        })}
        leftIcon={<questions.icon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
        dense={dense}
      /> */}
      {/*
      {isXSmall && (
        <MenuItemLink
          to="/configuration"
          primaryText={translate("pos.configuration")}
          leftIcon={<SettingsIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
      )} */}
      {isXSmall && logout}
    </>
  );
};

export default Menu;
