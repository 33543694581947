import _ from "lodash";
import React, { FC } from "react";
import {
  Filter,
  NumberInput,
  TextInput,
  ReferenceInput,
  AutocompleteInput,
} from "react-admin";

const ListFilter: FC<any> = (props) => (
  <Filter {...props}>
    <NumberInput source="id" />
    <TextInput alwaysOn source="code" />
    <TextInput alwaysOn source="series" />
    <ReferenceInput
      source="cardId"
      reference="cards"
      resource="cards"
      filter={{ onSale: 1 }}
      filterToQuery={(searchText: any) => {
        return _.isEmpty(_.trim(searchText))
          ? null
          : {
              name: searchText,
            };
      }}>
      <AutocompleteInput optionText="displayName" fullWidth />
    </ReferenceInput>
    <TextInput source="createdAt" />
  </Filter>
);

export default ListFilter;
