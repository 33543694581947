import { Box, Button } from "@material-ui/core";
import React from "react";
import {
  Datagrid,
  Edit as EditAdmin,
  EditButton,
  ReferenceManyField,
  Show,
  TextField,
  TopToolbar,
} from "react-admin";
import { Link } from "react-router-dom";
import { Form } from "./Create";
import ListActions from "./ListActions";

const ContestShowActions = ({ id }: any) => (
  <TopToolbar>
    <Button
      className="MuiButton-textPrimary MuiButton-textSizeSmall"
      component={Link}
      to={{
        pathname: "/contests/create",
        state: { record: { periodId: parseInt(id) } },
      }}
    >
      + Create
    </Button>
  </TopToolbar>
);

const ContestShow = (props: any) => {
  return (
    <Show {...props} actions={<ContestShowActions {...props} />} title={" "}>
      <ReferenceManyField
        label="contests"
        reference="contests"
        target="periodId"
        perPage={1000}
      >
        <Datagrid>
          <TextField source="name" label="Tên đề  thi" />
          <EditButton />
        </Datagrid>
      </ReferenceManyField>
    </Show>
  );
};
const Edit = (props: any) => {
  return (
    <div style={{ flex: 1, display: "flex" }}>
      <Box flex={2} ml={{ xs: 0, sm: "0.5em" }}>
        <EditAdmin
          undoable={false}
          component="div"
          {...props}
          actions={<ListActions />}
        >
          <Form />
        </EditAdmin>
      </Box>
      <Box flex={2} ml={{ xs: 0, sm: "0.5em" }}>
        <ContestShow {...props} />
      </Box>
    </div>
  );
};

export default Edit;
