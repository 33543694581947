import { Box } from "@material-ui/core";
import React from "react";
import {
  ArrayInput,
  BooleanInput,
  required,
  SimpleFormIterator,
  TextInput,
} from "react-admin";
import UploadAudio from "../components/UploadAudio";
import UploadImage from "../components/UploadImage";

const GameMutipleChoice = (props: any) => {
  return (
    <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
      <ArrayInput source="answerMetas" resource="questions">
        <SimpleFormIterator>
          <UploadImage
            source="imageUrl"
            label={"Ảnh đáp án"}
            resource="questions"
          />
          <UploadAudio
            source="audioUrl"
            label={"Âm thanh đáp án"}
            resource="questions"
          />
          <TextInput source="value" label="Đáp án chữ" resource="questions" />
          <BooleanInput source="isCorrect" resource="questions" />
        </SimpleFormIterator>
      </ArrayInput>
    </Box>
  );
};

const requiredValidate = [required()];

export default GameMutipleChoice;
