import React, { FC, useEffect, useState } from "react";
import { FieldProps } from "../types";
import { required, SelectInput, useTranslate } from "react-admin";
import { customHttpClient } from "../dataProvider";
import { Box } from "@material-ui/core";

interface Props extends FieldProps<any> {
  className?: string;
  fullWidth?: any;
  resource?: string;
  setVarCheckTimepublic?: any;
  setCourse?: any;
  disabled?: boolean;
}
const requiredValidate = [required()];

type Course = {
  id: string;
  name: string;
};

const CourseList: FC<Props> = ({
  record,
  className,
  setCourse,
  source,
  disabled,
  ...rest
}) => {
  const [courseList, setCourseList] = useState();

  useEffect(() => {
    // customHttpClient(``, {

    let res = customHttpClient(`/crud/courses`, {
      method: "GET",
    })
      .then((res: any) => res.json)
      .then((data: any) => {
        const dataCourse = data?.map((item: Course) => {
          return {
            id: item.id,
            name: item.name,
          };
        });
        setCourseList(dataCourse);
      })
      .catch((error: any) => {
        console.log(error, "error");
      });
  }, []);
  // console.log(courseList);

  return (
    <Box flex={2} ml={{ xs: 0, sm: "0.5em" }}>
      {/* {courseList && ( */}
      <SelectInput
        validate={requiredValidate}
        {...rest}
        source={source}
        label="Chọn khóa học"
        choices={courseList}
        onChange={(e: any) => setCourse(e.target.value)}
        disabled={disabled}
      />
      {/* )} */}
    </Box>
  );
};

export default CourseList;
