import React, { useState } from "react";
import SelectSearch from "react-select-search";
import "./styles.css";

interface PopsData {
  data: any;
  listCourse: any[];
  setId?: any;
  id: any;
}

function SelectCourses(props: PopsData) {
  const { data, listCourse, setId, id } = props;
  const [couresId, setCouresId] = useState(data?.course_id || null);
  //   console.log("props", data?.course_id);
  return (
    <div>
      <SelectSearch
        className="select-search"
        options={listCourse.map((item: any) => {
          return {
            value: item.id,
            name: item.name,
          };
        })}
        value={couresId || id}
        disabled={data?.course_id}
        search
        placeholder="Chọn khóa học"
        onChange={(e) => {
          setId(e);
          setCouresId(e);
          console.log("Select", e);
        }}
      />
    </div>
  );
}

export default SelectCourses;
