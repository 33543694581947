// in ./ResetViewsButton.js
import * as React from "react";
import {
  Button,
  useUpdateMany,
  useRefresh,
  useNotify,
  useUnselectAll,
} from "react-admin";
import { LockOutlined, LockOpenOutlined } from "@material-ui/icons";

const LocksButton: React.FC<any> = ({ selectedIds, isLock }) => {
  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll();
  const [updateMany, { loading }] = useUpdateMany(
    "cardcodes",
    selectedIds,
    { isLocked: isLock },
    {
      onSuccess: () => {
        refresh();
        notify("Khoá thành công");
        unselectAll("cardcodes");
      },
      onFailure: (error: any) =>
        notify("Error: Thao tác khóa, mở khóa không thành công", "warning"),
    }
  );

  return (
    <Button
      label={isLock ? "Khóa" : "Mở khóa"}
      disabled={loading}
      onClick={updateMany}>
      {isLock ? <LockOutlined /> : <LockOpenOutlined />}
    </Button>
  );
};

export default LocksButton;
