import React, { FC, useEffect } from "react";
import { useInput } from "react-admin";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import EditorToolbar, { modules, formats } from "./RichTextToolbar";

import { Editor } from "@tinymce/tinymce-react";
import { customHttpClient } from "../dataProvider";
import { required } from "react-admin";

const QuillVideo = Quill.import("formats/video");
const BlockEmbed = Quill.import("blots/block/embed");

const VIDEO_ATTRIBUTES = ["height", "width"];

// provides a custom div wrapper around the default Video blot
class Video extends BlockEmbed {
  static create(value: any) {
    const iframeNode = QuillVideo.create(value);
    iframeNode.style.position = "absolute";
    iframeNode.style.top = 0;
    iframeNode.style.left = 0;
    iframeNode.style.width = "100%";
    iframeNode.style.height = "100%";
    iframeNode.style.border = 0;

    const node = super.create();
    node.appendChild(iframeNode);

    node.style.position = "relative";
    node.style.overflow = "hidden";
    node.style["padding-top"] = "56.25%";
    return node;
  }

  static formats(domNode: any) {
    const iframe = domNode.getElementsByTagName("iframe")[0];
    return VIDEO_ATTRIBUTES.reduce(function (formats: any, attribute) {
      if (iframe.hasAttribute(attribute)) {
        formats[attribute] = iframe.getAttribute(attribute);
      }
      return formats;
    }, {});
  }

  static value(domNode: any) {
    return domNode.getElementsByTagName("iframe")[0].getAttribute("src");
  }

  format(name: string, value: any) {
    if (VIDEO_ATTRIBUTES.indexOf(name) > -1) {
      if (value) {
        this.domNode.setAttribute(name, value);
      } else {
        this.domNode.removeAttribute(name);
      }
    } else {
      super.format(name, value);
    }
  }
}

Video.blotName = "video";
Video.className = "ql-video-wrapper";
Video.tagName = "DIV";

Quill.register(Video, true);

// export const RichTextInput: FC<any> = (props) => {
//   // const editor = useRef(null);
//   const inputProps = useInput(props);
//   const {
//     input,
//     meta: { touched, error },
//   } = inputProps;

//   const config = {
//     readonly: false, // all options from https://xdsoft.net/jodit/doc/
//   } as any;

//   return (
//     <div className="text-editor">
//       <EditorToolbar />
//       <ReactQuill
//         theme="snow"
//         placeholder={props.placeholder}
//         modules={modules}
//         value={input.value}
//         onChange={(value) => {
//           input.onChange({ target: { value } });
//         }}
//       />
//     </div>
//   );
// };

export const RichTextInput: FC<any> = (props) => {
  // const editor = useRef(null);
  const inputProps = useInput(props);
  const requiredValidate = [required()];

  const {
    input,
    meta: { touched, error },
  } = inputProps;

  const config = {
    readonly: false, // all options from https://xdsoft.net/jodit/doc/
  } as any;

  return (
    <Editor
      tinymceScriptSrc='/tinymce/tinymce.min.js'
      apiKey="s1vqarrumcibda69nl55nhckmz87w4a7w8382tjfvvhigahr"
      disabled={props.checkDisableForm}
      value={input.value}
      init={{
        height: props.height ? props.height : 500,
        menubar: false,
        /* without images_upload_url set, Upload tab won't show up*/
        images_upload_url: "/api/upload/image",

        /* we override default upload handler to simulate successful upload*/
        // images_upload_handler: (blobInfo: any, success: any, failure: any) => {
        //   console.log(blobInfo);

        //   // setTimeout(function () {
        //   //   /* no matter what you upload, we will turn it into TinyMCE logo :)*/
        //   //   success("http://moxiecode.cachefly.net/tinymce/v9/images/logo.png");
        //   // }, 2000);

        //   const formData = new FormData();
        //   formData.append("file", blobInfo.blob());

        //   customHttpClient("/api/upload/image", {
        //     method: "POST",
        //     body: formData,
        //   })
        //     .then((res: any) => res.json)
        //     .then((message: any) => {
        //       if (message.data.url.includes("http")) {
        //         success(message.data.url);
        //       } else {
        //         success(process.env.REACT_APP_API_BASE_URL + message.data.url);
        //       }
        //     });
        // },
        images_upload_handler(blobInfo, progress) {
          return new Promise((resolve, reject) => {
            const formData = new FormData();
            formData.append("file", blobInfo.blob());

            customHttpClient("/api/upload/image", {
              method: "POST",
              body: formData,
            })
              .then((res: any) => res.json)
              .then((message: any) => {
                if (message.data.url.includes("http")) {
                  resolve(message.data.url);
                } else {
                  resolve(
                    process.env.REACT_APP_API_BASE_URL + message.data.url
                  );
                }
              });
          });
        },
        plugins:
          "print preview powerpaste casechange importcss tinydrive searchreplace autolink autosave save directionality advcode visualblocks visualchars fullscreen image link media mediaembed template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists checklist wordcount tinymcespellchecker a11ychecker imagetools textpattern noneditable help formatpainter permanentpen pageembed charmap tinycomments mentions quickbars linkchecker emoticons advtable code",
        mobile: {
          plugins:
            "print preview powerpaste casechange importcss tinydrive searchreplace autolink autosave save directionality advcode visualblocks visualchars fullscreen image link media mediaembed template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists checklist wordcount tinymcespellchecker a11ychecker textpattern noneditable help formatpainter pageembed charmap mentions quickbars linkchecker emoticons advtable",
        },
        toolbar:
          "undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange permanentpen formatpainter removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | image media pageembed link anchor codesample | a11ycheck ltr rtl code",
        tinycomments_mode: "embedded",
      }}
      onEditorChange={input.onChange}
    />
  );
};
