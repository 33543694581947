import ListActions from "./ListActions";
import React from "react";
import { Edit as EditAdmin } from "react-admin";
import { Form } from "./Create";

const Edit = (props: any) => {
  return (
    <EditAdmin
      component="div"
      undoable={false}
      {...props}
      actions={<ListActions />}>
      <Form />
    </EditAdmin>
  );
};

export default Edit;
