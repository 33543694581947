import * as reactAdmin from "react-admin";
import _ from "lodash";
import CourseTotal from "./CourseTotal";
import dataProvider from "../dataProvider";
import React, { FC, useCallback, useEffect, useState } from "react";
import StudentTotal from "./StudentTotal";
import UnitTotal from "./UnitTotal";
import { CSSProperties } from "@material-ui/core/styles/withStyles";

const styles = {
  flex: { display: "flex" },
  flexColumn: { display: "flex", flexDirection: "column" },
  leftCol: { flex: 1, marginRight: "1em" },
  rightCol: { flex: 1, marginLeft: "1em" },
  singleCol: { marginTop: "2em", marginBottom: "2em" },
};

const Dashboard: FC<any> = () => {
  const [studentCount, setStudentCount] = useState(0);
  const [courseCount, setCourseCount] = useState(0);
  const [unitCount, setUnitCount] = useState(0);

  const fetchCounts = useCallback(
    async (resouces: string, setter: (arg0: any) => void) => {
      const record = await dataProvider(reactAdmin.GET_LIST, resouces, {
        pagination: { page: 1, perPage: 1 },
        filter: {},
      });
      setter(_.get(record, "total", 0));
    },
    []
  );
  useEffect(() => {
    fetchCounts("students", setStudentCount);
    fetchCounts("courses", setCourseCount);
    fetchCounts("units", setUnitCount);
  }, [fetchCounts]);

  return (
    <div>
      <div style={styles.flexColumn as CSSProperties}>
        <div style={styles.flex}>
          <StudentTotal value={studentCount} />
          <CourseTotal value={courseCount} />
          <UnitTotal value={unitCount} />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
