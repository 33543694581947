import { Box, Card, CardContent } from "@material-ui/core";
import * as _ from "lodash";
import React, { FC } from "react";
import {
  AutocompleteInput,
  Create as CreateAdmin,
  DeleteButton,
  FormWithRedirect,
  NumberInput,
  ReferenceInput,
  required,
  SaveButton,
  SelectInput,
  TextInput,
  Toolbar,
  useTranslate,
} from "react-admin";
import { useHistory, useLocation } from "react-router";
import { ContestMode } from "./List";
import ListActions from "./ListActions";

export const Form = (props: any) => {
  const { record } = props;
  const translate = useTranslate();

  return (
    <FormWithRedirect
      {...props}
      initialValues={{
        duration: 0,
      }}
      render={(formProps: any) => (
        <Card>
          <form>
            <CardContent>
              <Box
                display={{ md: "block", lg: "flex" }}
                style={{ alignItems: "center" }}
              >
                <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                  <TextInput
                    type="text"
                    source="name"
                    label="Tên đề"
                    validate={requiredValidate}
                    resource="contests"
                    fullWidth
                  />
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                  <NumberInput
                    fullWidth
                    source="duration"
                    validate={requiredValidate}
                    resource="contests"
                  />
                </Box>

                <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                  <SelectInput
                    fullWidth
                    source="status"
                    label="Trạng thái"
                    validate={requiredValidate}
                    resource="contests"
                    choices={[
                      { id: "publish", name: "publish" },
                      { id: "private", name: "draft" },
                    ]}
                  />
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                  <ReferenceInput
                    source="periodId"
                    disabled
                    reference="periods"
                    resource="periods"
                    label="Kỳ học"
                    validate={requiredValidate}
                    filterToQuery={(searchText: any) => {
                      return _.isEmpty(_.trim(searchText))
                        ? null
                        : { name: searchText };
                    }}
                  >
                    <AutocompleteInput
                      fullWidth
                      validate={requiredValidate}
                      optionText={"name"}
                    />
                  </ReferenceInput>
                </Box>
              </Box>
              <Box
                display={{ md: "block", lg: "flex" }}
                style={{ alignItems: "center" }}
              >
                <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                  <TextInput
                    type="text"
                    source="expertise"
                    label="Ký năng"
                    validate={requiredValidate}
                    resource="contests"
                    fullWidth
                  />
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}></Box>
                <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}></Box>
                <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}></Box>
              </Box>
            </CardContent>
            <Toolbar
              record={formProps.record}
              basePath={formProps.basePath}
              invalid={formProps.invalid}
              handleSubmit={formProps.handleSubmit}
              saving={formProps.saving}
              undoable={false}
              resource="contests"
            >
              <SaveButton
                saving={formProps.saving}
                redirect={`/periods/${formProps.record.periodId}`}
                handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
              />
              {formProps.isEdit && <DeleteButton undoable={false} />}
            </Toolbar>
          </form>
        </Card>
      )}
    />
  );
};
const Create: FC = (props: any) => {
  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const mode = params.get("mode") || ContestMode.Online;

  return (
    <CreateAdmin
      {...props}
      undoable={false}
      actions={<ListActions mode={mode} />}
    >
      <Form />
    </CreateAdmin>
  );
};

const requiredValidate = [required()];

export default Create;
