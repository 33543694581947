import { Box, Card, CardContent } from "@material-ui/core";
import {
  Create as CreateAdmin,
  FormWithRedirect,
  NumberInput,
  Toolbar,
  required,
} from "react-admin";

import ListActions from "./ListActions";
import React from "react";
import { TextInput } from "react-admin";
import { BooleanInput } from "react-admin";
import UploadImage from "../components/UploadImage";

export const Form = (props: any) => {
  return (
    <FormWithRedirect
      {...props}
      render={(formProps: any) => (
        <Card>
          <form>
            <CardContent>
              <Box display={{ md: "block", lg: "flex" }}>
                <Box flex={3} ml={{ xs: 0, sm: "0.5em" }}>
                  <TextInput
                    source="description"
                    fullWidth={true}
                    label="Mô tả hệ thống bảo trì"
                    resource="systemstatus"
                    rows={3}
                  />
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                  <UploadImage source="imageUrl" />
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                  <BooleanInput
                    source="isMaintaining"
                    fullWidth={true}
                    label="Bật tắt bảo trì"
                    resource="systemstatus"
                  />
                </Box>
              </Box>
            </CardContent>
            <Toolbar
              record={formProps.record}
              basePath={formProps.basePath}
              invalid={formProps.invalid}
              handleSubmit={formProps.handleSubmit}
              saving={formProps.saving}
              undoable={false}
              resource="systemstatus"
            />
          </form>
        </Card>
      )}
    />
  );
};
const Create = (props: any) => {
  return (
    <CreateAdmin undoable={false} {...props} actions={<ListActions />}>
      <Form />
    </CreateAdmin>
  );
};

const requiredValidate = [required()];

export default Create;
