import { CircularProgress } from "@material-ui/core";
import React from "react";
import { ImageField } from "react-admin";

const PreviewInputImage = (props: any) => {
  if (typeof props.record === "string") {
    return (
      <ImageField
        {...props}
        record={{
          [props.source]: props.record.includes("http")
            ? props.record
            : process.env.REACT_APP_API_BASE_URL + props.record,
        }}
      />
    );
  }

  return !!props.record.rawFile ? (
    <>
      {props?.loading && (
        <div
          style={{
            background: "#000",
            position: "absolute",
            height: "100%",
            width: "100%",
            top: 0,
            opacity: 0.4,
            zIndex: 5,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress size={50} color="primary" />
        </div>
      )}
      <ImageField {...props} />
    </>
  ) : (
    <ImageField
      {...props}
      record={{
        ...props.record,
        [props.source]: props.record[props.source].includes("http")
          ? props.record[props.source]
          : process.env.REACT_APP_API_BASE_URL + props.record[props.source],
      }}
    />
  );
};
export default PreviewInputImage;
