import { Box, Card, CardContent, Typography } from "@material-ui/core";
import * as _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { FileInput } from "react-admin";
import {
  ArrayInput,
  AutocompleteArrayInput,
  AutocompleteInput,
  Create as CreateAdmin,
  DeleteButton,
  FormWithRedirect,
  ImageInput,
  NumberInput,
  ReferenceArrayInput,
  ReferenceInput,
  required,
  SaveButton,
  SelectInput,
  SimpleFormIterator,
  TextInput,
  Toolbar,
  useTranslate,
} from "react-admin";
import PreviewInputAudio from "../components/PreviewInputAudio";
import PreviewInputImage from "../components/PreviewInputImage";
import { RichTextInput } from "../components/RichTextInput";
import UploadAudio from "../components/UploadAudio";
import ArrangeWord from "./ArrangeWord";
import Backtalk from "./Backtalk";
import BacktalkConversation from "./BacktalkConversation";
import BacktalkVideo from "./BacktalkVideo";
import CatchingUp from "./CatchingUp";
import FillTextMultiple from "./FillTextMultiple";
import FillTextMultipleAnswer from "./FillTextMultipleAnswer";
import ListActions from "./ListActions";
import MultipleChoice from "./MulitpleChoice";
import PairSentence from "./PairSentence";
import Vocabulary, { ImportVocabulary } from "./Vocabulary";
import { BooleanInput } from "react-admin";
import GameMutipleChoice from "./GameMutipleChoice";
import GameTrain from "./GameTrain";
import { useLocation } from "react-router";
import { customHttpClientAdmin, customHttpClient } from "../dataProvider";
import { useRedirect } from "react-admin";
import { Editor } from "@tinymce/tinymce-react";
import { useNotify } from "react-admin";
import MonoAnswer from "./MonoAnswer";

export const optionRenderer = (choice: any) => {
  if (!choice || !choice.name) return "";
  return `Quiz: ${choice.name}`;
};

export const Form = (props: any) => {
  return (
    <FormWithRedirect
      {...props}
      render={(formProps: any) => (
        <ChildForm {...formProps} isEdit={props.isEdit} />
      )}
    />
  );
};

export enum QuestionMetaKey {
  VOCABULARY = "vocabulary",
  MULTIPLE_CHOICE = "multiple_choice",
  ARRANGEMENT = "arrangement",
  // FILL_WORD = "fill_word",
  FILL_WORD_MULTIPLE = "fill_word_multiple",
  PAIR = "pair",
  FILL_WORD_MULTIPLE_ANSWER = "fill_word_multiple_answer",
  READING = "reading",
  LISTENING = "listening",
  CATCHING_UP = "catching_up",
  BACK_TALK = "back_talk",
  BACK_TALK_VIDEO = "back_talk_video",
  BACK_TALK_CONVERSATION = "back_talk_conversation",
  NEW_ARRANGE = "new_arrange",
  FLASH_CARD = "flash_card",
  SPEAK_NOT_TEXT = "speak_not_text",
}

export enum GameMetaKey {
  GAME_FISH = "game_fish",
  GAME_SPIN = "game_spin",
  GAME_MINE = "game_mine",
  GAME_POKEMON = "game_pokemon",
  GAME_TRAIN = "game_train",
  BricksDownGame = "BricksDownGame",
  BridgeGame = "BridgeGame",
  BasketballGame = "BasketballGame",
  MixAndMatchGame = "MixAndMatchGame",
}

export enum textValidate {
  NEW_ARRANGE = `Vui lòng điền thông tin vào phần Đáp án * theo định dạng \n VD:What /is your/ name`,
  SPEAK_NOT_TEXT = "Vui lòng không để trống thông tin Đáp án",
}

export const ChildForm = (formProps: any) => {
  const translate = useTranslate();
  const { record } = formProps;
  const location = useLocation();
  const redirect = useRedirect();
  const notify = useNotify();
  const [script, setScript] = useState<string>("");
  const params = new URLSearchParams(location.search);
  const [type, setType] = useState<any>(
    QuestionMetaKey.VOCABULARY || formProps.record.type
  );
  const [imgLink, setImgLink] = useState<any>("");
  const [audioLink, setAudioLink] = useState<any>("");
  const [loadAudio, setLoadAudio] = useState<boolean>(false);
  const [loadImg, setloadImg] = useState<boolean>(false);
  const [explain, setExplain] = useState<any>(
    formProps?.record?.questionExplain || null
  );
  const isTypeGame =
    !!params.get("game") || Object.values(GameMetaKey).includes(type);
  const [disabled, setDisabled] = useState<boolean>(false);
  const isContest = params.get("mode") === "contest";
  const collection: any = document.getElementsByClassName("fade-enter");
  for (var i = 0; i < collection.length; ++i) {
    collection[i].style.opacity = "1";
    collection[i].style.transform = "translateX(0)";
  }

  useEffect(() => {
    setType(() => formProps.record.type);
    let audio_pre = _.get(formProps.record, "audios[0].value");
    let img_pre = _.get(formProps.record, "images[0].value");
    setAudioLink(audio_pre);
    setImgLink(img_pre);
  }, [formProps.record]);

  useEffect(() => {
    renderScriptData();
  }, []);

  const uploadAudio = useCallback((file: any) => {
    if (file) {
      setLoadAudio(true);
      let formdata = new FormData();
      formdata.append("file", file);

      customHttpClient(`/api/upload/audio`, {
        method: "POST",
        body: formdata,
      })
        .then((res: any) => {
          if (res?.body) {
            const resAudio = JSON.parse(res?.body);
            const audio = _.get(resAudio, "data.url");
            setAudioLink(audio);
            setLoadAudio(false);
          }
        })
        .catch((error: any) => {
          setLoadAudio(false);
          notify("Audio vượt quá dung lượng cho phép", "error");
        });
    } else {
      setAudioLink("");
    }
  }, []);

  const upLoadAudioImg = (questionId: number, payload: any) => {
    const data = JSON.stringify({
      audio:
        payload.type == QuestionMetaKey.LISTENING
          ? payload.audioUrl || ""
          : audioLink || "",
      image: imgLink || "",
    });
    customHttpClientAdmin(`/admin/questions/${questionId}/update-audio`, {
      method: "POST",
      body: data,
    })
      .then((res: any) => res.json)
      .then((success: any) => {
        console.log("push image and audio success", success);
      });
  };

  const uploadImage = useCallback((file: any) => {
    if (file) {
      setloadImg(true);
      let formdata = new FormData();
      formdata.append("file", file);

      customHttpClient(`/api/upload/image`, {
        method: "POST",
        body: formdata,
      })
        .then((res: any) => {
          if (res?.body) {
            const resImage = JSON.parse(res?.body);
            const img = _.get(resImage, "data.url");
            setImgLink(img);
            setloadImg(false);
          }
        })
        .catch((error: any) => {
          setloadImg(false);
          notify("Ảnh vượt qúa dung lượng cho phép", "error");
        });
    } else {
      setImgLink("");
    }
  }, []);

  const renderScriptData = () => {
    if (formProps.isEdit) {
      customHttpClientAdmin(`/admin/questions/${formProps.record.id}`, {
        method: "GET",
      })
        .then((res: any) => res.json)
        .then((success: any) => {
          setScript(success.script);
        });
    }
  };

  const validateField = (input: any) => {
    if (input?.length) {
      return !(input?.trim() == "");
    } else {
      return false;
    }
  };

  const checkQuestion = (data: any) => {
    console.log("DATA", data);
    switch (data?.type) {
      case QuestionMetaKey.NEW_ARRANGE:
        if (!data?.answerMetas) {
          notify(textValidate.NEW_ARRANGE, {
            type: "warning",
          });
          break;
        }
        if (data?.answerMetas?.length < 1) {
          notify(textValidate.NEW_ARRANGE, {
            type: "warning",
          });
        } else {
          const value = data?.answerMetas[0].value;
          if (!value.includes("/")) {
            notify(textValidate.NEW_ARRANGE, {
              type: "warning",
            });
          } else {
            handleSubmitQuestion(data);
          }
        }
        break;
      case QuestionMetaKey.SPEAK_NOT_TEXT:
        if (!data?.answerMetas) {
          notify(textValidate.SPEAK_NOT_TEXT, {
            type: "warning",
          });
        } else {
          if (validateField(data?.answerMetas[0]?.value)) {
            handleSubmitQuestion(data);
          } else {
            notify(textValidate.SPEAK_NOT_TEXT, {
              type: "warning",
            });
          }
        }
        break;
      default:
        handleSubmitQuestion(data);
        break;
    }
  };

  const handleSubmitQuestion = async (data: any) => {
    try {
      setDisabled(true);
      customHttpClient(
        `/crud/questions${formProps.isEdit ? `/${formProps.record.id}` : ""}`,
        {
          method: formProps.isEdit ? "PATCH" : "POST",
          body: JSON.stringify({
            ...data,
            images: [],
            audios: [],
            audioUrl: null,
            questionText: data?.questionText ? data?.questionText : null,
            questionTitle: data?.questionTitle ? data?.questionTitle : null,
            questionExplain: explain ? explain : null,
          }),
        }
      )
        .then((res: any) => res.json)
        .then((success: any) => {
          if (success) {
            upLoadAudioImg(success?.id, data);
            if (success.type == "listening") {
              customHttpClientAdmin(
                `/admin/questions/${success.id}/update-script`,
                {
                  method: "POST",
                  body: JSON.stringify({
                    script: script,
                  }),
                }
              )
                .then((res: any) => res.json)
                .then((success: any) => {
                  if (success.status == 200) {
                    // TODO: console.log("Ok Thank");
                  }
                });
            }
            if (
              success.type == QuestionMetaKey.NEW_ARRANGE ||
              success.type == QuestionMetaKey.SPEAK_NOT_TEXT
            ) {
              customHttpClientAdmin(
                `/admin/questions/${success.id}/create-arrangement`,
                {
                  method: "POST",
                  body: JSON.stringify({
                    value: data?.answerMetas[0]?.value,
                  }),
                }
              )
                .then((res: any) => res.json)
                .then((success: any) => {
                  if (success.status == 200) {
                  }
                });
            }
            redirect(
              isContest
                ? `/contests/${formProps.record.contestId}`
                : `/units/${formProps.record.unitId}`
            );
            notify("Update element success", "info");
          }
        })
        .catch((error: any) => {
          console.log(error, "error");
        });
      setDisabled(false);
    } catch (error) {
      console.log(error, "error");
      setDisabled(false);
    }
  };
  return (
    <Card>
      <form>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            {translate("resources.questions.fieldGroups.question")}
          </Typography>
          <Box
            display={{ md: "block", lg: "flex" }}
            style={{ alignItems: "center" }}
          >
            <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
              <TextInput
                type="text"
                source="questionText"
                resource="questions"
                fullWidth
              />
            </Box>
            {!isTypeGame && (
              <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                <NumberInput
                  source="duration"
                  label="Thời gian làm"
                  resource="questions"
                  fullWidth
                />
              </Box>
            )}

            {(type !== QuestionMetaKey.VOCABULARY ||
              type !== QuestionMetaKey.FLASH_CARD) && (
              <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                <NumberInput
                  source="sequenceNo"
                  resource="questions"
                  fullWidth
                />
              </Box>
            )}

            <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
              <ReferenceInput
                source={isContest ? "contestId" : "unitId"}
                reference={isContest ? "contests" : "units"}
                resource="questions"
                filterToQuery={(searchText: any) => {
                  return _.isEmpty(_.trim(searchText))
                    ? null
                    : { name: searchText };
                }}
              >
                <AutocompleteInput
                  fullWidth
                  validate={requiredValidate}
                  optionText={optionRenderer}
                />
              </ReferenceInput>
            </Box>
          </Box>
          {(type !== QuestionMetaKey.VOCABULARY ||
            type !== QuestionMetaKey.FLASH_CARD) && (
            <Box
              display={{ md: "block", lg: "flex" }}
              style={{ alignItems: "center" }}
            >
              <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                <ArrayInput source="videos" resource="questions">
                  <SimpleFormIterator>
                    <TextInput source="value" />
                  </SimpleFormIterator>
                </ArrayInput>
              </Box>
              <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                {![
                  QuestionMetaKey.READING,
                  QuestionMetaKey.LISTENING,
                  QuestionMetaKey.FLASH_CARD,
                ].includes(type) && (
                  <FileInput
                    className="audio-preview"
                    source="audios"
                    resource="courses"
                    multiple
                    accept="audio/*"
                    maxSize={20 * 1024 * 1024}
                    onChange={(e: any) => {
                      uploadAudio(e[0]);
                    }}
                  >
                    <PreviewInputAudio source="value" loading={loadAudio} />
                  </FileInput>
                )}
              </Box>
              {![QuestionMetaKey.FLASH_CARD].includes(type) && (
                <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                  <ImageInput
                    source="images"
                    resource="questions"
                    // multiple
                    accept="image/*"
                    maxSize={2 * 1024 * 1024}
                    onChange={(e: any) => {
                      uploadImage(e);
                    }}
                    placeholder={<p>Kéo thả ảnh vào đây</p>}
                  >
                    <PreviewInputImage
                      source="value"
                      title="title"
                      loading={loadImg}
                    />
                  </ImageInput>
                </Box>
              )}
            </Box>
          )}

          {!isTypeGame && (
            <Box
              display={{ md: "block", lg: "flex" }}
              style={{ alignItems: "center" }}
            >
              <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                <TextInput
                  source="questionTitle"
                  fullWidth
                  resource="questions"
                />
              </Box>
            </Box>
          )}
          {/* ==== Phần giải thích định dạng html ==== */}
          <>
            <p className="MuiFormLabel-root">Giải thích</p>
            <Box
              display={{ md: "block", lg: "flex" }}
              style={{ alignItems: "center", marginBottom: 20 }}
            >
              <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                <Editor
                  tinymceScriptSrc='/tinymce/tinymce.min.js'
                  apiKey="s1vqarrumcibda69nl55nhckmz87w4a7w8382tjfvvhigahr"
                  value={explain}
                  init={{
                    height: 300,
                    menubar: false,
                    images_upload_url: "/api/upload/image",
                    // images_upload_handler: (
                    //   blobInfo: any,
                    //   success: any,
                    //   failure: any
                    // ) => {
                    //   console.log('blobInfo',blobInfo)
                    //   const formData = new FormData();
                    //   formData.append("file", blobInfo.blob());

                    //   customHttpClient("/api/upload/image", {
                    //     method: "POST",
                    //     body: formData,
                    //   })
                    //     .then((res: any) => res.json)
                    //     .then((message: any) => {
                    //       if (message.data.url.includes("http")) {
                    //         success(message.data.url);
                    //       } else {
                    //         success(
                    //           process.env.REACT_APP_API_BASE_URL +
                    //             message.data.url
                    //         );
                    //       }
                    //     });
                    // },
                    images_upload_handler(blobInfo, progress) {
                      return new Promise((resolve, reject) => {
                        const formData = new FormData();
                        formData.append("file", blobInfo.blob());

                        customHttpClient("/api/upload/image", {
                          method: "POST",
                          body: formData,
                        })
                          .then((res: any) => res.json)
                          .then((message: any) => {
                            if (message.data.url.includes("http")) {
                              resolve(message.data.url);
                            } else {
                              resolve(
                                process.env.REACT_APP_API_BASE_URL +
                                  message.data.url
                              );
                            }
                          });
                      });
                    },

                    plugins:
                      "print preview powerpaste casechange importcss tinydrive searchreplace autolink autosave save directionality advcode visualblocks visualchars fullscreen image link media mediaembed template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists checklist wordcount tinymcespellchecker a11ychecker imagetools textpattern noneditable help formatpainter permanentpen pageembed charmap tinycomments mentions quickbars linkchecker emoticons advtable code",
                    mobile: {
                      plugins:
                        "print preview powerpaste casechange importcss tinydrive searchreplace autolink autosave save directionality advcode visualblocks visualchars fullscreen image link media mediaembed template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists checklist wordcount tinymcespellchecker a11ychecker textpattern noneditable help formatpainter pageembed charmap mentions quickbars linkchecker emoticons advtable",
                    },
                    toolbar:
                      "undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange permanentpen formatpainter removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | image media pageembed link anchor codesample | a11ycheck ltr rtl code",
                    tinycomments_mode: "embedded",
                  }}
                  onEditorChange={(e) => setExplain(e)}
                />
              </Box>
            </Box>
          </>
          <Typography variant="h6" gutterBottom>
            {translate("resources.questions.fieldGroups.answer")}
          </Typography>
          <Box
            display={{ md: "block", lg: "block" }}
            style={{ alignItems: "center" }}
          >
            <SelectInput
              source="type"
              validate={[required()]}
              fullWidth
              onChange={(event: any, key: any, payload: any) => {
                setType(event.target.value);
              }}
              choices={Object.values(
                isTypeGame ? GameMetaKey : QuestionMetaKey
              ).map((name, index) => ({
                id: name,
                name: `${index + 1}. ${translate(
                  `resources.questions.fields.${name}`
                )}`,
              }))}
              resource="questions"
            />
            {type === QuestionMetaKey.MULTIPLE_CHOICE && (
              <MultipleChoice
                isCheckTypeQuestions={formProps.form.getState().values.type}
              />
            )}
            {[
              GameMetaKey.GAME_POKEMON,
              GameMetaKey.BasketballGame,
              GameMetaKey.BricksDownGame,
            ].includes(type) && <GameMutipleChoice />}
            {[
              GameMetaKey.GAME_MINE,
              GameMetaKey.GAME_FISH,
              GameMetaKey.GAME_TRAIN,
              GameMetaKey.GAME_SPIN,
              GameMetaKey.MixAndMatchGame,
              GameMetaKey.BridgeGame,
            ].includes(type) && <GameTrain />}
            {type === QuestionMetaKey.ARRANGEMENT && <ArrangeWord />}
            {type === QuestionMetaKey.FILL_WORD_MULTIPLE && (
              <FillTextMultiple />
            )}
            {type === QuestionMetaKey.PAIR && (
              <>
                <BooleanInput
                  source="hasImage"
                  label="Dạng bài có ảnh không?"
                />
                <PairSentence />
              </>
            )}
            {type === QuestionMetaKey.FILL_WORD_MULTIPLE_ANSWER && (
              <FillTextMultipleAnswer />
            )}
            {(type === QuestionMetaKey.VOCABULARY ||
              type === QuestionMetaKey.FLASH_CARD) && (
              <>
                <ImportVocabulary source="vocabMetas" resource="questions" />
                <Vocabulary />
              </>
            )}
            {type === QuestionMetaKey.BACK_TALK_VIDEO && (
              <>
                <BacktalkVideo />
              </>
            )}
            {type === QuestionMetaKey.BACK_TALK_CONVERSATION && (
              <>
                <BacktalkConversation />
              </>
            )}
            {[QuestionMetaKey.LISTENING].includes(type) && (
              <UploadAudio source="audioUrl" resource="questions" />
            )}
            {[QuestionMetaKey.READING].includes(type) && (
              <RichTextInput source="richText" label="Nhập câu hỏi" />
            )}
            {[
              QuestionMetaKey.CATCHING_UP,
              // QuestionMetaKey.NEW_ARRANGE,
              QuestionMetaKey.SPEAK_NOT_TEXT,
            ].includes(type) && <CatchingUp />}
            {[QuestionMetaKey.NEW_ARRANGE].includes(type) && <MonoAnswer />}
            {[QuestionMetaKey.BACK_TALK].includes(type) && <Backtalk />}
            {/* ========================BAT DAU SCRIPT======================== */}
            {[QuestionMetaKey.LISTENING].includes(type) && (
              <>
                <p className="MuiFormLabel-root">Thông báo script</p>
                <Box
                  display={{ md: "block", lg: "flex" }}
                  style={{ alignItems: "center" }}
                >
                  <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                    <Editor
                      tinymceScriptSrc='/tinymce/tinymce.min.js'
                      apiKey="s1vqarrumcibda69nl55nhckmz87w4a7w8382tjfvvhigahr"
                      value={script}
                      init={{
                        height: 500,
                        menubar: false,
                        images_upload_url: "/api/upload/image",
                        // images_upload_handler: (
                        //   blobInfo: any,
                        //   success: any,
                        //   failure: any
                        // ) => {
                        //   const formData = new FormData();
                        //   formData.append("file", blobInfo.blob());

                        //   customHttpClient("/api/upload/image", {
                        //     method: "POST",
                        //     body: formData,
                        //   })
                        //     .then((res: any) => res.json)
                        //     .then((message: any) => {
                        //       if (message.data.url.includes("http")) {
                        //         success(message.data.url);
                        //       } else {
                        //         success(
                        //           process.env.REACT_APP_API_BASE_URL +
                        //             message.data.url
                        //         );
                        //       }
                        //     });
                        // },
                        images_upload_handler(blobInfo, progress) {
                          return new Promise((resolve, reject) => {
                            const formData = new FormData();
                            formData.append("file", blobInfo.blob());

                            customHttpClient("/api/upload/image", {
                              method: "POST",
                              body: formData,
                            })
                              .then((res: any) => res.json)
                              .then((message: any) => {
                                if (message.data.url.includes("http")) {
                                  resolve(message.data.url);
                                } else {
                                  resolve(
                                    process.env.REACT_APP_API_BASE_URL +
                                      message.data.url
                                  );
                                }
                              });
                          });
                        },
                        plugins:
                          "print preview powerpaste casechange importcss tinydrive searchreplace autolink autosave save directionality advcode visualblocks visualchars fullscreen image link media mediaembed template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists checklist wordcount tinymcespellchecker a11ychecker imagetools textpattern noneditable help formatpainter permanentpen pageembed charmap tinycomments mentions quickbars linkchecker emoticons advtable code",
                        mobile: {
                          plugins:
                            "print preview powerpaste casechange importcss tinydrive searchreplace autolink autosave save directionality advcode visualblocks visualchars fullscreen image link media mediaembed template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists checklist wordcount tinymcespellchecker a11ychecker textpattern noneditable help formatpainter pageembed charmap mentions quickbars linkchecker emoticons advtable",
                        },
                        toolbar:
                          "undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange permanentpen formatpainter removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | image media pageembed link anchor codesample | a11ycheck ltr rtl code",
                        tinycomments_mode: "embedded",
                      }}
                      onEditorChange={(e) => setScript(e)}
                    />
                  </Box>
                </Box>
              </>
            )}
            {/* ========================KET THUC SCRIPT======================== */}
            {[QuestionMetaKey.READING, QuestionMetaKey.LISTENING].includes(
              type
            ) && (
              <>
                <ReferenceArrayInput
                  source="childIds"
                  reference="questions"
                  resource="questions"
                  label="Câu hỏi con"
                  fullWidth
                  filter={
                    isContest
                      ? {
                          contestId: _.get(record, "contestId", undefined),
                          "parentId||$isnull": true,
                          ...(_.get(record, "id", undefined)
                            ? { "id||$ne": _.get(record, "id", undefined) }
                            : {}),
                        }
                      : {
                          unitId: _.get(record, "unitId", undefined),
                          "parentId||$isnull": true,
                          ...(_.get(record, "id", undefined)
                            ? { "id||$ne": _.get(record, "id", undefined) }
                            : {}),
                        }
                  }
                  perPage={100}
                  filterToQuery={(searchText: any) => {
                    return _.isEmpty(_.trim(searchText))
                      ? null
                      : { name: searchText };
                  }}
                >
                  <AutocompleteArrayInput
                    source="questionText"
                    fullWidth
                    optionText={(record: any) => {
                      const stt = _.get(record, "sequenceNo", "");
                      const question = _.get(record, "questionText", "");
                      return `${stt}. ${question}`;
                    }}
                  />
                </ReferenceArrayInput>
              </>
            )}
          </Box>
        </CardContent>
        <Toolbar
          disabled={disabled}
          record={formProps.record}
          basePath={formProps.basePath}
          invalid={formProps.invalid}
          handleSubmit={formProps.handleSubmit}
          saving={formProps.saving}
          undoable={false}
          resource="questions"
        >
          <SaveButton
            disabled={disabled}
            saving={formProps.saving}
            redirect={
              isContest
                ? `/contests/${formProps.record.contestId}`
                : `/units/${formProps.record.unitId}`
            }
            handleSubmitWithRedirect={() =>
              // handleSubmitQuestion(formProps.form.getState().values)
              checkQuestion(formProps.form.getState().values)
            }
            // handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
            undoable={false}
            // onClick={handleSubmitQuestion}
          />
          {formProps.isEdit && (
            <DeleteButton
              redirect={
                isContest
                  ? `/contests/${formProps.record.contestId}`
                  : `/units/${formProps.record.unitId}`
              }
              undoable={false}
            />
          )}
        </Toolbar>
      </form>
    </Card>
  );
};

const Create = (props: any) => {
  return (
    <CreateAdmin {...props} undoable={false} actions={<ListActions />}>
      <Form />
    </CreateAdmin>
  );
};

const requiredValidate = [required()];

export default Create;
