import React, { FC } from 'react';
import { Filter, NumberInput, TextInput } from 'react-admin';

const ListFilter: FC<any> = (props) => (
  <Filter {...props}>
    <NumberInput source="id" />
    <TextInput source="name" />
  </Filter>
);

export default ListFilter;
